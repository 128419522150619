import React, { Component } from 'react'

import $ from "jquery"
import _ from 'lodash-es'
import ModalConsultation from './ModalConsultation';
import { withTranslation } from "react-i18next"; // Import withTranslation
//import i18n from 'i18next';

class FreeConsultation extends Component {

    constructor(props){
        super(props)
        this.state = {
            Modal: false
        }
    }

    modal_show = () => {
        this.setState({ Modal: true })
        _.delay(function() {
            $('#myModal .modal_content').css('transform', 'scale(1)')
        }, 500);
    }

    modal_close = () => {
        this.setState({ Modal: false })
    }

    show_text = () =>{
        $('.freeCons').css('width','255px');
        $('.freeCons .const_free_text').css('display','block');
    }
    hide_text = () =>{
        $('.freeCons').css('width','70px');
        $('.freeCons .const_free_text').css('display','none');
    }

    render() {
        const { t } = this.props; // Access t from props

        return (
            <div className="freeCons">
                <ModalConsultation 
                    close={this.modal_close} 
                    show={this.state.Modal}
                />
                <a className="cont" 
                    onClick={this.modal_show}
                    onMouseEnter={this.show_text} 
                    onMouseLeave={this.hide_text} 
                    >
                    <span className="const_free_text">
                        {t('kontakt.floatBtn')}
                    </span>
                    <svg 
                        width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26.1431 10H25.6241V15.0301C25.6241 17.5126 23.6431 19.5297 21.2085 19.5297H9V21.9121C9 23.5179 10.2812 24.8234 11.8569 24.8234H13.1552V27.2059C13.1552 27.533 13.3516 27.8269 13.6524 27.946C13.7444 27.9825 13.8395 28 13.9345 28C14.1481 28 14.3585 27.9095 14.5081 27.7427L17.1343 24.8234H26.1431C27.7188 24.8234 29 23.5179 29 21.9121V12.9113C29 11.3072 27.7188 10 26.1431 10Z" fill="white" fill-opacity="0.4"/>
                        <path d="M20.6301 3H5.36836C4.0626 3 3 4.08711 3 5.42298V14.577C3 15.9129 4.0626 17 5.36836 17H20.6316C21.9374 17 23 15.9129 23 14.577V5.42298C22.9984 4.08711 21.9358 3 20.6301 3ZM20.8937 7.78135L13.7997 11.3028C13.5471 11.432 13.2739 11.4966 12.9992 11.4966C12.7245 11.4966 12.4513 11.432 12.1987 11.3028L5.10468 7.78135V5.99481L12.884 9.85058C12.9582 9.8942 13.0418 9.8942 13.1161 9.85058L20.8953 5.99481V7.78135H20.8937Z" fill="white"/>
                    </svg>
                </a>
            </div>
        )
    }
}

export default withTranslation()(FreeConsultation);