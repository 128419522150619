import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
//import _ from 'lodash-es'

import ux01 from '../../images/services/ux/ux-01.png'
import ux02 from '../../images/services/ux/ux-02.png'
import ux03 from '../../images/services/ux/ux-03.png'

import ux01retina from '../../images/services/ux/ux-01@2x.png'
import ux02retina from '../../images/services/ux/ux-02@2x.png'
import ux03retina from '../../images/services/ux/ux-03@2x.png'
 

export default class ContentUx extends Component {
    blocks_load = () => {;
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    <div className="block">
                        <div className="block__text">
                            <div className="text-toptitle">UI/UX</div>
                            <h2>Používateľské testovanie</h2>
                            <p>V rámci testovania webových aplikácií presne definujeme vašu cieľovú skupinu. Podľa nej nájdeme vhodných respondentov a pustíme sa do testovania.</p>
                        </div>
                        <div className="block__image">
                            <img src={ux01} srcSet={`${ux01}, ${ux01} 1x, ${ux01retina} 2x`} alt="Používateľské testovanie" />
                        </div>
                    </div>
                    <div className="block">
                        <div className="block__image">
                            <img src={ux02}  srcSet={`${ux02}, ${ux02} 1x, ${ux02retina} 2x`} alt="Prototypy" />
                        </div>
                        <div className="block__text">
                            <div className="text-toptitle">Administrácia.</div>
                            <h2>Prototypy</h2>
                            <p>Aby sme zistili, či vytvorený dizajn funguje, vytvoríme klikateľný grafický prototyp. Z neho zistíme, ako sa zákazníci po návšteve webu správajú. Následne vylepšujeme web.</p>
                        </div>
                    </div>
                    <div className="block">
                        <div className="block__text">
                            <div className="text-toptitle">s možnosťou administrácie</div>
                            <h2>Zdravý web</h2>
                            <p>Čím skôr dôjde k odstráneniu chýb v systéme, tým menej peňazí budete musieť neskôr vynaložiť na jeho revíziu.</p>
                        </div>
                        <div className="block__image">
                            <img src={ux03}  srcSet={`${ux03}, ${ux03} 1x, ${ux03retina} 2x`} alt="Zdravý web" />
                        </div>
                    </div>
                </div> 
            </section>
        )
    }
}
