import React, { useState, useRef, useEffect } from "react";
import { Waypoint } from "react-waypoint";
import $ from "jquery";
import { gsap, Power3 } from "gsap";
//import _ from "lodash-es";
import { useTranslation } from "react-i18next";

// images
import kastiel_tablet from "../../images/portfolio/kastiel.png";
import chandelier from "../../images/portfolio/chandelier.png";
import mnam from "../../images/portfolio/mnam.png";
import mnam_circle from "../../images/portfolio/mnam_circle.png";
import mnam_paste from "../../images/portfolio/mnam_paste.png";
import mnam_pattern from "../../images/portfolio/mnam_pattern.png";
import aldona_graphics from "../../images/portfolio/aldona-graphics.png";
import mirano_graphics from "../../images/portfolio/mirano.png";
import giganet_graphics from "../../images/portfolio/giganet.png";
import jan_kadera from "../../images/portfolio/jan-kadera.png";
import giganet_character from "../../images/portfolio/giganet-character.png";
import giganet_lines1 from "../../images/portfolio/giganet-lines.png";
import giganet_lines2 from "../../images/portfolio/giganet-lines.png";
import morph_full from "../../images/portfolio/morph-full.png";
import morph_half from "../../images/portfolio/morph-half.png";

const Portfolio = (props) => {
  const portfolio = useRef();

  const [state, setState] = useState({
    isActive1: true,
    isActive2: false,
    isActive3: false,
    isActive4: false,
    isActive5: false,
    isActive6: false,
    isActive7: false
  });

  // timeline for animations
  const t1 = useRef();
  const t2 = useRef();
  const t3 = useRef();
  const t4 = useRef();
  const t5 = useRef();
  const t6 = useRef();
  const t7 = useRef();

  const { t } = useTranslation();

  // if the portfolio not active
  //   const hasPortfolio = props.status;
  //   if (!hasPortfolio) {
  //     return null;
  //   }

  useEffect(() => {
    let ctx = gsap.context(() => {
      //  gsap.to('.portfolio__left .content', 1, {x: 0, opacity: 1});
    }, portfolio);

    return () => ctx.revert();
  }, []);

  const slideNext = (index, duration) => {
    let $item = $(".portfolio-item").eq(index).find(".content");
    gsap.to($item, 1, { x: 0, opacity: 1, ease: Power3.easeOut, delay: 0.2 });
  };

  const slidePrev = (index, duration) => {
    let $item = $(".portfolio-item").eq(index).find(".content");
    gsap.to($item, 1, { x: 0, opacity: 1, ease: Power3.easeOut, delay: 0.2 });
  };

  const fadeIn = (index, duration) => {
    let $item = $(".portfolio-item").eq(index).find(".content");
    gsap.to($item, 1, { x: 0, opacity: 1 });
  };

  const fadeOut = (index, duration) => {
    let $item = $(".portfolio-item").eq(index).find(".content");
    gsap.to($item, 1, { x: "-500", opacity: 0 });
  };

  const slideBy = (index) => {
    let prevIndex = $(".portfolio-item.active").data('index');
    setState({
      isActive1: false,
      isActive2: false,
      isActive3: false,
      isActive4: false,
      isActive5: false,
      isActive6: false,
      isActive7: false,
    });

    if (index == 0) {
      setState({ isActive1: true });
    } else if (index == 1) {
      setState({ isActive2: true });
    } else if (index == 2) {
      setState({ isActive3: true });
    } else if (index == 3) {
      setState({ isActive4: true });
    } else if (index == 4) {
      setState({ isActive5: true });
    } else if (index == 5) {
        setState({ isActive6: true });
    } else if (index == 6) {
        setState({ isActive7: true });
    }
    
    // animated item by index
    fadeOut(prevIndex-1, 1);
    slideNext(index, 1);

    if (index == 0) {
      if (!t1.current) {
        t1.current = gsap
          .timeline()
          .to($(".active .portfolio__right .kastiel-graphics"), {
            scale: 1,
            opacity: 1,
            delay: 1.2,
          })
          .to($(".active .portfolio__right .kastiel-chandelier"), {
            y: 0,
            delay: 2,
          })
          .to($(".active .portfolio__right .kastiel-chandelier"), {
            rotation: -5,
            ease: "sine.inOut",
            delay: 3.5,
            repeat: -1,
            yoyo: true,
          });
      } else {
        t1.current.restart();
      }
    } else if (index == 1) {
      if (!t2.current) {
        t2.current = gsap
          .timeline()
          .to($(".mirano-graphics"), {
            scale: 1,
            opacity: 1,
            duration: 1,
          });
      } else {
        t2.current.restart();
      }
    } else if (index == 2) {
      if (!t3.current) {
        t3.current = gsap.timeline().to($(".drteiss-graphics"), {
            scale: 1,
            opacity: 1,
            duration: 1,
          });
      } else {
        t3.current.restart();
      }
    } else if (index == 3) {
      if (!t4.current) {
        t4.current = gsap.timeline().to(".character-graphics", {
            scale: 1,
            duration: 1,
        })
        .to(".lines1-graphics", {
            y: 0,
            x: 0,
            opacity: 1,
            duration: 0.5,
        })
        .to(".lines2-graphics", {
            y: 0,
            x: 0,
            opacity: 1,
            duration: 0.5,
        })
        .to(".morphfull-graphics", {
            scale: 1,
            duration: 0.5,
        })
        .to(".morphhalf-graphics", {
            x: 0,
            opacity: 1,
            duration: 0.5,
        });
      } else {
        t4.current.restart();
      }
    } else if (index == 4) {
      if (!t5.current) {
        t5.current = gsap.timeline().to($(".aldona-graphics"), {
            x: 0,
            opacity: 1,
            duration: 1,
          });
      } else {
        t5.current.restart();
      }
    } else if (index == 5) {
        if (!t6.current) {
          t6.current = gsap.timeline().timeline()
          .to($(".jankadera-graphics"), {
                scale: 1,
                opacity: 1,
                duration: 1,
            });
            } else {
                t6.current.restart();
            }
    } else if (index == 6) {
        if (!t7.current) {
          t7.current = gsap.timeline().to(".mnam-screen", {
            y: 0,
            duration: 0.5,
          })
          .to(".mnam-circle", {
            y: 0,
            duration: 0.5,
          })
          .to(".mnam-paste", {
            y: 0,
            duration: 0.5,
          });

        } else {
          t7.current.restart();
        }
    }
  };

  /* the stepping slide */
  const nextSlide = () => {
    if ($(".portfolio-item").eq(0).hasClass("active")) {
      setState({ isActive1: false, isActive2: true });
      // steps of animation
      fadeOut(0, 1);
      slideNext(1, 1);

      if (!t2.current) {
        t2.current = gsap
          .timeline()
          .to($(".mirano-graphics"), {
            scale: 1,
            opacity: 1,
            duration: 1,
          });

      } else {
        t2.current.restart();
      }
    } else if ($(".portfolio-item").eq(1).hasClass("active")) {
      setState({ isActive2: false, isActive3: true });
      // steps of animation
      fadeOut(1, 1);
      slideNext(2, 1);

      if (!t3.current) {
        t3.current = gsap.timeline().to($(".drteiss-graphics"), {
            scale: 1,
            opacity: 1,
            duration: 1,
          });

      } else {
        t3.current.restart();
      }
    } else if ($(".portfolio-item").eq(2).hasClass("active")) {
      setState({ isActive3: false, isActive4: true });
      // steps of animation
      fadeOut(2, 1);
      slideNext(3, 1);

      if (!t4.current) {
        t4.current = gsap.timeline()
        .to(".character-graphics", {
            scale: 1,
            duration: 1,
        })
        .to(".lines1-graphics", {
            y: 0,
            x: 0,
            opacity: 1,
            duration: 0.5,
        })
        .to(".lines2-graphics", {
            y: 0,
            x: 0,
            opacity: 1,
            duration: 0.5,
        })
        .to(".morphfull-graphics", {
            scale: 1,
            duration: 0.5,
        })
        .to(".morphhalf-graphics", {
            x: 0,
            opacity: 1,
            duration: 0.5,
        });

      } else {
        t4.current.restart();
      }
    } else if ($(".portfolio-item").eq(3).hasClass("active")) {
      setState({ isActive4: false, isActive5: true });
      // steps of animation
      fadeOut(3, 1);
      slideNext(4, 1);

      if (!t5.current) {
        t5.current = gsap.timeline().to($(".aldona-graphics"), {
            x: 0,
            opacity: 1,
            duration: 1,
          });
      } else {
        t5.current.restart();
      }
    } else if ($(".portfolio-item").eq(4).hasClass("active")) {
      setState({ isActive5: false, isActive6: true });
      // steps of animation
      fadeOut(4, 1);
      slideNext(5, 1);

      if (!t6.current) {
        t6.current = gsap
          .timeline()
          .to($(".jankadera-graphics"), {
            scale: 1,
            opacity: 1,
            duration: 1,
          });
      } else {
        t6.current.restart();
      }
    }  else if ($(".portfolio-item").eq(5).hasClass("active")) {
        setState({ isActive6: false, isActive7: true });
        // steps of animation
        fadeOut(5, 1);
        slideNext(6, 1);
  
        if (!t7.current) {
          t7.current = gsap
            .timeline()
            .to(".mnam-screen", {
                y: 0,
                duration: 0.5,
              })
              .to(".mnam-circle", {
                y: 0,
                duration: 0.5,
              })
              .to(".mnam-paste", {
                y: 0,
                duration: 0.5,
              });
        } else {
          t7.current.restart();
        }
      }  else if ($(".portfolio-item").eq(6).hasClass("active")) {
        setState({ isActive7: false, isActive1: true });
        // steps of animation
        fadeOut(6, 1);
        slideNext(0, 1);
  
        if (!t1.current) {
          t1.current = gsap
            .timeline()
            .to($(".active .portfolio__right .kastiel-graphics"), {
              scale: 1,
              opacity: 1,
              delay: 1.2,
            })
            .to($(".active .portfolio__right .kastiel-chandelier"), {
              y: 0,
              delay: 2,
            })
            .to($(".active .portfolio__right .kastiel-chandelier"), {
              rotation: -5,
              ease: "sine.inOut",
              delay: 3.5,
              repeat: -1,
              yoyo: true,
            });
        } else {
          t1.current.restart();
        }
      }
  };

  /* the stepping slide */ 
  const prevSlide = () => {
    if ($(".portfolio-item").eq(0).hasClass("active")) {
      setState({ isActive1: false, isActive7: true });
      fadeOut(0, 1);
      slidePrev(6, 1);

      if (!t7.current) {
        t7.current = gsap.timeline()
        .to(".mnam-screen", {
            y: 0,
            duration: 0.5,
          })
          .to(".mnam-circle", {
            y: 0,
            duration: 0.5,
          })
          .to(".mnam-paste", {
            y: 0,
            duration: 0.5,
          });

      } else {
        t7.current.restart();
      }
    } else if ($(".portfolio-item").eq(1).hasClass("active")) {
      setState({ isActive2: false, isActive1: true });
      fadeOut(1, 1);
      slidePrev(0, 1);

      if (!t1.current) {
        t1.current = gsap
          .timeline()
          .to($(".active .portfolio__right .kastiel-graphics"), {
            scale: 1,
            opacity: 1,
            delay: 1.2,
          })
          .to($(".active .portfolio__right .kastiel-chandelier"), {
            y: 0,
            delay: 2,
          })
          .to($(".active .portfolio__right .kastiel-chandelier"), {
            rotation: -5,
            ease: "sine.inOut",
            delay: 3.5,
            repeat: -1,
            yoyo: true,
          });
      } else {
        t1.current.restart();
      }
    } else if ($(".portfolio-item").eq(2).hasClass("active")) {
      setState({ isActive2: true, isActive3: false });
      fadeOut(2, 1);
      slidePrev(1, 1);

      if (!t2.current) {
        t2.current = gsap
          .timeline()
          .to($(".mirano-graphics"), {
            scale: 1,
            opacity: 1,
            duration: 1,
          });

      } else {
        t2.current.restart();
      }
    } else if ($(".portfolio-item").eq(3).hasClass("active")) {
      setState({ isActive3: true, isActive4: false });
      fadeOut(3, 1);
      slidePrev(2, 1);

      if (!t3.current) {
        t3.current = gsap.timeline().to($(".drteiss-graphics"), {
            scale: 1,
            opacity: 1,
            duration: 1,
          });

      } else {
        t3.current.restart();
      }
    } else if ($(".portfolio-item").eq(4).hasClass("active")) {
      setState({ isActive5: false, isActive4: true });
      fadeOut(4, 1);
      slidePrev(3, 1);

      if (!t4.current) {
        t4.current = gsap.timeline()
        .to(".character-graphics", {
            scale: 1,
            duration: 1,
        })
        .to(".lines1-graphics", {
            y: 0,
            x: 0,
            opacity: 1,
            duration: 0.5,
        })
        .to(".lines2-graphics", {
            y: 0,
            x: 0,
            opacity: 1,
            duration: 0.5,
        })
        .to(".morphfull-graphics", {
            scale: 1,
            duration: 0.5,
        })
        .to(".morphhalf-graphics", {
            x: 0,
            opacity: 1,
            duration: 0.5,
        });


      } else {
        t4.current.restart();
      }
    } else if ($(".portfolio-item").eq(5).hasClass("active")) {
        setState({ isActive6: false, isActive5: true });
        fadeOut(5, 1);
        slidePrev(4, 1);
  
        if (!t5.current) {
          t5.current = gsap.timeline().to($(".aldona-graphics"), {
            x: 0,
            opacity: 1,
            duration: 1,
          });

        } else {
          t5.current.restart();
        }
      } else if ($(".portfolio-item").eq(6).hasClass("active")) {
        setState({ isActive7: false, isActive6: true });
        fadeOut(6, 1);
        slidePrev(5, 1);
  
        if (!t6.current) {
          t6.current = gsap.timeline()
          .to($(".jankadera-graphics"), {
            scale: 1,
            opacity: 1,
            duration: 1,
          });
        } else {
          t6.current.restart();
        }
      }
  };

  /* init loading */
  function loadingAnim() {
    // first load of the portfolio carousel
    gsap.to(".portfolios .portfolio-holder", 1, {
      y: 0,
      opacity: 1,
      delay: 0.8,
    });
    gsap.to(".active .portfolio__left .content", 1, {
      x: 0,
      opacity: 1,
      delay: 1.2,
    });

    t1.current = gsap
      .timeline()
      .to($(".active .portfolio__right .kastiel-graphics"), {
        scale: 1,
        opacity: 1,
        duration: 0.5,
      })
      .to($(".active .portfolio__right .kastiel-chandelier"), {
        y: 0,
        delay: 0.2,
        duration: 1.5,
      })
      .to($(".active .portfolio__right .kastiel-chandelier"), {
        rotation: -5,
        ease: "sine.inOut",
        duration: 2,
        repeat: -1,
        yoyo: true,
      });
  }

  return (
    <section className="portfolios" ref={portfolio}>
      <Waypoint scrollableAncestor={window} onEnter={loadingAnim} />
      <div className="container-block">
        <div className="portfolio-head">
          <div className="head-text">
            <div className="toptitle">{t("pageHome.section6.headline")}</div>
            <h2>{t("pageHome.section6.title")}</h2>
          </div>
          <div className="head-action">
            <a href="/referencie" className="btn--primary">
              {t("pageHome.section6.button")}
            </a>
          </div>
        </div>
      </div>

      <div className="portfolio-holder">
        <div
          className={state.isActive1 ? "portfolio-item active" : "portfolio-item"}
          data-index="1"
        >
          <div className="portfolio__left">
            <div className="content">
              <div className="hashlist">
                <span>#web</span>
                <span>#3D</span>
              </div>

              <div className="content-bottom">
                <div className="content__toptitle">Kaštiel pÁlffy</div>
                <h2 className="content__title">
                  <a href={t("reference.kastiel", { ns: "routes" })}>
                    Tvorba webu a ilustrácií pre impozantný kaštieľ
                  </a>
                </h2>
                <div>
                  <a href={t("reference.kastiel", { ns: "routes" })}>
                    Zobraziť viac
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.75 7H1.25M9.5 1.75L14.75 7L9.5 1.75ZM14.75 7L9.5 12.25L14.75 7Z"
                        stroke="#F11341"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="portfolio__right">
            <img src={kastiel_tablet} className="kastiel-graphics" alt="." />
            <img src={chandelier} className="kastiel-chandelier"  alt="." />
          </div>
        </div>

        <div
          className={state.isActive2 ? "portfolio-item active" : "portfolio-item"}
          data-index="2"
        >
          <div className="portfolio__left">
            <div className="content">
              <div className="hashlist">
                <span>#web</span>
                <span>#marketing</span>
                <span>#prints</span>
              </div>

              <div className="content-bottom">
                <div className="content__toptitle">Mirano</div>
                <h2 className="content__title">
                  <a href={t("reference.mirano", { ns: "routes" })}>Web, online marketing a SEO pre stavebnú firmu</a>
                </h2>
                <div>
                  <a href={t("reference.mirano", { ns: "routes" })}>
                    Zobraziť viac
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.75 7H1.25M9.5 1.75L14.75 7L9.5 1.75ZM14.75 7L9.5 12.25L14.75 7Z"
                        stroke="#F11341"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="portfolio__right">
            <img src={mirano_graphics} className="mirano-graphics"  alt="." />
          </div>
        </div>

        <div
          className={state.isActive3 ? "portfolio-item active" : "portfolio-item"}
          data-index="3"
        >
          <div className="portfolio__left">
            <div className="content">
              <div className="hashlist">
                <span>#ui/ux dizajn </span>
                <span>#web</span>
                <span>#eshop</span>
                <span>#marketing</span>
              </div>

              <div className="content-bottom">
                <div className="content__toptitle">DR THEISS</div>
                <h2 className="content__title">
                  <a href={t("reference.giganet", { ns: "routes" })}>E-shop na mieru a online marketing pre DR THEISS</a>
                </h2>
                <div>
                  <a href={t("reference.giganet", { ns: "routes" })}>
                    Zobraziť viac
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.75 7H1.25M9.5 1.75L14.75 7L9.5 1.75ZM14.75 7L9.5 12.25L14.75 7Z"
                        stroke="#F11341"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="portfolio__right">
            <img src={giganet_graphics} className="drteiss-graphics"  alt="." />
          </div>
        </div>

        <div
          className={state.isActive4 ? "portfolio-item active" : "portfolio-item"}
          data-index="4"
        >
          <div className="portfolio__left">
            <div className="content">
              <div className="hashlist">
                <span>#web</span>
                <span>#brand</span>
                <span>#logotyp</span>
                <span>#print</span>
                <span>#marketing</span>
              </div>

              <div className="content-bottom">
                <div className="content__toptitle">GIGAnet</div>
                <h2 className="content__title">
                  <a href={t("reference.giganet", { ns: "routes" })}>Komplexné služby a branding pre poskytovateľa internetu</a>
                </h2>
                <div>
                  <a href={t("reference.giganet", { ns: "routes" })}>
                    Zobraziť viac
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.75 7H1.25M9.5 1.75L14.75 7L9.5 1.75ZM14.75 7L9.5 12.25L14.75 7Z"
                        stroke="#F11341"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>

              </div>
            </div>
          </div>

          <div className="portfolio__right">
            <img src={giganet_character} className="character-graphics"  alt="." />
            <img src={giganet_lines1} className="lines1-graphics"  alt="." />
            <img src={giganet_lines2} className="lines2-graphics"  alt="." />
            <img src={morph_full} className="morphfull-graphics"  alt="." />
            <img src={morph_half} className="morphhalf-graphics"  alt="." />
          </div>
        </div>

        <div
          className={state.isActive5 ? "portfolio-item active" : "portfolio-item"}
          data-index="5"
        >
          <div className="portfolio__left">
            <div className="content">
              <div className="hashlist">
                <span>#web</span>
                <span>#logotyp</span>
                <span>#animacie</span>
                <span>#ilustracie</span>
                <span>#marketing</span>
              </div>

              <div className="content-bottom">
                <div className="content__toptitle">Aldona</div>
                <h2 className="content__title">
                  <a href={t("reference.aldona", { ns: "routes" })}>
                  Ilustrovaný a animovaný web na mieru pre špedičnú spoločnosť
                  </a>
                </h2>
                <div>
                  <a href={t("reference.aldona", { ns: "routes" })}>
                    Zobraziť viac
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.75 7H1.25M9.5 1.75L14.75 7L9.5 1.75ZM14.75 7L9.5 12.25L14.75 7Z"
                        stroke="#F11341"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="portfolio__right">
            <img src={aldona_graphics} className="aldona-graphics"  alt="." />
          </div>
        </div>

        <div
          className={state.isActive6 ? "portfolio-item active" : "portfolio-item"}
          data-index="6"
        >
          <div className="portfolio__left">
            <div className="content">
              <div className="hashlist">
                <span>#web</span>
                <span>#marketing</span>
                <span>#prints</span>
                <span>#identity</span>
              </div>

              <div className="content-bottom">
                <div className="content__toptitle">Ján Kadera</div>
                <h2 className="content__title">
                  <a href={t("reference.janKadera", { ns: "routes" })}>Úspešná primátorská kampaň</a>
                </h2>
                <div>
                  <a href={t("reference.janKadera", { ns: "routes" })}>
                    Zobraziť viac
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.75 7H1.25M9.5 1.75L14.75 7L9.5 1.75ZM14.75 7L9.5 12.25L14.75 7Z"
                        stroke="#F11341"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="portfolio__right">
            <img src={jan_kadera} className="jankadera-graphics"  alt="jan kadera" />
          </div>
        </div>

        <div
          className={state.isActive7 ? "portfolio-item active" : "portfolio-item"}
          data-index="7"
        >
          <div className="portfolio__left">
            <div className="content">
              <div className="hashlist">
                <span>#UI/UX DIZAJN</span>
              </div>

              <div className="content-bottom">
                <div className="content__toptitle">MŇAM delivery</div>
                <h2 className="content__title">
                  <a href={t("reference.mnam", { ns: "routes" })}>
                    Vyladený dizajn pre food delivery web 
                  </a>
                </h2>
                <div>
                  <a href={t("reference.mnam", { ns: "routes" })}>
                    Zobraziť viac
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M14.75 7H1.25M9.5 1.75L14.75 7L9.5 1.75ZM14.75 7L9.5 12.25L14.75 7Z"
                        stroke="#F11341"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="portfolio__right">
            <img src={mnam_pattern} className="mnam-pattern"  alt="." />
            <img src={mnam} className="mnam-screen" alt="."  />
            <img src={mnam_circle} className="mnam-circle"  alt="." />
            <img src={mnam_paste} className="mnam-paste"  alt="." />
          </div>
        </div>

      </div>{/* portfolios end */}

      <div className="portfolio-actions">
        <div className="portfolio-pagination">
          <ul>
            <li className={state.isActive1 ? "active" : ""}>
              <button onClick={() => slideBy(0)}></button>
            </li>
            <li className={state.isActive2 ? "active" : ""}>
              <button onClick={() => slideBy(1)}></button>
            </li>
            <li className={state.isActive3 ? "active" : ""}>
              <button onClick={() => slideBy(2)}></button>
            </li>
            <li className={state.isActive4 ? "active" : ""}>
              <button onClick={() => slideBy(3)}></button>
            </li>
            <li className={state.isActive5 ? "active" : ""}>
              <button onClick={() => slideBy(4)}></button>
            </li>
            <li className={state.isActive6 ? "active" : ""}>
              <button onClick={() => slideBy(5)}></button>
            </li>
            <li className={state.isActive7 ? "active" : ""}>
              <button onClick={() => slideBy(6)}></button>
            </li>
          </ul>
        </div>
        <div className="portfolio-buttons">
          <button className="portfolio__btn btn-prev" onClick={prevSlide}>
            <svg
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.60288 0.724267C7.51128 0.632437 7.40247 0.55958 7.28267 0.509869C7.16287 0.460158 7.03444 0.43457 6.90474 0.43457C6.77504 0.43457 6.64661 0.460158 6.52681 0.509869C6.40701 0.55958 6.2982 0.632437 6.2066 0.724267L0.290139 6.64073C0.198309 6.73232 0.125453 6.84114 0.0757422 6.96094C0.026031 7.08074 0.000442505 7.20917 0.000442505 7.33887C0.000442505 7.46857 0.026031 7.597 0.0757422 7.7168C0.125453 7.8366 0.198309 7.94541 0.290139 8.03701L6.2066 13.9535C6.39176 14.1386 6.64289 14.2427 6.90474 14.2427C7.16659 14.2427 7.41772 14.1386 7.60288 13.9535C7.78804 13.7683 7.89206 13.5172 7.89206 13.2553C7.89206 12.9935 7.78804 12.7423 7.60288 12.5572L2.38259 7.33887L7.60288 2.12055C7.69471 2.02895 7.76757 1.92014 7.81728 1.80034C7.86699 1.68054 7.89258 1.55211 7.89258 1.42241C7.89258 1.29271 7.86699 1.16428 7.81728 1.04448C7.76757 0.92468 7.69471 0.815865 7.60288 0.724267Z"
                fill="#131414"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.7793 7.33874C15.7793 7.07721 15.6754 6.8264 15.4905 6.64148C15.3056 6.45655 15.0547 6.35266 14.7932 6.35266H1.97423C1.71271 6.35266 1.46189 6.45655 1.27697 6.64148C1.09204 6.8264 0.988153 7.07721 0.988153 7.33874C0.988153 7.60026 1.09204 7.85107 1.27697 8.036C1.46189 8.22092 1.71271 8.32481 1.97423 8.32481H14.7932C15.0547 8.32481 15.3056 8.22092 15.4905 8.036C15.6754 7.85107 15.7793 7.60026 15.7793 7.33874Z"
                fill="#131414"
              />
            </svg>
          </button>

          <button className="portfolio__btn btn-next" onClick={nextSlide}>
            <svg
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.39712 0.724267C8.48872 0.632437 8.59753 0.55958 8.71733 0.509869C8.83713 0.460158 8.96556 0.43457 9.09526 0.43457C9.22496 0.43457 9.35339 0.460158 9.47319 0.509869C9.59299 0.55958 9.7018 0.632437 9.7934 0.724267L15.7099 6.64073C15.8017 6.73232 15.8745 6.84114 15.9243 6.96094C15.974 7.08074 15.9996 7.20917 15.9996 7.33887C15.9996 7.46857 15.974 7.597 15.9243 7.7168C15.8745 7.8366 15.8017 7.94541 15.7099 8.03701L9.7934 13.9535C9.60824 14.1386 9.35711 14.2427 9.09526 14.2427C8.83341 14.2427 8.58228 14.1386 8.39712 13.9535C8.21196 13.7683 8.10794 13.5172 8.10794 13.2553C8.10794 12.9935 8.21196 12.7423 8.39712 12.5572L13.6174 7.33887L8.39712 2.12055C8.30529 2.02895 8.23243 1.92014 8.18272 1.80034C8.13301 1.68054 8.10742 1.55211 8.10742 1.42241C8.10742 1.29271 8.13301 1.16428 8.18272 1.04448C8.23243 0.92468 8.30529 0.815865 8.39712 0.724267Z"
                fill="#131414"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.220703 7.33874C0.220703 7.07721 0.324593 6.8264 0.509518 6.64148C0.694444 6.45655 0.945256 6.35266 1.20678 6.35266H14.0258C14.2873 6.35266 14.5381 6.45655 14.723 6.64148C14.908 6.8264 15.0118 7.07721 15.0118 7.33874C15.0118 7.60026 14.908 7.85107 14.723 8.036C14.5381 8.22092 14.2873 8.32481 14.0258 8.32481H1.20678C0.945256 8.32481 0.694444 8.22092 0.509518 8.036C0.324593 7.85107 0.220703 7.60026 0.220703 7.33874Z"
                fill="#131414"
              />
            </svg>
          </button>
        </div>
      </div>

      <div className="action-mobile">
        <a href="/referencie" className="btn--primary">
          {t("pageHome.section6.button")}
        </a>
      </div>
    </section>
  );
};

export default Portfolio;
