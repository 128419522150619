import {React, useEffect, useState } from 'react'
import $ from "jquery"
import Notifications, {notify} from 'react-notify-toast';
import { useTranslation, Trans } from 'react-i18next'; 

import dr from '../../images/dr.svg'
import ig from '../../images/ig.svg'

function Content() {

    const [type, setType] = useState(1);
    const [fullname, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [tel, setTel] = useState('');
    const [text, setText] = useState('');
    const [sent, setSent] = useState(false);
    const [company, setCompany] = useState('');

    function handleFullNameChange(event) {
        setFullName(event.target.value);
    }

    function handleEmailChange(event) {
        setEmail(event.target.value);
    }

    function handleTelChange(event) {
        setTel(event.target.value);
    }
    
    function handleTextChange(event) {
        setText(event.target.value);
    }

    function handleCompanyChange(event) {
        setCompany(event.target.value);
    }

    function validateEmail(emailParam) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(emailParam).toLowerCase());
    }

    function sendEmail(event)  {
        event.preventDefault();
        
        var api_key = 'a89a0ff9aea2ee281d70816f0a3127ac-1d8af1f4-0e6fca5b';
        var domain = 'mg.matate.sk';
        var mailgun = require('mailgun-js')({apiKey: api_key, domain: domain, host: "api.eu.mailgun.net"});
         
        var txt = "<h3><b>Email:</b> " + email + " </h3> "
             txt += "<h3><b>Name:</b> " + fullname + " </h3> "
            txt += "<h3><b>Telefón:</b> " + tel + " </h3> "
            txt += "<h3><b>Názov firmy:</b> " + company + " </h3> "

        if(type == 1)
            txt += "<h3><b>Typ:</b> Web </h3> "
        else if(type == 2)
            txt += "<h3><b>Typ:</b> Marketing </h3> "
        else if(type == 3)
             txt += "<h3><b>Typ:</b> Iné </h3> "
            
             txt += "<h3><b>Text:</b> " + text + " </h3> "

        var data = {
          from: 'Matate server <server@mg.matate.sk>',
          to: 'info@matate.sk'/**/,
          subject: 'Konzultácia pre '+email+' - matate.sk',
          html: txt
        };
        data['h:Reply-To'] = email;
        if(email != "" && text != "" && validateEmail(email)){
            mailgun.messages().send(data);
           // notify.show("Správa bola úspešne odoslaná.", 'success');
            setSent(true);

            var service = null;
            if(type == 1) {
                service =  "Web";
            } else if(type == 2) {
                service =  "Marketing";
            }
            else {
                service = "Iné";
            }
            
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'contactFormSent',
                form_type: "contactPage",
                user: {
                    name: fullname,
                    email: email,
                    phone: tel,
                    company: company,
                    services: service,
                }
              });

            $('.toast-notification').attr('id', "float-form-sent-success");
            setEmail('');
            setFullName('');
            setTel('');
            setText('');
            setCompany('');
            $(".form-label").removeClass("is-smaller");

            setInterval(() => {
                //this.props.close();
                setSent(false);
            }, 2000);

        }else{
            notify.show("Zle vyplnené udaje.", 'error');
            setSent(false);
        }
    }

    function inputListener() {
        $("input").on('focus', function() {
            $(this).prev('.form-label').addClass("is-smaller");
        });

        $("input").on('blur', function() {
            if($(this).val().length == 0) {
                $(this).prev('.form-label').removeClass("is-smaller");
            }
        });

        $("textarea").on('focus', function() {
            $(this).prev('.form-label').addClass("is-smaller");
        });

        $("textarea").on('blur', function() {
            if($(this).val().length == 0) {
                $(this).prev('.form-label').removeClass("is-smaller");
            }
        });
    }

    useEffect(() => {
        inputListener();
    });

    const { t } = useTranslation(); 

    return(  
        <div className="main-content">
            <div className="container">
                <div className="contact-row">
                    <div>
                        <div className="contact-box">
                            <div className="person">
                                <div className="name">Dávid Brontvaj <span class="divider">|</span> <div className="position">CEO</div></div>
                            </div>
                            <div className="person-contact">
                                <ul>
                                    <li><a href="mailto:info@matate.sk"> 
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.98138 21.3832C3.30846 21.5408 3.66986 21.625 4.03984 21.625H21.9375C22.2047 21.625 22.4676 21.5811 22.7164 21.4971L15.6548 14.1144C15.2872 14.4672 14.9588 14.7821 14.6848 15.0439C13.7425 15.9453 12.2574 15.9453 11.3152 15.0439C10.9904 14.7335 10.5877 14.3471 10.1335 13.9111L2.98138 21.3832Z" fill="#5E5F5F"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.8157 6.25897C22.7765 7.26075 19.3754 10.5391 16.8267 12.9888L23.9993 20.4875C24.2429 20.1014 24.375 19.6513 24.375 19.1875V7.81247C24.375 7.24307 24.1758 6.69421 23.8157 6.25897Z" fill="#5E5F5F"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.1728 6.24548C1.80568 6.6828 1.60236 7.23712 1.60236 7.8125V19.1875C1.60236 19.5512 1.68374 19.9067 1.83636 20.2293L8.96166 12.785C6.46852 10.3875 3.25778 7.29198 2.1728 6.24548Z" fill="#5E5F5F"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.64569 5.40724L12.4384 13.8697C12.7525 14.1703 13.2475 14.1703 13.5616 13.8697C15.5813 11.9377 20.5649 7.13572 22.3542 5.41088C22.2175 5.38722 22.0781 5.375 21.9375 5.375H4.03985C3.90699 5.375 3.77517 5.38592 3.64569 5.40724Z" fill="#5E5F5F"/>
                                            </svg>
                                            info@matate.sk
                                        </a>
                                    </li>
                                    <li><a href="tel:+421 902 359 330">     
                                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                                                <g clip-path="url(#clip0_5315_1553)">
                                                    <path d="M19.7669 17.6995C18.9175 16.8609 17.8571 16.8609 17.0131 17.6995C16.3692 18.338 15.7254 18.9764 15.0924 19.6256C14.9193 19.8042 14.7732 19.842 14.5622 19.723C14.1456 19.4958 13.7019 19.3118 13.3016 19.0629C11.435 17.8889 9.8714 16.3794 8.48635 14.6806C7.79924 13.8365 7.18787 12.933 6.76045 11.9159C6.67388 11.7103 6.69011 11.575 6.85784 11.4073C7.50167 10.7851 8.12927 10.1467 8.76228 9.50826C9.64417 8.62096 9.64417 7.58217 8.75687 6.68946C8.25371 6.18089 7.75054 5.68313 7.24738 5.17456C6.72799 4.65517 6.214 4.13036 5.6892 3.61638C4.83977 2.7886 3.77934 2.7886 2.93533 3.62179C2.28609 4.26021 1.6639 4.91486 1.00383 5.54247C0.392462 6.12137 0.0840717 6.83013 0.0191474 7.65791C-0.0836493 9.00509 0.246382 10.2765 0.711673 11.5155C1.6639 14.08 3.11387 16.3578 4.87224 18.4462C7.24738 21.2704 10.0824 23.5048 13.399 25.1171C14.8922 25.8421 16.4396 26.3994 18.1222 26.4914C19.28 26.5563 20.2863 26.2641 21.0925 25.3606C21.6443 24.7438 22.2665 24.1811 22.8508 23.5914C23.7165 22.7149 23.7219 21.6545 22.8617 20.7888C21.8337 19.7555 20.8003 18.7275 19.7669 17.6995Z" fill="white" fill-opacity="0.3"/>
                                                    <path d="M18.7335 13.3875L20.73 13.0466C20.4162 11.2125 19.5505 9.55155 18.2358 8.23143C16.8453 6.84096 15.087 5.96449 13.1501 5.69397L12.8687 7.70121C14.3674 7.91222 15.7308 8.58851 16.8075 9.66517C17.8246 10.6823 18.4901 11.97 18.7335 13.3875Z" fill="white" fill-opacity="0.3"/>
                                                    <path d="M21.8553 4.70926C19.5505 2.40445 16.6343 0.949059 13.4152 0.5L13.1339 2.50724C15.9148 2.89679 18.436 4.1574 20.427 6.143C22.3152 8.03121 23.5542 10.4172 24.0033 13.0412L25.9997 12.7004C25.4749 9.65973 24.0411 6.90045 21.8553 4.70926Z" fill="white" fill-opacity="0.3"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_5315_1553">
                                                    <rect width="26" height="26" fill="white" transform="translate(0 0.5)"/>
                                                    </clipPath>
                                                </defs>
                                                </svg>+421 902 359 330</a>
                                    </li>
                                    <li><a href="http://linkedin.com/in/dbrontvaj/" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="26" height="27" viewBox="0 0 26 27" fill="none">
                                                <path d="M23.6963 24.34V16.722C23.6963 12.978 22.8903 10.118 18.5223 10.118C16.4163 10.118 15.0123 11.262 14.4403 12.354H14.3883V10.456H10.2543V24.34H14.5703V17.45C14.5703 15.63 14.9083 13.888 17.1443 13.888C19.3543 13.888 19.3803 15.942 19.3803 17.554V24.314H23.6963V24.34Z" fill="#5E5F5F"/>
                                                <path d="M2.93787 10.4559H7.25387V24.3399H2.93787V10.4559Z" fill="#5E5F5F"/>
                                                <path d="M5.09597 3.09998C3.71798 3.09998 2.59998 4.21798 2.59998 5.59598C2.59998 6.97398 3.71798 8.11798 5.09597 8.11798C6.47397 8.11798 7.59197 6.97398 7.59197 5.59598C7.59197 4.21798 6.47397 3.09998 5.09597 3.09998Z" fill="#5E5F5F"/>
                                                </svg>in/dbrontvaj/</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="company-info">
                                <p><strong>MATATE s.r.o.</strong><br />
                                    Doležalova 3424/15C,<br /> 
                                    821 04 Bratislava - mestská časť Ružinov 
                                </p>
                                <p>
                                    IČO: 52 877 558<br /> 
                                    DIČ: 2121187717<br /> 
                                    IČ DPH: SK2121187717
                                </p>
                            </div>
                            <ul className="social-list">
                                <li>   
                                    <a href="https://dribbble.com/MATATE" target="_blank">
                                        <img src={dr} alt="" />
                                    </a>
                                </li>
                                <li>
                                    <a href="http://instagram.com/matate_sk/" target="_blank">
                                        <img src={ig} alt="" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <form className="form--contact" action="#" autoComplete="false" method="POST">
                            <div className="form-group">
                                <div className="input-holder">
                                    <label className="form-label">{t('contactPage.form.input1')}<span class="red-sign">*</span></label>   
                                    <input type="text" name="nev" className="form-control" onChange={handleFullNameChange} value={fullname} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-holder">
                                    <label className="form-label">{t('contactPage.form.input2')}<span class="red-sign">*</span></label>   
                                    <input type="text" name="email" className="form-control" onChange={handleEmailChange} value={email} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-holder">
                                    <label class="form-label"><Trans i18nKey="contactPage.form.input3" components={{small: <small />}} /></label>   
                                    <input type="text" name="company" className="form-control" onChange={handleCompanyChange} value={company} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-holder">
                                    <label class="form-label"><Trans i18nKey="contactPage.form.input4" components={{small: <small />}} /></label>   
                                    <input type="text" name="phone" className="form-control" onChange={handleTelChange} value={tel} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label--base">{t('contactPage.form.typesLabel')}<span class="red-sign">*</span></label>  
                                <div class="form-row">
                                    <div className="radio-button">
                                        <input type="radio" name="type" value="web" id="web" />
                                        <label htmlFor="web" onClick={() => setType(1)}>{t('contactPage.form.type1')}</label>
                                    </div>
                                    <div className="radio-button">
                                        <input type="radio" name="type" value="marketing" id="marketing" />
                                        <label htmlFor="marketing" onClick={() => setType(2)}>{t('contactPage.form.type2')}</label>
                                    </div>
                                    <div className="radio-button">
                                        <input type="radio" name="type" value="other" id="other" />
                                        <label htmlFor="other" onClick={() => setType(3)}>{t('contactPage.form.type3')}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-holder">
                                    <label className="form-label"><Trans>{t('contactPage.form.message')}</Trans><span class="red-sign">*</span></label>   
                                    <textarea name="msg" className="form-control" onChange={handleTextChange} value={text} />
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="checkbox-holder" hidden>
                                    <input type="checkbox" name="terms"  value="terms" id="terms" />
                                    <label htmlFor="terms" className="form-label--base"><span>Súhlasím so</span> </label><a href="#">spracovaním osobných údajov</a>
                                </div>
                            </div>
                            <div className="form-group">
                                <button className="btn-send" onClick={sendEmail}><span>{t('contactPage.form.button')}</span></button>
                            </div>

                            {sent ? <div className="success-msg">{t('messages.success')}</div> : null }

                            <Notifications  options={{zIndex: 99999, top: '750px'}} />
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Content;