import React, { Component } from "react";
import { Waypoint } from "react-waypoint";
import $ from "jquery";
import _ from "lodash-es";
import { withTranslation } from 'react-i18next';

class Hero extends Component {
  hero_load = () => {
    _.delay(function () {
      $(".hero--sub__text").css("transform", "none").css("opacity", "1");
    }, 100);
  };

  render() {
    return (
      <div class="hero--sub hero--services">
        <Waypoint scrollableAncestor={window} onEnter={this.hero_load} />

        <div class="reveal_wrap">
          <div class="hero--sub__text">
            <div class="hero-subtitle">{this.props.t("pageSluzby.hero.subtitle")}</div>

            <h1>
            {this.props.t("pageSluzby.hero.heading")}
            </h1>
            <p>
            {this.props.t("pageSluzby.hero.description")}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Hero);