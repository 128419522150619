import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'
//import Parallax from 'react-rellax'

import eye from '../../images/references/jan-kadera/eye.svg'
import marketing from '../../images/references/jan-kadera/marketing.svg'
import darts from '../../images/references/jan-kadera/darts.svg'

import eventImage from '../../images/references/jan-kadera/event-image.png'
import eventImage2x from '../../images/references/jan-kadera/event-image@2x.png'

import campaignScreen01 from '../../images/references/jan-kadera/campaign-screen-01.png'
import campaignScreen01x2 from '../../images/references/jan-kadera/campaign-screen-01@2x.png'

import campaignScreen02 from '../../images/references/jan-kadera/campaign-screen-02.png'
import campaignScreen02x2 from '../../images/references/jan-kadera/campaign-screen-02@2x.png'

import campaignScreen03 from '../../images/references/jan-kadera/campaign-screen-03.png'
import campaignScreen03x2 from '../../images/references/jan-kadera/campaign-screen-03@2x.png'

import campaignScreen04 from '../../images/references/jan-kadera/campaign-screen-04.png'
import campaignScreen04x2 from '../../images/references/jan-kadera/campaign-screen-04@2x.png'

import campaignScreen05 from '../../images/references/jan-kadera/campaign-screen-05.png'
import campaignScreen05x2 from '../../images/references/jan-kadera/campaign-screen-05@2x.png'

import campaignScreen06 from '../../images/references/jan-kadera/campaign-screen-06.png'
import campaignScreen06x2 from '../../images/references/jan-kadera/campaign-screen-06@2x.png'

import product01 from '../../images/references/jan-kadera/product-01.png'
import product01_2x from '../../images/references/jan-kadera/product-01@2x.png'

import product02 from '../../images/references/jan-kadera/product-02.png'
import product02_2x from '../../images/references/jan-kadera/product-02@2x.png'

import product03 from '../../images/references/jan-kadera/product-03.png'
import product03_2x from '../../images/references/jan-kadera/product-03@2x.png'

import product04 from '../../images/references/jan-kadera/product-04.png'
import product04_2x from '../../images/references/jan-kadera/product-04@2x.png'

//import logoGraphic from '../../images/references/aldona/aldona-logo.png'
//import logoGraphic2x from '../../images/references/aldona/aldona-logo@2x.png'

//import manual01 from '../../images/references/aldona/logo-manual-01.png'
//import manual01x2 from '../../images/references/aldona/logo-manual-01@2x.png'

//import manual02 from '../../images/references/aldona/logo-manual-02.png'
//import manual02x2 from '../../images/references/aldona/logo-manual-02@2x.png';


export default class ContentJanKadera extends Component {

    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $(".back-link").css("opacity", "1");
        }, 1500);
    }

    blockIntro = () => {
        _.delay(function() {
            $(".block--intro").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(0).css("opacity", "1").css("transform", "none");
        }, 400);
        
        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(1).css("opacity", "1").css("transform", "none");
        }, 750);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(2).css("opacity", "1").css("transform", "none");
        }, 950);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(3).css("opacity", "1").css("transform", "none");
        }, 1150);
    }

    blockCoverImage = () => {
        $(".block--cover-image").find('.overlayer').css("width", "0");
    }

    blockLogo = () => {

        _.delay(function() {
            $(".block-top").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--logo").find(".block-image").css("opacity", "1").css("transform", "none");
        }, 500);
    }

    blockPack = () => {
        _.delay(function() {
            $(".block--image-pack").find(".overlayer").css("width", "0");
        }, 1000);
    }

    blockScreen = () => {
        $(".block--screens").find(".block-image").css("opacity", "1").css("transform", "none");
    }

    blockPreviews = () => {

        _.delay(function() {

            let screens = $(".block--previews").find(".block-image");

            $.each(screens, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);
    }

    blockColumns = () => {

        let columnItems = $(".block--columns").find(".services__item");

        _.delay(function() {
            $.each(columnItems, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);

    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    
                    <div class="block--intro">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockIntro}
                            bottomOffset="20%"
                        />
                        <h2>Úspešná primátorská kampaň pre Jána Kaderu</h2>
                        <p>Pre primátora mesta Námestovo Jána Kaderu sme pripravovali celú kandidátsku kampaň vo voľbách 2022. Starali sme sa o celú vizuálnu identitu, sociálne siete, web a online marketing nie len pre primátora, ale aj celú skupinu ľudí okolo neho - kandidátov na poslancov. Kampaň bola vo voľbách úspešná.</p>
                    </div>


                    {/* <div>
                        <img class="brand-logo-mobile" src={logoMobile} alt="Aldona" />
                    </div> */}

                    <div class="block--boxes">
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={eye} alt="IDENTITA A IDEA KAMPANE" />
                                <h3>IDENTITA A IDEA KAMPANE</h3>
                            </div>
                            <p>V prvom rade bolo potrebné vymyslieť a zadefinovať ideu, ktorá by tomu celému dala nejakú tému a bolo by na tejto idei založená celá kampaň. Následne sme ideu spracovali do celej vizuálnej identity a zadefinovali tomu farby, fonty, tvary a podobne.  </p><br/>
                            <p>V tvorbe idei sme sa hrali so slovíčkami a vzniklo <strong>“NÁMESTOVO VŠETKÝM”</strong>, kde sa nachádza slovo Námestovo a zároveň aj spojenie “Nám všetkým”. </p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={marketing} alt="ONLINE MARKETING A TEXTY" />
                                <h3>ONLINE MARKETING A TEXTY</h3>
                            </div>
                            <p>Na základe premysleného programu primátora a jeho tímu sme pripravili koncept celej kampane aj s celým plánom a mediálnym rozpočtom. Tá okrem iného zahŕňala správu sociálnych sietí, PPC kampaní na Facebooku aj v Google či Youtube, tvorbu webu, ale aj menše úlohy ako sú návrhy letákov či billboardov. </p>
                            <p>Napísali sme texty so správnym tone of voice, ktoré sa použili či už pri tvorbe príspevkov a reklám, alebo aj na webe a v brožúrach či billboardoch.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={darts} alt="GRAFICKÉ MATERIÁLY" />
                                <h3>GRAFICKÉ MATERIÁLY</h3>
                            </div>
                            <p>V rámci kampane sme sa postarali o celú vizuálnu identitu. Tá okrem tvorby loga zahŕňala aj tvorbu ďalších grafických materiálov. V kampani sme sa starali o:</p>
                            <ul>
                                <li>tvorba loga a identity</li>
                                <li>grafický návrh billboardov a rollupov</li>
                                <li>návrh a zalomenie brožúry </li>
                                <li>návrh oblečenia a reklamných predmetov s logom</li>
                                <li>dizajn webu</li>
                            </ul>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={darts} alt="WEB" />
                                <h3>WEB</h3>
                            </div>
                            <p>Samozrejmosťou bolo aj spracovanie prehľadného a jednoduchého webu, na ktorý sme mohli z kampaní ľudí smerovať. Jedná sa o jednostránkový web, na ktorom bol uverejnený profil kandidáta a jeho program spolu s profilmi celého tímu.</p>
                        </div>
                    </div>
{/* 
                    <div className="link-row link-row--topNegative">
                            <a href="https://www.aldona.sk/" target="blank">navštíviť WWW.ALDONA.SK <i className="arrow-next"></i></a>
                        </div> */}
                </div> 

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    <img src={eventImage}  srcSet={`${eventImage}, ${eventImage} 1x, ${eventImage2x} 2x`}  alt="Ján Kadera" />
                    <div class="overlayer"></div>
                </div>

                <div class="container-block"> 
                    <div class="block--image-pack">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockPack}
                            bottomOffset="10%"
                        />
                        <img class="block-image" src={campaignScreen01} srcSet={`${campaignScreen01}, ${campaignScreen01} 1x, ${campaignScreen01x2} 2x`}  alt="Ján Kadera" />
                        <div class="overlayer"></div>
                    </div>
                </div>

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    <img src={campaignScreen02}  srcSet={`${campaignScreen02}, ${campaignScreen02} 1x, ${campaignScreen02x2} 2x`}  alt="Ján Kadera" />
                    <div class="overlayer"></div>
                </div>

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    <img src={campaignScreen03}  srcSet={`${campaignScreen03}, ${campaignScreen03} 1x, ${campaignScreen03x2} 2x`}  alt="Ján Kadera" />
                    <div class="overlayer"></div>
                </div>
                
                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    <img src={campaignScreen04}  srcSet={`${campaignScreen04}, ${campaignScreen04} 1x, ${campaignScreen04x2} 2x`}  alt="Ján Kadera" />
                    <div class="overlayer"></div>
                </div>

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    <img src={campaignScreen05}  srcSet={`${campaignScreen05}, ${campaignScreen05} 1x, ${campaignScreen05x2} 2x`}  alt="Ján Kadera" />
                    <div class="overlayer"></div>
                </div>

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    <img src={campaignScreen06}  srcSet={`${campaignScreen06}, ${campaignScreen06} 1x, ${campaignScreen06x2} 2x`}  alt="Ján Kadera" />
                    <div class="overlayer"></div>
                </div>

                <div class="block--previews">
                    <div class="container-block"> 
                        <div class="previews-screens">
                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPreviews}
                            />
                            <div class="columns">
                                <div class="screen-image is-half">
                                    <img class="block-image" src={product01} srcSet={`${product01}, ${product01} 1x, ${product01_2x} 2x`}  alt="ján kadera" />
                                    <img class="block-image" src={product03} srcSet={`${product03}, ${product03} 1x, ${product03_2x} 2x`}  alt="ján kadera" />
                                </div>
                                <div class="screen-image is-half">
                                    <img class="block-image" src={product02} srcSet={`${product02}, ${product02} 1x, ${product02_2x} 2x`}  alt="ján kadera" />
                                    <img class="block-image" src={product04} srcSet={`${product04}, ${product04} 1x, ${product04_2x} 2x`}  alt="ján kadera" />
                                </div>
                            </div>
                        </div>
                        {/* <div className="link-row link-row--topMore mb-0">
                            <a href="https://www.aldona.sk/" target="blank">navštíviť WWW.ALDONA.SK <i className="arrow-next"></i></a>
                        </div> */}
                    </div>
                </div>

            </section>
        )
    }
}
