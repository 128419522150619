import React, { Component } from 'react'
import $ from "jquery";
import _ from 'lodash-es'

class counter extends Component {
    constructor(props){
        super(props)
        var c = 0;
        var i = setInterval(function(){
            $(".fade-in").html(c + "%" + '<div class="overlay" style="height: '+c+'%">'+c+'%</div>');
            if( c < 100){
              c++;
            }else{
                clearInterval(i);
                $('.counter').fadeOut();
                $('html').css('overflow-y', 'auto');
            }
        }, props.time/100);
        this.startAnimation(props);
    }
    startAnimation = (props) => {
        _.delay(function() { 
            $('#section1 .digital_agenciy').css('opacity', '0');
            $('#section1 .animation_1').css('transform', 'matrix(1, 0, 0, 1, 0, 0)')
        }, props.time+800);
        _.delay(function() {
            $('#section1 .sub_text').css('transition', 'opacity 2s ease-in-out').css('opacity', '1');
            $('#section1 .btn-holder').css('transition', 'opacity 2s ease-in-out').css('opacity', '1');
            $('#section1 .hero-right').css('transition', 'opacity 2s ease-in-out').css('opacity', '1');
            $('#section1 .info').css('transition', 'opacity 2s ease-in-out').css('opacity', '1');
        }, props.time+1300);
        _.delay(function() {
            $('#section1 .Mybtn').css('transition', 'all .5s ease-in');
            $('#section1 .Mybtn rect').css('transition','stroke-dashoffset 1s ease').css('stroke-dashoffset', '0px');
            $('#section1 .Mybtn .text').css('animation','blur 2s ease-in').css('animation-fill-mode','forwards');
            $('.work').css('transition', 'opacity 2s ease-in-out').css('opacity', '1');
        }, props.time+1700);
        _.delay(function() {
            $('#section1 .logo img').css('left', '0px');
        }, props.time+2000);
    }
    render() {
        return (
            <div className="counter">
                <div className="fade-in">0% 
                    <div className="overlay">0%</div>
                </div>
            </div>
        )
    }
}
export default counter