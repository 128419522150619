import React from 'react'
import { Helmet } from "react-helmet";

// glboal components
import Footer from '../../components/home/Footer'

// hero specifiy components 
import HeroItems from '../../components/reference/HeroItems'
import ReferenceDrTheiss from '../../components/reference/ContentDrTheiss'
import Header from '../../components/reference/Header'
import Contact from '../../components/reference/Contact'
import Portfolio from '../../components/home/Portfolio'

import FreeConsultation from '../../components/home/FreeConsultation'

//import Notifications, { notify } from 'react-notify-toast';

class Drtheiss extends  React.Component {
  constructor(){
    super();

    this.state = {
        hasPortfolio: true, // portfolio on/off
        subtitle: "Drtheiss",
        title: "Kvalitné produkty pod nemeckou záštitou",
        hashTags: [
            "#2020 / 2021 / 2022",
            "#UI/UX dizajn",
            "#WEB",
            "#Marketing"
        ]
    }
  }

  render() {
      return (
          <div className="referenceView drtheiss">
              <Helmet>
                <title>DR THEISS | Referencie | MATATE</title>
                <meta name="description" content="Dr Theiss referencia matate" />
              </Helmet>
              <FreeConsultation />
              <Header />
              <HeroItems subtitle={this.state.subtitle} title={this.state.title} hashTags={this.state.hashTags} />
              <ReferenceDrTheiss />
              <Portfolio status={this.state.hasPortfolio} />
              <Contact />
              <Footer />
          </div>
        )
    }
}

export default Drtheiss;
