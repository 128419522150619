import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'

import aldona from '../../images/aboutus/aldona.svg'
import drtheiss from '../../images/aboutus/drtheiss.svg'
import giganet from '../../images/aboutus/giganet.svg'
import lacalut from '../../images/aboutus/lacalut.svg'
import medirex from '../../images/aboutus/medirex.svg'
import mirano from '../../images/aboutus/mirano.svg'
import transparex from '../../images/aboutus/transparex.svg'
import viaadria from '../../images/aboutus/viaadria.svg'


export default class Brands extends Component {
    brands_load = () => {
        $('.freeCons').css('opacity','1');
        _.delay(function() {
            $('.section2 .wall').css('transform', 'translateX(200%)')
        }, 200);
    }
    render() {
        return (
            <div id="section2" className="section2">
                <Waypoint
                    scrollableAncestor={window}
                    onEnter={this.brands_load}
                />
                <div className="wall"></div>
                <div className="brands-container">
                    <div className="first_img_col">
                        <a target="_blank" href="https://lacalut.sk/">
                            <img src={lacalut} alt="." /></a>
                        <a target="_blank" href="https://www.theiss.sk/">
                            <img src={drtheiss} alt="." /></a> 
                        <a target="_blank" href="https://www.medirexgroupacademy.sk/">
                            <img src={medirex} alt="." /></a> 
                        <a target="_blank" href="https://www.transparex.sk/">
                            <img src={transparex} alt="." /></a> 
                    </div>
                    <div className="second_img_col">
                        <a target="_blank" href="https://www.mirano.sk/">
                            <img src={mirano} alt="." /></a>
                        <a target="_blank" href="https://www.giganet.sk/">
                            <img src={giganet} alt="."  /></a>
                        <a target="_blank" href="https://aldona.sk/">
                            <img src={aldona} alt="."  /></a>
                        <a target="_blank" href="https://www.viaadria.eu/">
                            <img src={viaadria} alt="."  /></a>
                    </div>
                </div>
            </div>
        )
    }
}
