import React from 'react'
import { TweenMax } from 'gsap/all'

class CustomCursor extends React.Component {
    constructor(cursor, target) {
        super();
		this.cursor = cursor;
		this.circle = this.cursor.querySelector('.circle');
		this.target = target;
		this.text = this.target.getAttribute('datahover')
        this.cursor.querySelector('.text').innerHTML = this.text;
        this.cursor.style.display = 'none';
		this.state = {
			isDown: false
		}
	}
	getBounds() {
		const { width, height } = this.cursor.getBoundingClientRect()
		return { width, height }
	}
	init() {
		this.initEvents()
	}
	initEvents() {
		this.target.addEventListener('mousemove', e => this.mouseMove(e))
    this.target.addEventListener('mouseenter', e => {
        this.cursor.style.display = 'flex';
    });
    this.target.addEventListener('mouseleave', e => {
        this.cursor.style.display = 'none';
    });
  }
	mouseMove(e) {

		const { clientX, clientY } = e;
		const { width, height } = this.getBounds()
		
		TweenMax.to(this.cursor, .5, {
			x: clientX - width / 2,
			y: clientY - height / 2
		})
	}
}

export { CustomCursor }