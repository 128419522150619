import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
//import _ from 'lodash-es'

import analytika01 from '../../images/services/analytika/analytika-01.png'
import analytika02 from '../../images/services/analytika/analytika-02.png'

import analytika01retina from '../../images/services/analytika/analytika-01@2x.png'
import analytika02retina from '../../images/services/analytika/analytika-02@2x.png'
 

export default class ContentAnalytika extends Component {
    blocks_load = () => {;
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    <div className="block">
                        <div className="block__text">
                            <div className="text-toptitle">UI/UX</div>
                            <h2>Počúvajte, čo vám klienti hovoria</h2>
                            <p>Marketing stojí na dátach. Čím viac ich máme, tým presnejšie vieme odhadnúť správanie vašej cieľovej skupiny. A nastaviť úspešnú kampaň.</p>
                        </div>
                        <div className="block__image">
                            <img src={analytika01} srcSet={`${analytika01}, ${analytika01} 1x, ${analytika01retina} 2x`} alt="Používateľské testovanie" />
                        </div>
                    </div>
                    <div className="block">
                        <div className="block__image">
                            <img src={analytika02}  srcSet={`${analytika02}, ${analytika02} 1x, ${analytika02retina} 2x`} alt="Prototypy" />
                        </div>
                        <div className="block__text">
                            <div className="text-toptitle">s možnosťou administrácie.</div>
                            <h2>Nehádajte, merajte</h2>
                            <p>Nastavením a prepojením analytických nástrojov s vaším webom získame prístup k užitočným dátam. Relevantné info = lepšie výsledky.</p>
                        </div>
                    </div>
                </div> 
            </section>
        )
    }
}
