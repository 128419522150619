import React, { useEffect, useState } from "react";
//import axios from "axios";
import { gql, useQuery } from "@apollo/client";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const GET_BLOG_POSTS = gql`
  query GetPosts {
    posts {
      nodes {
        id
        date
        content
        title
        slug
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  }
`;

const List = () => {
  const { loading, error, data } = useQuery(GET_BLOG_POSTS);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    data &&
      setArticles(() =>
        data.posts.nodes.map(({ featuredImage, ...article }) => {
          return {
            ...article,
            thumbnail: { url: featuredImage.node.sourceUrl },
          };
        })
      );
  }, [data]);

  console.log(data);
  return (
    <div className="blogList">
      <div className="hero">
        <div className="hero__subtitle">Blog.</div>
        <h1 className="hero__title">
          Prečítajte si články z<br />
          <strong>nášho blogu</strong>
        </h1>
      </div>

      <div className="items">
        {!loading && !articles.length > 0 && (
          <div className="noContent">
            Aktuálne sa tu nenachádzajú žiadne články
          </div>
        )}
        {loading ? (
          <SkeletonTheme baseColor="#202020" highlightColor="#444">
            <Skeleton height={300} />
            <Skeleton height={30} />
          </SkeletonTheme>
        ) : (
          articles.map((article) => (
            <a
              key={article.id}
              className="blog-item"
              href={`${"/blog/" + article.slug}`}
            >
              <img src={article.thumbnail.url} alt="Some title" />
              <h2>{article.title}</h2>
              <div class="link">Čítať článok</div>
            </a>
          ))
        )}
      </div>
    </div>
  );
};

export default List;
