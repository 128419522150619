import React, { Component } from 'react'
//import { Waypoint } from 'react-waypoint';
//import $ from "jquery"
import _ from 'lodash-es'

export default class Breadcrumb extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            breadcrumb: props.breadcrumb
        }
    }

    render() {
        return (
           <div className="breadcrumb">
                <ul>
                    <li><a href="/">Domov</a></li>
                    {
                        this.state.breadcrumb.map(obj => ( 
                        <li>  
                            <a href={obj.link}>{obj.title}</a>
                        </li>  
                        )) 
                    }  
                </ul>
           </div>
        )  
    }
}
