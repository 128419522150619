import React from 'react'
import { Helmet } from "react-helmet";

// glboal components
import Footer from '../../components/home/Footer'

// hero specifiy components 
import HeroItems from '../../components/reference/HeroItems'
import ReferenceAldona from '../../components/reference/ContentAldona'
import Header from '../../components/reference/Header'
import Contact from '../../components/reference/Contact'
import Portfolio from '../../components/home/Portfolio'

import FreeConsultation from '../../components/home/FreeConsultation'

//import Notifications, { notify } from 'react-notify-toast';

class  Aldona extends  React.Component {
  constructor() {
    super();

    this.state = {
        hasPortfolio: true, // portfolio on/off
        subtitle: "Aldona",
        title: "Transporty v rámci celej Európy",
        hashTags: [
            "#2020 - 2021",
            "#WEB",
            "#LOGOTYP",
            "#ANIMÁCIE",
            "#ILUSTRÁCIE",
            "#MARKETING"
        ],   
     }
  }

  render() {
      return (
          <div className="referenceView aldona">
              <Helmet>
                <title>Aldona | Referencie | MATATE</title>
                <meta name="description" content="Aldona referencia matate" />
              </Helmet>
              <FreeConsultation />
              <Header />
              <HeroItems subtitle={this.state.subtitle} title={this.state.title} hashTags={this.state.hashTags} />
              <ReferenceAldona />
              <Portfolio status={this.state.hasPortfolio} />
              <Contact />
              <Footer />
          </div>
        )
    }
}

export default Aldona;
