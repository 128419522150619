import * as React from 'react'
import _ from 'lodash-es'
import $ from "jquery"
import { Waypoint } from 'react-waypoint';

import ReviewsList from './ReviewsList'

const Reviews_LoadPage = () => {
    _.delay(function() {
        $('#section7 .Mybtn .stroke').css('animation-name', 'rotate');
        $('#section7 .black_wall').css('transform', 'translate(100%)');
        $('#section7 svg .line').css('transition','stroke-dashoffset 2s ease').css('stroke-dashoffset', '5600px');
    }, 100);
    _.delay(function() {
        $('.animation_10').css('transform', 'matrix(1, 0, 0, 1, 0, 0)');
    }, 200);
    _.delay(function() {
        $('#section7 .Mybtn').addClass('zisk_animated');
        $('#section7 .uvodzovky').css('display', 'block');
    }, 1000);
    _.delay(function() {
        $('#section7 .text').css('opacity', '1');
    }, 1500);
    _.delay(function() {
        $('#section7 .z_cele').css('opacity', '1');
    }, 1600);
    _.delay(function() {
        $('.animation_11').css('transform', 'matrix(1, 0, 0, 1, 0, 0)');
        $('#section7 .zisk0').addClass('zisk_animated');
        $('#section7 .zisk1').addClass('zisk_animated');
        $('#section7 .zisk3').addClass('zisk_animated');
        $('#section7 .zisk2').addClass('zisk_animated');
        $('#section7 .animeImg').addClass('zisk_animated');
    }, 1800); 
}

class Reviews extends React.Component{
    constructor(props) {
        super(props);
        this.count_slides = 2
        this.state = {
            order: 1,
        }
    }

    resetAnimation(){
        $('#section7 .Mybtn .stroke').css('animation-name', 'none');
        setTimeout(function() {
            $('#section7 .Mybtn .stroke').css('animation-name', 'rotate');
        }, 1);
    }

    handleClick = (event) => {
        this.Next(this.props)
        this.resetAnimation()
        clearInterval(this.myInterval)
        this.interval(this.props)
    }

    Next = (props) => {
        if(this.state.order != this.count_slides)
            this.setState({order: this.state.order + 1})
        else
            this.setState({order: (this.state.order - this.count_slides) + 1})
    }

    load = (props) => {
        Reviews_LoadPage()
        this.interval(this.props)
    }

    interval = (props) => {
        this.myInterval = setInterval(() => {
            this.Next(this.props)
        }, 8100)
        console.log(this.myInterval)
    }

    _renderWaypoint = () => {
        return (
            <Waypoint
            scrollableAncestor={window}
            onEnter={ this.load.bind(this,this.props)} />
        )
    }

    render() {
        return (
            <section id="section7" className="section7 ">
                {this._renderWaypoint()}

                <div className="bg">
                    {/* <div className="black_wall"></div> */}

                    <ReviewsList actual={this.state.order}/>
                </div>
            </section>
        )
    }
    
}

export default Reviews
