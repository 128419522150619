import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'

export default class Hero extends Component {
    hero_load = () => {
        _.delay(function() {
            $('.hero--sub__text').css('transform','none').css('opacity', '1');
        }, 100);
    }

    render() {
        return (
            <div class="hero--sub hero--services">
                <Waypoint
                    scrollableAncestor={window}
                    onEnter={this.hero_load}
                />

                <div class="reveal_wrap">
                    <div class="hero--sub__text">
                        <div class="hero-subtitle">Cookie.</div>
                        <h1>Cookies lišty a súhlasy <strong>na webe</strong></h1>
                        <p>Pomôžeme vám s implementáciou cookies lišty na váš web a so správnym nastavením všetkého čo k tomu treba</p>
                    </div>
                </div>
            </div>
        )
    }
}
