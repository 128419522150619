import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'
import { withTranslation } from "react-i18next"; // Import withTranslation
//import i18n from 'i18next';

import oneB from  '../../images/aboutus/team/1b.svg'
import oneS from  '../../images/aboutus/team/1s.svg'
import twoB from  '../../images/aboutus/team/2b.svg'
import twoS from  '../../images/aboutus/team/2s.svg'
import threeB from  '../../images/aboutus/team/3b.svg'
import threeS from  '../../images/aboutus/team/3s.svg'
import fourB from  '../../images/aboutus/team/4b.svg'
import fourS from  '../../images/aboutus/team/4s.svg'
import fiveB from  '../../images/aboutus/team/5b.svg'
import fiveS from  '../../images/aboutus/team/5s.svg'
import sixB from  '../../images/aboutus/team/6b.svg'

class Team extends Component {
    team_load = () => {
        _.delay(function() {
            $('.team .AU_animation7').css('transform', 'translateX(0)')
        }, 400);
        _.delay(function() {
            $('.team .AU_animationTeam.an1').css('transform', 'translateX(0)')
            $('.team .AU_animationTeam.an1').css('transform', 'scale(1)')
            $('.team .AU_animationTeam.an1').css('transform', 'translateX(0)')
        }, 600);
        _.delay(function() {
            $('.team .AU_animationTeam.an2').css('transform', 'translateX(0)')
            $('.team .AU_animationTeam.an2').css('transform', 'scale(1)')
            $('.team .AU_animationTeam.an2').css('transform', 'translateX(0)')
        }, 800);
        _.delay(function() {
            $('.team .AU_animationTeam.an3').css('transform', 'translateX(0)')
            $('.team .AU_animationTeam.an3').css('transform', 'scale(1)')
        }, 1000);
    }

    render() {
        const { t } = this.props; // Access t from props

        return (
            <div className="team">
                <Waypoint
                    scrollableAncestor={window}
                    onEnter={this.team_load}
                />
                <div className="cont">
                    <div className="top reveal_wrap">
                        <div className="AU_animation7">
                            <h2>{t('pageOnas.team.title1')}</h2>
                            <h1>{t('pageOnas.team.title2')}</h1>
                        </div>
                    </div>
                    <div className="bottom row">
                        <div className="one col-lg-4 AU_animationTeam an1">
                            <img src={oneB} alt=""/>
                            <h2>Dávid</h2>
                            <h3>🚀 {t('pageOnas.team.members.member1.role')}</h3>
                            <img className="head" src={oneS} alt=""/>
                        </div>
                        <div className="one col-lg-4 AU_animationTeam an1">
                            <img src={twoB} alt=""/>
                            <h2>René</h2>
                            <h3>✏️ {t('pageOnas.team.members.member2.role')}</h3>
                            <img className="head" src={twoS} alt=""/>
                        </div>
                        <div className="one col-lg-4 AU_animationTeam an1">
                            <img src={threeB} alt=""/>
                            <h2>Kristína </h2>
                            <h3>🔎 {t('pageOnas.team.members.member3.role')}</h3>
                            <img className="head" src={threeS} alt=""/>
                        </div>
                        <div className="one col-lg-4 AU_animationTeam an2">
                            <img src={fourB} alt=""/>
                            <h2>Martin</h2>
                            <h3>✏️ {t('pageOnas.team.members.member4.role')}</h3>
                            <img className="head" src={fourS} alt=""/>
                        </div>
                        <div className="one col-lg-4 AU_animationTeam an2">
                            <img src={fiveB} alt=""/>
                            <h2>Lukáš</h2>
                            <h3>🔎 {t('pageOnas.team.members.member5.role')}</h3>
                            <img className="head" src={fiveS} alt=""/>
                        </div>
                        {/*
                            <div className="one col-lg-4 AU_animationTeam an2">
                                <img src={oneB} alt=""/>
                                <h2>Daniel Babčan</h2>
                                <h3>✏️ Online marketér</h3>
                                <img className="head" src={oneS} alt=""/>
                            </div>
                        */ }
                        <div className="one col-lg-4 AU_animationTeam an3">
                            <img src={sixB} alt=""/>
                            <h2>Martin</h2>
                            <h3>🖥️ {t('pageOnas.team.members.member6.role')}</h3>
                        </div>
                        {/*
                        <div className="one col-lg-4 AU_animationTeam an3">
                            <img src={fiveB} alt=""/>
                            <h2>Lukáš Kula</h2>
                            <h3>🔎 SEO analytik</h3>
                            <img className="head" src={fiveS} alt=""/>
                        </div>
                        */ }
                    </div>
                
                </div>
                <svg className="team_line" width="309" height="795" viewBox="0 0 309 795" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className="line"  d="M-13.4499 38.4317C-11.568 38.936 -9.68605 39.4403 -7.80413 39.9445C233.146 108.483 305.122 405.381 120.02 562.667L-74.7326 728.211" stroke="url(#paint0_linear)" stroke-width="24" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <g filter="url(#filter0_f)">
                    <path d="M-65.3991 38.4318C-63.4677 38.6877 -61.5363 38.9436 -59.6048 39.1995C188.199 75.9082 298.075 360.963 134.936 540.928L-36.7004 730.334" stroke="url(#paint1_linear)" stroke-opacity="0.3" stroke-width="24" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <filter id="filter0_f" x="-192.473" y="0.959473" width="479.696" height="792.93" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur"/>
                    </filter>
                    <linearGradient id="paint0_linear" x1="81.1568" y1="741.202" x2="117.922" y2="39.9567" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#521B78"/>
                    <stop offset="0.494792" stop-color="#EA375C"/>
                    <stop offset="1" stop-color="#10A6E1"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear" x1="119.557" y1="722.996" x2="65.061" y2="22.9051" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#521B78"/>
                    <stop offset="0.494792" stop-color="#EA375C"/>
                    <stop offset="1" stop-color="#10A6E1"/>
                    </linearGradient>
                    </defs>
                </svg>
            </div>
        )
    }
}

export default withTranslation()(Team);