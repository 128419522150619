import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'
 
import eye from '../../images/references/casopis/eye.svg'
import wireframe from '../../images/references/casopis/wireframe.svg'

import coverImage from '../../images/references/casopis/casopis-building.png'
import coverImage2x from '../../images/references/casopis/casopis-building@2x.png'

import refImage01 from '../../images/references/casopis/screen-01.png'
import refImage01_2x from '../../images/references/casopis/screen-01@2x.png'

import refImage02 from '../../images/references/casopis/screen-02.png'
import refImage02_2x from '../../images/references/casopis/screen-02@2x.png'

import refImage03 from '../../images/references/casopis/screen-03.png'
import refImage03_2x from '../../images/references/casopis/screen-03@2x.png'

import refImage04 from '../../images/references/casopis/screen-04.png'
import refImage04_2x from '../../images/references/casopis/screen-04@2x.png'

import refImage05 from '../../images/references/casopis/screen-05.png'
import refImage05_2x from '../../images/references/casopis/screen-05@2x.png'

import mockupImage from '../../images/references/casopis/screen-part.png'
import mockupImage_2x from '../../images/references/casopis/screen-part@2x.png'

export default class ContentCasopis extends Component {

    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $(".back-link").css("opacity", "1");
        }, 1500);
    }

    blockIntro = () => {
        _.delay(function() {
            $(".block--intro").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(0).css("opacity", "1").css("transform", "none");
        }, 400);
        
        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(1).css("opacity", "1").css("transform", "none");
        }, 750);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(2).css("opacity", "1").css("transform", "none");
        }, 950);
    }

    blockCoverImage = () => {
        $(".block--cover-image").find('.overlayer').css("width", "0");
    }

    blockScreen = () => {
        $(".block--screens").find(".block-image").css("opacity", "1").css("transform", "none");
    }

    blockPreviews = () => {

        _.delay(function() {

            let screens = $(".block--previews").find(".block-image");

            $.each(screens, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);
    }

    blockColumns = () => {

        let columnItems = $(".block--columns").find(".services__item");

        _.delay(function() {
            $.each(columnItems, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);

    }

    blockImages = () => {

        _.delay(function() {
            $(".block-images").find(".overlayer").css("width", "0");
        }, 200);

        _.delay(function() {
            $(".block-images").find("img").css("opacity", "1").css("transform", "none");
        }, 1000);
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                

                <div class="container-block"> 
                    <div class="block--intro">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockIntro}
                            bottomOffset="20%"
                        />
                        <h2>Web pre časopis s možnosťou <br />online prehliadania</h2>
                        <p>Pre architektonický časopis Projekt, ktorý vychádza už od roku 1959, sme vytvorili úplne nový web s možnosťou prehliadania ukážok časopisu online.</p>
                    </div>

                    <div class="block--boxes">
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={eye} alt="UI UX dizajn" />
                                <h3>UX/UI DIZAJN</h3>
                            </div>
                            <p>Od šéfredaktorky a tímu časopisu Projekt sme dostali zadanie vytvoriť webovú stránku pre časopis, ktorá bude minimalistická a bude sa ponášať v dizajne samotného časopisu, ktorý vychádza 4x ročne.</p>
                            <p>Web mal byť moderný, ale zároveň čiernobiely a obohacovať ho mali veľké fotky, ktoré sa využívajú aj v konkrétnych článkoch v časopise.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={wireframe} alt="PROGRAMOVANIE A CMS" />
                                <h3>PROGRAMOVANIE A CMS</h3>
                            </div>
                            <p>Webovú stránku sme zastrešovali komplet na mieru a mali sme v rukách aj implementáciu dizajnu. Web sme naprogramovali a nasadili na CMS Wordpress pre správu obsahu. Klienti majú možnosť jednoducho si nahrávať časopisy na web.</p>
                            <p>Návštevníci webu majú možnosť prehliadať si ukážky časopisu priamo na webe.</p>
                        </div>
                    </div>

                    <div className="link-row link-row--topNegative">
                        <a href="https://www.casopisprojekt.sk/" target="blank">navštíviť WWW.casopisprojekt.sk <i className="arrow-next"></i></a>
                    </div>
                </div> 

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                     <img src={coverImage}  srcSet={`${coverImage}, ${coverImage} 1x, ${coverImage2x} 2x`}  alt="casopis" />
                    <div class="overlayer"></div> 
                </div>

                <div className="block--columns">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockColumns}
                            bottomOffset="10%"
                        />
                    <div className="container-block">
                        <div className="services">
                            <div className="services__item columns">
                                <div className="services__image column is-half">
                                    <img src={refImage01}  srcSet={`${refImage01}, ${refImage01} 1x, ${refImage01_2x} 2x`}  alt="casopis" />
                                </div>
                                <div className="services__image column is-half last">
                                    <img src={refImage02}  srcSet={`${refImage02}, ${refImage02} 1x, ${refImage02_2x} 2x`}  alt="casopis" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block--screens">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockScreen}
                            bottomOffset="5%"
                        />
                    <img class="block-image" src={mockupImage} srcSet={`${mockupImage}, ${mockupImage} 1x, ${mockupImage_2x} 2x`}  alt="casopis" />
                </div>

                <div class="block--previews">
                    <div class="container-fluid"> 
                        <div class="previews-screens">

                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPreviews}
                            />
                            <div class="columns">
                                <div class="screen-image">
                                    <img class="block-image" src={refImage03} srcSet={`${refImage03}, ${refImage03} 1x, ${refImage03_2x} 2x`}  alt="casopis" />
                                </div>
                                <div class="screen-image">
                                    <img class="block-image" src={refImage04} srcSet={`${refImage04}, ${refImage04} 1x, ${refImage04_2x} 2x`}  alt="casopis" />
                                </div>
                                <div class="screen-image">
                                    <img class="block-image" src={refImage05} srcSet={`${refImage05}, ${refImage05} 1x, ${refImage05_2x} 2x`}  alt="casopis" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
