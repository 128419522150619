
import React, { Component } from "react";
import { Waypoint } from "react-waypoint";
import $ from "jquery";
import _ from "lodash-es";
import logo from '../../images/matate-logo.svg'
//import { TweenMax } from "gsap/all";

// import Breadcrumb from "../commons/Breadcrumb";

import { withTranslation } from "react-i18next"; // Import withTranslation
import i18n from 'i18next';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: false,
      //breadcrumb: props.breadcrumb
    };
  }

  handleLanguageChange = (language) => {
    
    if (i18n.language == "en") {
        window.replace.location("/en");
    } else {
        window.replace.location("/");
    }
    
    const routes = i18n.getResourceBundle(i18n.language, 'routes');
    const currentPathname = window.location.pathname.replace(/\/+$/, '');
    const currentRouteKey = Object.keys(routes).find((key) => routes[key] === currentPathname);
    window.location.replace(this.props.t(`routes:${currentRouteKey}`, { lng: language }));
    
    i18n.changeLanguage(language); // Access i18n from props
  }

  header_load = () => {
    _.delay(function () {
      $(".header_line .line-path")
        .css("transition", "stroke-dashoffset 3s ease")
        .css("stroke-dashoffset", "5600px");
    }, 100);
  };

  menuOpen = () => {
    //$('html').css('overflow-y', 'hidden');
    let menu = $(".overlay-menu");
    let menuLeft = $(".main-menu__left");
    let menuRight = $(".main-menu__right");

    // TweenMax.to(menu, 0.5, {
    //     autoAlpha: 1
    // });

    menu.css("visibility", "visible").css("opacity", "1");
    menu.addClass("is-showed");

    let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1;
    let safariAgent = navigator.userAgent.indexOf("Safari") > -1;

    if (chromeAgent && safariAgent) {
      // the user is running Safari
      // do something useful
      _.delay(function () {
        //$('.menu-line .line-path').css('stroke-dashoffset', '0');
        $(".menu-line .line-path").addClass("line-anim");
      }, 100);
    } else if (!safariAgent) {
      _.delay(function () {
        //$('.menu-line .line-path').css('stroke-dashoffset', '0');
        $(".menu-line .line-path").addClass("line-anim");
      }, 100);
    } else {
      $(".menu-line .line-path").addClass("fixed-line");
    }

    _.delay(function () {
      menuLeft.css("transform", "none").css("opacity", "1");
      menuRight.css("transform", "none").css("opacity", "1");
    }, 500);

    // TweenMax.to(menuLeft, 0.6, {
    //     autoAlpha: 1,
    //     x: 0,
    //     delay: 0.4
    // });

    // TweenMax.to(menuRight, 0.6, {
    //     autoAlpha: 1,
    //     x: 0,
    //     delay: 0.4
    // });
  };

  menuClose = () => {
    //$('html').css('overflow-y', 'auto');
    let menu = $(".overlay-menu");

    _.delay(function () {
      menu.removeClass("is-showed");
    }, 300);

    _.delay(function () {
      menu.css("visibility", "hidden").css("opacity", "0");
    }, 200);

    // TweenMax.to(menu, 0.5, {
    //     autoAlpha: 0
    // });
  };

  render() {
    return (
      <div class="page-header">
        <header>
          <div className="cursor">
            <div className="circle"></div>
            <div className="text"></div>
          </div>

          <Waypoint scrollableAncestor={window} onEnter={this.header_load} />

          <div className="header">
            <span
              className="left"
              onMouseEnter={(e) => this.setState({ logo: true })}
              onMouseLeave={(e) => this.setState({ logo: false })}
            >
              <div
                className={`text position-absolute ${
                  !this.state.logo ? "opacity-0" : ""
                }`}
              >
                Pekné weby a kampane, <span>ktoré fungujú.</span>
              </div>
              <a href="/" className={this.state.logo ? "opacity-0" : ""}>
                <img src={logo} alt="" />
              </a>
            </span>

            <div className="navigation-bar">
              <button className="hambi-btn" onClick={this.menuOpen}>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>

          {/* <Breadcrumb breadcrumb={this.state.breadcrumb} /> */}

          {/* <svg className="header_line" width="601" height="364" viewBox="0 0 601 364" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M107.424 605.319C-183.445 238.761 240.636 -200.549 647.347 143.203C1054.06 486.955 1248.2 283.004 1208.85 11.4058" stroke="black" stroke-width="21" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M107.424 605.319C-183.445 238.761 240.636 -200.549 647.347 143.203C1054.06 486.955 1248.2 283.004 1208.85 11.4058" stroke="url(#paint0_linear)" stroke-width="21" stroke-linecap="round" stroke-linejoin="round"/>
                    <defs>
                    <linearGradient id="paint0_linear" x1="1072.74" y1="172.951" x2="137.19" y2="479.38" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#0FA6E1"/>
                    <stop offset="0.538272" stop-color="#F3385A"/>
                    <stop offset="1" stop-color="#45187A"/>
                    </linearGradient>
                    </defs>
                </svg> */}
                

          <svg
            className="header_line"
            width="296"
            height="803"
            viewBox="0 0 296 803"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="line-path"
              d="M317.766 40.6287C315.904 41.2033 314.042 41.7778 312.181 42.3524C73.9742 119.889 13.1964 419.281 204.073 569.506L404.904 727.621"
              stroke="url(#paint0_linearh)"
              stroke-width="21"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <g filter="url(#filter0_fh)">
              <path
                className="line-path"
                d="M369.678 38.6783C367.758 39.0065 365.837 39.3348 363.917 39.663C117.666 85.6498 18.5707 374.629 188.351 548.342L366.978 731.17"
                stroke="url(#paint1_linearh)"
                stroke-opacity="0.3"
                stroke-width="24"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_fh"
                x="18.4675"
                y="0.828033"
                width="503.989"
                height="801.648"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="BackgroundImageFix"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="12"
                  result="effect1_foregroundBlur"
                />
              </filter>
              <linearGradient
                id="paint0_linearh"
                x1="249.612"
                y1="746.456"
                x2="186.544"
                y2="47.0851"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#521B78" />
                <stop offset="0.494792" stop-color="#EA375C" />
                <stop offset="1" stop-color="#2F80ED" />
              </linearGradient>
              <linearGradient
                id="paint1_linearh"
                x1="210.555"
                y1="729.704"
                x2="264.02"
                y2="-11"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#521B78" />
                <stop offset="0.494792" stop-color="#EA375C" />
                <stop offset="1" stop-color="#2F80ED" />
              </linearGradient>
            </defs>
          </svg>
        </header>

        <div className="overlay-menu">
          <div class="overlay-menu__content">
            <button className="main-menu-close" onClick={this.menuClose}>
              <span></span>
              <span></span>
            </button>
            <div className="main-menu__content">
              <div className="main-menu__left">
                <nav className="main-nav">
                  <ul>
                    <li>
                      <a href={this.props.t('home', {ns:'routes'})} hreflang={i18n.language}>{this.props.t('menu.item1')}</a>
                    </li>
                    <li>
                      <a href={this.props.t('reference.index',{ns:'routes'})} hreflang={i18n.language}> {this.props.t('menu.item2')}</a>
                    </li>
                    <li>
                      <a href={this.props.t('service.index', {ns:'routes'})} hreflang={i18n.language}>{this.props.t('menu.item3')}</a>
                    </li>
                    <li>
                      <a href={this.props.t('aboutUs', {ns:'routes'})} hreflang={i18n.language}>{this.props.t('menu.item4')}</a>
                    </li>
                    <li>
                      <a href={this.props.t('blog', {ns:'routes'})} hreflang={i18n.language}>{this.props.t('menu.item5')}</a>
                    </li>
                    <li>
                      <a href={this.props.t('contact', {ns:'routes'})} hreflang={i18n.language}>{this.props.t('menu.item6')}</a>
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="main-menu__right">

              <div className="menu-group">
                  <div className="menu-group__title">Language</div>
                  <ul className="main-menu__language">
                    <li lang="sk">
                        <a href="/" onClick={() => this.handleLanguageChange('sk')}>SK</a>
                    </li>
                    <li lang="en">
                        <a href="/en" onClick={() => this.handleLanguageChange('en')}>EN</a>
                    </li>
                  </ul>
                </div>

                <div className="menu-group">
                  <div className="menu-group__title">Kontakt</div>
                  <ul>
                    <li>
                      <a href="tel:+421902359330">+421 902 359 330</a>
                    </li>
                    <li>
                      <a href="mailto:info@matate.sk">info@matate.sk</a>
                    </li>
                  </ul>
                </div>

                <div className="menu-group">
                  <div className="menu-group__title">Social</div>
                  <ul className="main-menu__social">
                    <li>
                      <a href="https://dribbble.com/MATATE" target="_blank">
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.2771 12.5049C17.2041 12.5049 16.1085 12.6106 14.9901 12.8222C14.5821 11.6586 14.1212 10.5705 13.6074 9.55797C15.0128 8.89303 16.2634 8.0543 17.359 7.04177C18.4546 6.02925 19.3198 4.89583 19.9545 3.64152C21.2391 4.7296 22.2478 6.04436 22.9808 7.58581C23.7137 9.12727 24.0802 10.7669 24.0802 12.5049C24.0802 12.792 24.0651 13.132 24.0348 13.5249C22.1911 12.8449 20.2719 12.5049 18.2771 12.5049ZM12.9047 8.28854C11.2876 5.49277 9.36083 3.46772 7.12421 2.21341C8.80167 1.3369 10.5849 0.89864 12.474 0.89864C14.7559 0.89864 16.8565 1.51824 18.7758 2.75745C18.2015 3.9362 17.4119 5.00162 16.4069 5.95369C15.402 6.90576 14.2345 7.68404 12.9047 8.28854ZM11.5899 8.78724C10.0031 9.33128 8.36342 9.6033 6.67084 9.6033C4.91782 9.6033 3.20258 9.30106 1.52512 8.69657C2.32607 6.3995 3.70884 4.53314 5.67343 3.09747C7.9705 4.12511 9.94265 6.0217 11.5899 8.78724ZM6.67084 11.0541C8.63544 11.0541 10.5094 10.7292 12.2926 10.0793C12.7762 11.0465 13.2145 12.0742 13.6074 13.1622C11.4614 13.7818 9.59129 14.7793 7.99694 16.1545C6.4026 17.5297 5.24273 19.124 4.51734 20.9375C3.36881 19.8645 2.47341 18.5989 1.83114 17.1405C1.18887 15.6822 0.867733 14.137 0.867733 12.5049C0.867733 11.6888 0.95085 10.8803 1.11709 10.0793C2.94567 10.7292 4.79692 11.0541 6.67084 11.0541ZM14.0381 14.545C14.8844 17.4163 15.3226 20.4766 15.3528 23.7257C14.3857 23.9826 13.426 24.1111 12.474 24.1111C9.99554 24.1111 7.74381 23.3781 5.71877 21.9122C6.29304 20.1894 7.31689 18.6782 8.79034 17.3786C10.2638 16.0789 12.013 15.1344 14.0381 14.545ZM15.4435 14.2277C16.3956 14.0463 17.3401 13.9556 18.2771 13.9556C20.1812 13.9556 22.0249 14.3032 23.8082 14.9984C23.4001 16.8723 22.5727 18.5422 21.326 20.0081C20.0792 21.474 18.5718 22.5621 16.8036 23.2723C16.7281 20.0534 16.2747 17.0385 15.4435 14.2277Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/matate_sk/"
                        target="_blank"
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.0003 7.35484C8.99322 7.35484 7.35533 8.99273 7.35533 10.9998C7.35533 13.0068 8.99322 14.6447 11.0003 14.6447C13.0073 14.6447 14.6452 13.0068 14.6452 10.9998C14.6452 8.99273 13.0073 7.35484 11.0003 7.35484ZM21.9323 10.9998C21.9323 9.49039 21.946 7.99469 21.8612 6.48805C21.7764 4.73805 21.3772 3.18492 20.0975 1.90524C18.8151 0.622813 17.2647 0.226329 15.5147 0.141563C14.0053 0.0567978 12.5096 0.0704697 11.003 0.0704697C9.49361 0.0704697 7.99791 0.0567978 6.49127 0.141563C4.74127 0.226329 3.18814 0.625548 1.90846 1.90524C0.626033 3.18766 0.229549 4.73805 0.144783 6.48805C0.0600174 7.99742 0.0736893 9.49312 0.0736893 10.9998C0.0736893 12.5064 0.0600174 14.0048 0.144783 15.5115C0.229549 17.2615 0.628767 18.8146 1.90846 20.0943C3.19088 21.3767 4.74127 21.7732 6.49127 21.858C8.00064 21.9427 9.49635 21.9291 11.003 21.9291C12.5124 21.9291 14.0081 21.9427 15.5147 21.858C17.2647 21.7732 18.8178 21.374 20.0975 20.0943C21.3799 18.8119 21.7764 17.2615 21.8612 15.5115C21.9487 14.0048 21.9323 12.5091 21.9323 10.9998ZM11.0003 16.608C7.89674 16.608 5.39205 14.1033 5.39205 10.9998C5.39205 7.89625 7.89674 5.39156 11.0003 5.39156C14.1038 5.39156 16.6085 7.89625 16.6085 10.9998C16.6085 14.1033 14.1038 16.608 11.0003 16.608ZM16.8381 6.47164C16.1135 6.47164 15.5284 5.88648 15.5284 5.16188C15.5284 4.43727 16.1135 3.85211 16.8381 3.85211C17.5628 3.85211 18.1479 4.43727 18.1479 5.16188C18.1481 5.33394 18.1144 5.50435 18.0486 5.66336C17.9829 5.82236 17.8864 5.96684 17.7648 6.0885C17.6431 6.21017 17.4986 6.30664 17.3396 6.37238C17.1806 6.43813 17.0102 6.47186 16.8381 6.47164Z"
                            fill="#F2F2F2"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <svg
              className="svg-line menu-line"
              width="1528"
              height="999"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0m)">
                <g filter="url(#filter0_fm)">
                  <path
                    className="line-path"
                    d="M580.168 1437.45l.888 41.71c-344.599 262.1-827.786-258.71-515.83-795.174 311.954-536.463 8.452-949.634 354.55-1414.124 118.97-159.667 348.625-370.372 826.694-282.692"
                    stroke="url(#paint0_linearm)"
                    stroke-opacity=".3"
                    stroke-width="24"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <path
                  className="line-path"
                  d="M553.316 1424.17l.888 41.71c-344.599 262.11-827.786-258.71-515.83-795.172 311.954-536.463 8.452-949.634 354.55-1414.123 118.971-159.668 348.625-370.365 826.696-282.695"
                  stroke="url(#paint1_linearm)"
                  stroke-width="21"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linearm"
                  x1="291.794"
                  y1="1155.46"
                  x2="-6.851"
                  y2="267.512"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#03B6F2" />
                  <stop offset=".474" stop-color="#F3385A" />
                  <stop offset=".99" stop-color="#772390" />
                </linearGradient>
                <linearGradient
                  id="paint1_linearm"
                  x1="264.942"
                  y1="1142.18"
                  x2="-33.702"
                  y2="254.234"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#03B6F2" />
                  <stop offset=".474" stop-color="#F3385A" />
                  <stop offset=".99" stop-color="#772390" />
                </linearGradient>
                <clipPath id="clip0m">
                  <path
                    fill="#fff"
                    transform="scale(-1 1) rotate(69.215 686.18 -1040.844)"
                    d="M0 0h2895.22v1066.27H0z"
                  />
                </clipPath>
                <filter
                  id="filter0_fm"
                  x="-71.24"
                  y="-1069.42"
                  width="1353.73"
                  height="2654.5"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="12"
                    result="effect1_foregroundBlur"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Header);