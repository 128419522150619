
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

const common_en = require('./locales/en/translations.json');
const common_sk = require('./locales/sk/translations.json');

const root_en = require('./locales/en/routes.json');
const root_sk = require('./locales/sk/routes.json');

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'sk'],
    resources: {
      en: {
        translations: common_en,
        routes: root_en
      },
      sk: {
        translations: common_sk,
        routes: root_sk
      },
    },
    fallbackLng: 'en',
    lng: localStorage.getItem('i18nextLng') || 'sk',
    debug: false,
    ns: ['translations', 'routes'],
    defaultNS: 'translations',
    detection: {
        order: ['path', 'localStorge', 'htmlTag', 'cookie', 'navigator']
    },
    interpolation: {
      escapeValue: false,
    },
  });


  //let newurl = window.location.pathname.startsWith(`/en`) ? window.location.pathname.replace(`/en`, `/en`) : window.location.pathname.replace(`/`, '/')
 // window.location.replace(newurl);

  if(window.location.pathname.startsWith(`/en`)) {
    i18n.changeLanguage('en');
  } else {
    i18n.changeLanguage('sk');
  }

  // change html lang attribute
  document.documentElement.lang = i18n.language;

export default i18n;