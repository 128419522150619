import React from 'react'
import {Helmet} from "react-helmet";

import Header from './components/home/Header'
//import SectionWhy from './components/home/SectionWhy'
//import AboutUs from './components/home/AboutUs'
import OurServices from './components/home/OurServices'
//import Quality from './components/home/Quality'
import Reviews from './components/home/Reviews'
import Footer from './components/home/Footer'
import Portfolio from './components/home/Portfolio'
import Ceo from './components/home/Ceo'

import FreeConsultation from './components/home/FreeConsultation'
//import Notifications, {notify} from 'react-notify-toast';

import { withTranslation } from "react-i18next"; // Import withTranslation

class Home extends React.Component {
  constructor(){
    super();
    this.state = {
      lang: parseInt(localStorage.getItem('lang')), // dont used
      hasPortfolio: true // portfolio on/off
    }
    // // TODO: dont used we used react i18n instead
    if(localStorage.getItem('lang') == null)
      this.state = {
        lang: 0,
      }
  }
  saveLang = (val) => {
    localStorage.setItem('lang', val);
    this.setState({lang: val})
  } 
  render() {
      return (
          <div className="Home">
              <Helmet>
                <title>{this.props.t('pages.home.pageTitle')} | MATATE</title>
                <meta name="description" content={this.props.t('pages.home.metaText')} />
              </Helmet>
              {/* <FreeConsultation /> */}
              <Header saveLang={this.saveLang}  lang={this.state.lang}/>
              <Portfolio status="true" />
              <Ceo />
              <OurServices /> 
              {/* <SectionWhy /> */}
              {/* <AboutUs /> */}
              <Reviews />
              <Footer />
          </div>
        )
   }
}

export default withTranslation()(Home);
