import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'

import eye from '../../images/transparex/eye.svg'
import union from '../../images/transparex/union.svg'
import wireframe from '../../images/transparex/wireframe.svg'

import coverImage from '../../images/transparex/macbook.png'
import coverImage2x from '../../images/transparex/macbook@2x.png'

import transparexLogo from '../../images/transparex/transparex-logo.png'
import transparexLogo2x from '../../images/transparex/transparex-logo@2x.png'

import dizajnManual from '../../images/transparex/dizajn-manual.jpg'
import dizajnManual2x from '../../images/transparex/dizajn-manual@2x.jpg'

import transparexScreens from '../../images/transparex/transparex-mockup.jpg'
import transparexScreens2x from '../../images/transparex/transparex-mockup@2x.jpg'

import refImage01 from '../../images/transparex/ref-image-01.png'
import refImage01_2x from '../../images/transparex/ref-image-01@2x.png'

import refImage02 from '../../images/transparex/ref-image-02.png'
import refImage02_2x from '../../images/transparex/ref-image-02@2x.png'

import refImage03 from '../../images/transparex/ref-image-03.png'
import refImage03_2x from '../../images/transparex/ref-image-03@2x.png'

import refImage04 from '../../images/transparex/ref-image-04.png'
import refImage04_2x from '../../images/transparex/ref-image-04@2x.png'

import logoMobile from '../../images/transparex/transparex-inner-logo.svg'


export default class ContentTransparex extends Component {
    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $(".back-link").css("opacity", "1");
        }, 1500);

    }

    blockIntro = () => {
        _.delay(function() {
            $(".block--intro").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(0).css("opacity", "1").css("transform", "none");
        }, 400);
        
        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(1).css("opacity", "1").css("transform", "none");
        }, 750);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(2).css("opacity", "1").css("transform", "none");
        }, 950);
    }

    blockCoverImage = () => {
        $(".block--cover-image").find('.overlayer').css("width", "0");
    }

    blockLogo = () => {

        _.delay(function() {
            $(".block-top").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--logo").find(".block-image").css("opacity", "1").css("transform", "none");
        }, 500);
    }

    blockPack = () => {
        _.delay(function() {
            $(".block--image-pack").find(".overlayer").css("width", "0");
        }, 1000);
    }

    blockScreen = () => {
        $(".block--screens").find(".block-image").css("opacity", "1").css("transform", "none");
    }

    blockPreviews = () => {

        $('.preview-line-rp').css('transition','stroke-dashoffset 3s ease').css('stroke-dashoffset', '5600px');
        $('.preview-line-lp').css('transition','stroke-dashoffset 3s ease').css('stroke-dashoffset', '5600px');

        _.delay(function() {
            $(".preview-top").css("opacity", "1").css("transform", "none");
        }, 300);

        _.delay(function() {

            let screens = $(".previews-screens").find(".block-image");

            $.each(screens, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);
        
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    
                    <div class="block--intro">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockIntro}
                            bottomOffset="20%"
                        />
                        <h2>Transparentné dáta</h2>
                        <p>Transparex je novo vznikajúci medzinárodný projekt zaoberajúci sa firemnými dátami, verejným obstarávaním a vyhodnocovaním transparentnosti spoločností.  Pre tento projekt zabezpečujeme kompletný dizajn na mieru, UX a tiež sme vypracovali logo a dizajn manuál.</p>
                    </div>

                    <div>
                        <img class="brand-logo-mobile" src={logoMobile} alt="Transparex" />
                    </div>

                    <div class="block--boxes">
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={eye} alt="UI UX dizajn" />
                                <h3>UI/UX dizajn</h3>
                            </div>
                            <p>Na základe wireframes sme pre projekt Transparex vypracovali prepracované dizajny každej podstránky v desktopovom, ale aj mobilnom zobrazení. Pravidelne konzultujeme UX požiadavky a sme v úzkej spolupráci s programátormi. </p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={union} alt="Logo a dizajn manuál" />
                                <h3>Logo a dizajn manuál</h3>
                            </div>
                            <p>Značku Transparex sme dostali do grafického prevedenia a pripravili sme kompletný a detailný dizajn manuál, ktorý hovorí o správnom použití značky v rôznych situáciách. Zadefinovali sme farby, fonty a merkantil. Pripravili sme šablónu pre prezentáciu, mailový podpis či vizitky.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={wireframe} alt="Wireframy" />
                                <h3>Wireframy</h3>
                            </div>
                            <p>Pred začatím dizajnovania grafických návrhov sme pracovali na wireframoch (drôtených modeloch), ktoré zabezpečili správne pochopenie a rozvrhnutie prvkov na webe a naladenie sa na rovnakú vlnu s klientom.</p>
                        </div>
                    </div>
                </div> 

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    <img src={coverImage}  srcSet={`${coverImage}, ${coverImage} 1x, ${coverImage2x} 2x`}  alt="Transparex" />
                    <div class="overlayer"></div>
                </div>

                <div class="container-block"> 
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockLogo}
                        />
                    <div class="block--logo">
                        <div class="block-top">
                            <h2 class="block-title">Logo</h2>
                            <p class="block-text">Logo je základným prvkom vizuálnej prezentácie
                                značky. Preto treba dbať na správne používanie pre
                                profesionálny vzhľad komunikačných a firemných
                                materiálov.</p>
                        </div>
                        <img class="block-image" src={transparexLogo} srcSet={`${transparexLogo}, ${transparexLogo} 1x, ${transparexLogo2x} 2x`}  alt="Transparex" />
                    </div>
                </div>

                <div class="container-block"> 
                    <div class="block--image-pack">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockPack}
                            bottomOffset="10%"
                        />
                        <img class="block-image" src={dizajnManual} srcSet={`${dizajnManual}, ${dizajnManual} 1x, ${dizajnManual2x} 2x`}  alt="Transparex" />
                        <div class="overlayer"></div>
                    </div>
                </div>

                <div class="block--screens">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockScreen}
                            bottomOffset="5%"
                        />
                    <img class="block-image" src={transparexScreens} srcSet={`${transparexScreens}, ${transparexScreens} 1x, ${transparexScreens2x} 2x`}  alt="Transparex" />
                </div>

                <div class="block--previews">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockPreviews}

                        />
              
                    <div class="preview-top">
                        <div class="top-title">Slovná recenzia klienta:</div>
                        <p class="top-text">“V rámci medzinárodného projektu TRANSPAREX nám spoločnosť MATATE pomohla navrhnúť komplexný dizajn. Ceníme si vlastné inovácie a nápady, a zároveň zodpovednú dodávku služieb na kľúč. “</p>
                        <div class="company">
                            <div class="name">Doc. Ing. Radoslav Delina, PhD.,</div>
                            <div class="position">Odborný garant a analytik projektu Transparex</div>
                        </div>
                    </div>


                    <div class="previews-screens">

                         <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockPreviews}

                        />

                        <div class="svg-line-left">
                            <svg class="preview-line-l" width="456" height="1150" viewBox="0 0 456 1150" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path class="preview-line-lp" d="M92.0643 99.6451C94.0049 101.304 95.9455 102.962 97.8862 104.62C344.111 321.655 261.649 713.342 -45.9948 794.852L-369.711 880.679" stroke="url(#paint0_linear)" stroke-width="24" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <g filter="url(#filter0_f)">
                                <path class="preview-line-lp" d="M30.6374 70.3247C32.7768 71.7174 34.9161 73.1101 37.0555 74.5028C309.35 257.769 278.386 656.843 -16.0878 777.565L-325.938 904.654" stroke="url(#paint1_linear)" stroke-opacity="0.3" stroke-width="24" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                <filter id="filter0_f" x="-361.941" y="34.3232" width="622.113" height="906.333" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur"/>
                                </filter>
                                <linearGradient id="paint0_linear" x1="-473.268" y1="672.595" x2="-245.189" y2="1002.39" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#3E97EF"/>
                                <stop offset="0.538272" stop-color="#F3385A"/>
                                <stop offset="1" stop-color="#45187A"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear" x1="-455.609" y1="711.758" x2="-186.683" y2="1009.18" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#3E97EF"/>
                                <stop offset="0.538272" stop-color="#F3385A"/>
                                <stop offset="1" stop-color="#45187A"/>
                                </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div class="svg-line-right">
                            <svg class="preview-line-r" width="936" height="1103" viewBox="0 0 936 1103" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path class="preview-line-rp" d="M1008.74 788.935C1006.68 790.442 1004.62 791.949 1002.56 793.456C734.703 983.146 372.493 812.785 363.946 494.642L354.894 159.863" stroke="url(#paint0_linear_slr)" stroke-width="24" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                <g filter="url(#filter0_fslr)">
                                <path class="preview-line-rp" d="M1051.41 735.902C1049.56 737.663 1047.71 739.425 1045.87 741.186C804.872 964.015 423.626 842.071 373.888 527.724L321.492 196.947" stroke="url(#paint1_linear_slr)" stroke-opacity="0.3" stroke-width="24" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                <filter id="filter0_fslr" x="285.49" y="160.945" width="801.919" height="729.989" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                                <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur"/>
                                </filter>
                                <linearGradient id="paint0_linear_slr" x1="581.221" y1="106.955" x2="207.804" y2="253.045" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#3E97EF"/>
                                <stop offset="0.538272" stop-color="#F3385A"/>
                                <stop offset="1" stop-color="#45187A"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_slr" x1="539.045" y1="115.131" x2="187.731" y2="308.419" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#3E97EF"/>
                                <stop offset="0.538272" stop-color="#F3385A"/>
                                <stop offset="1" stop-color="#45187A"/>
                                </linearGradient>
                                </defs>
                            </svg>
                        </div>

                        <div class="screen-image">
                            <img class="block-image" src={refImage01} srcSet={`${refImage01}, ${refImage01} 1x, ${refImage01_2x} 2x`}  alt="Transparex Reference 01" />
                        </div>
                        <div class="screen-image">
                            <img class="block-image" src={refImage02} srcSet={`${refImage02}, ${refImage02} 1x, ${refImage02_2x} 2x`}  alt="Transparex Reference 02" />
                        </div>
                        <div class="screen-image">
                            <img class="block-image" src={refImage03} srcSet={`${refImage03}, ${refImage03} 1x, ${refImage03_2x} 2x`}  alt="Transparex Reference 03" />
                        </div>
                        <div class="screen-image">
                            <img class="block-image" src={refImage04} srcSet={`${refImage04}, ${refImage04} 1x, ${refImage04_2x} 2x`}  alt="Transparex Reference 04" />
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
