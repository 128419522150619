import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
//import _ from 'lodash-es'

import marketing01 from '../../images/services/online-marketing/marketing-01.png'
import marketing02 from '../../images/services/online-marketing/marketing-02.png'

import marketing01retina from '../../images/services/online-marketing/marketing-01@2x.png'
import marketing02retina from '../../images/services/online-marketing/marketing-02@2x.png'


export default class ContentMarketing extends Component {
    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    <div className="block">
                        <div className="block__text">
                            <div className="text-toptitle">UI/UX</div>
                            <h2>Digitálna propagácia</h2>
                            <p>Tvoríme kampane na sociálnych sieťach, Google či Youtube. Získavame zákazníkov so skutočným záujmom o vaše produkty a služby.</p>
                        </div>
                        <div className="block__image">
                            <img src={marketing01} srcSet={`${marketing01}, ${marketing01} 1x, ${marketing01retina} 2x`} alt="Digitálna propagácia" />
                        </div>
                    </div>
                    <div className="block">
                        <div className="block__image">
                            <img src={marketing02}  srcSet={`${marketing02}, ${marketing02} 1x, ${marketing02retina} 2x`} alt="Prototypy" />
                        </div>
                        <div className="block__text">
                            <div className="text-toptitle">s možnosťou administrácie.</div>
                            <h2>Content marketing</h2>
                            <p>Preberáme kontrolu nad vašimi sociálnymi sieťami. Tvoríme kreatívny grafický, textový a video obsah. Príspevky pretavíme do plodných kampaní.</p>
                        </div>
                    </div>
                </div> 
            </section>
        )
    }
}
