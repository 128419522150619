import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'
//import Parallax from 'react-rellax'

import megaphone from '../../images/references/giganet/megaphone.svg'
import circles from '../../images/references/giganet/circles.svg'
import wireframe from '../../images/references/giganet/wireframe.svg'

import coverImage from '../../images/references/giganet/cover-image.png'
import coverImage2x from '../../images/references/giganet/cover-image@2x.png'

import site01 from '../../images/references/giganet/site-01.png'
import site01_2x from '../../images/references/giganet/site-01@2x.png'

import site02 from '../../images/references/giganet/site-02.png'
import site02_2x from '../../images/references/giganet/site-02@2x.png'

import graphics00 from '../../images/references/giganet/screen-00.png'
import graphics00_2x from '../../images/references/giganet/screen-00@2x.png'

import graphics01 from '../../images/references/giganet/screen-01.png'
import graphics01_2x from '../../images/references/giganet/screen-01@2x.png'

import graphics02 from '../../images/references/giganet/screen-02.png'
import graphics02_2x from '../../images/references/giganet/screen-02@2x.png'

import cardgroup01_01 from '../../images/references/giganet/reference-01.png'
import cardgroup01_01_2x from '../../images/references/giganet/reference-01@2x.png'

import cardgroup02_01 from '../../images/references/giganet/reference-02.png'
import cardgroup02_01_2x from '../../images/references/giganet/reference-02@2x.png'

export default class ContentGiganet extends Component {

    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $(".back-link").css("opacity", "1");
        }, 1500);

    }

    blockIntro = () => {
        _.delay(function() {
            $(".block--intro").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(0).css("opacity", "1").css("transform", "none");
        }, 400);
        
        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(1).css("opacity", "1").css("transform", "none");
        }, 750);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(2).css("opacity", "1").css("transform", "none");
        }, 950);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(3).css("opacity", "1").css("transform", "none");
        }, 1100);
    }

    blockCoverImage = () => {
        $(".block--cover-image").find('.overlayer').css("width", "0");
    }

    blockScreen = () => {
        $(".block--screens").find(".block-image").css("opacity", "1").css("transform", "none");
    }

    blockColumns = () => {

        let columnItems = $(".block--columns").find(".services__item");

        _.delay(function() {
            $.each(columnItems, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);

    }

    blockPack = () => {
        _.delay(function() {
            $(".block--image-pack").find(".overlayer").css("width", "0");
        }, 1000);
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    
                    <div class="block--intro">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockIntro}
                            bottomOffset="20%"
                        />
                        <h2>Full servisové služby pre internetového providera</h2>
                        <p>GIGAnet je lokálny poskytovateľ internetu a televízie na Hornej Orave. My sme sa postarali o uvedenie úplne novej značky na trh. Vytvorili sme celý brand a  online marketingovú stratégiu, ktorú sme následne implementovali do praxe.</p>
                        <p>Okrem online marketingu sme sa postarali o web, tlačové materiály, ale napríklad aj polepy všetkých áut. </p>
                        <p>Tento klient u nás má full-servisové služby. Riešime mu naozaj všetko, čo sa týka marketingu a PR, grafiky a webu.</p>
                    </div>

                    <div class="block--boxes">
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={circles} alt="BRANDING" />
                                <h3>BRANDING</h3>
                            </div>
                            <p>Mali sme dôležitú úlohu - vysvetliť ľuďom na Orave, že meníme značku (predtým OravaSK) na GIGAnet. Prišli sme teda s návrhom celej značky - od odvodeného loga, cez farebnú škálu, fonty a spoločné grafické prvky.</p><br />
                            <p>Dnes už je GIGAnet stabilná značka.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={circles} alt="WEB" />
                                <h3>WEB</h3>
                            </div>
                            <p>Pre GIGAnet sme pripravili úplne nový web s dizajnom na mieru.</p><br />
                            <p>Spravili sme analýzu kľúčových slov, navrhli štruktúru a nakreslili dizajn. Postarali sme sa aj o úplne nové texty na web. </p><br />
                            <p>Následne sme web naprogramovali a implementovali na CMS Wordpress. Na webe sme tiež naprogramovali interaktívny objednávací formulár na mieru.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={megaphone} alt="ONLINE MARKETING" />
                                <h3>ONLINE MARKETING</h3>
                            </div>
                            <p>Pre GIGAnet sme navrhli komplexnú marketingovú kampaň v online priestore. Už niekoľko rokov sa staráme o sociálne siete Facebook a Instagram. Spravujeme PPC kampane na sociálnych sieťach aj v Google.</p><br />
                            <p>Pripravili sme niekoľko väčších časovo obmedzených kampaní.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={wireframe} alt="TLAČOVÉ MATERIÁLY, POLEPY" />
                                <h3>TLAČOVÉ MATERIÁLY, POLEPY</h3>
                            </div>
                            <p>Poskytovateľ internetu a TV potrebuje mať k dispozícií dostatok materiálov v tlačenej forme.</p><br />
                            <p>Pripravili sme letáky, vizitky, brožúry či zakladače. Okrem toho sme nadizajnovali polepy okien na mieru celej novej predajni a tiež všetkých áut. Všetko tak, aby to ladilo.</p>
                        </div>
                    </div>

                    <div className="link-row link-row--topNegative">
                        <a href="https://www.giganet.sk/" target="blank">navštíviť WWW.giganet.SK  <i className="arrow-next"></i></a>
                    </div>
                </div> 

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    <img src={coverImage}  srcSet={`${coverImage}, ${coverImage} 1x, ${coverImage2x} 2x`}  alt="Giganet" />
                    <div class="overlayer"></div>
                </div>

                
                <div className="block--columns block--columns-site">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockColumns}
                            topOffset="0%"
                        />
                    <div className="container-block">
                        <div className="services">
                            <div className="services__item columns">
                                <div className="services__image column is-half">
                                    <img src={site01} srcSet={`${site01}, ${site01} 1x, ${site01_2x} 2x`} alt="Giganet" />
                                </div>
                                <div className="services__image column is-half">
                                    <img src={site02} srcSet={`${site02}, ${site02} 1x, ${site02_2x} 2x`} alt="Giganet" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="block--columns">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockColumns}
                            bottomOffset="10%"
                        />
                    <div className="container-block">
                        <div className="services">
                            <div className="services__item columns">
                                <div className="services__image column is-half">
                                    <img src={cardgroup02_01} srcSet={`${cardgroup02_01}, ${cardgroup02_01} 1x, ${cardgroup02_01_2x} 2x`} alt="Giganet" />
                                </div>
                                <div className="services__image column is-half">
                                    <img src={cardgroup01_01} srcSet={`${cardgroup01_01}, ${cardgroup01_01} 1x, ${cardgroup01_01_2x} 2x`} alt="Giganet" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="image-pack">
                    <div class="container-block"> 
                        <div class="block--image-pack">
                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPack}
                                bottomOffset="30%"
                            />
                            <img class="block-image" src={graphics00} srcSet={`${graphics00}, ${graphics00} 1x, ${graphics00_2x} 2x`}  alt="Giganet" />
                            <div class="overlayer"></div>
                        </div>
                    </div>
                    
                    <div class="container-block"> 
                        <div class="block--image-pack">
                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPack}
                                bottomOffset="10%"
                            />
                            <img class="block-image" src={graphics01} srcSet={`${graphics01}, ${graphics01} 1x, ${graphics01_2x} 2x`}  alt="Giganet" />
                            <div class="overlayer"></div>
                        </div>
                    </div>

                    <div class="container-block"> 
                        <div class="block--image-pack">
                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPack}
                                bottomOffset="10%"
                            />
                            <img class="block-image" src={graphics02} srcSet={`${graphics02}, ${graphics02} 1x, ${graphics02_2x} 2x`}  alt="Giganet" />
                            <div class="overlayer"></div>
                        </div>
                    </div> 
                </div>

            </section>
        )
    }
}
