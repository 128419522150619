import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'

import eye from '../../images/references/exsotrans/eye.svg'
import wireframe from '../../images/references/exsotrans/wireframe.svg'

import coverImage from '../../images/references/exsotrans/cover.png'
import coverImage2x from '../../images/references/exsotrans/cover@2x.png'

import refImage01 from '../../images/references/exsotrans/screen01.png'
import refImage01_2x from '../../images/references/exsotrans/screen01@2x.png'

import refImage02 from '../../images/references/exsotrans/screen02.png'
import refImage02_2x from '../../images/references/exsotrans/screen02@2x.png'

import refImage03 from '../../images/references/exsotrans/screen03.png'
import refImage03_2x from '../../images/references/exsotrans/screen03@2x.png'

import refImage04 from '../../images/references/exsotrans/screen04.png'
import refImage04_2x from '../../images/references/exsotrans/screen04@2x.png'

import section01 from '../../images/references/exsotrans/services.png'
import section02 from '../../images/references/exsotrans/building.png'

import exsotransVideo from '../../images/references/exsotrans/exsotrans-video.mp4'

export default class ContentExsotrans extends Component {

    componentDidMount() {
        document.querySelector("#video").play();
    }
    
    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $(".back-link").css("opacity", "1");
        }, 1500);
    }

    blockIntro = () => {
        _.delay(function() {
            $(".block--intro").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(0).css("opacity", "1").css("transform", "none");
        }, 400);
        
        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(1).css("opacity", "1").css("transform", "none");
        }, 750);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(2).css("opacity", "1").css("transform", "none");
        }, 950);
    }

    blockCoverImage = () => {
        $(".block--cover-image").find('.overlayer').css("width", "0");
    }

    blockScreen = () => {
        $(".block--screens").find(".block-image").css("opacity", "1").css("transform", "none");
    }

    blockPreviews = () => {

        _.delay(function() {

            let screens = $(".block--previews").find(".block-image");

            $.each(screens, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);
    }

    blockColumns = () => {

        let columnItems = $(".block--columns").find(".services__item");

        _.delay(function() {
            $.each(columnItems, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);

    }

    blockImages = () => {

        _.delay(function() {
            $(".block-images").find(".overlayer").css("width", "0");
        }, 200);

        _.delay(function() {
            $(".block-images").find("img").css("opacity", "1").css("transform", "none");
        }, 1000);
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    
                    <div class="block--intro">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockIntro}
                            bottomOffset="20%"
                        />
                        <h2>Animovaný web s dizajnom<br /> a ilustráciami na mieru</h2>
                        <p>Špedičné spoločnosti v tom majú jasno - chcú mať originálne webové stránky. Podobne, ako pri našom klientovi ALDONA, nás oslovila nemecká špedičná spoločnosť EXSOTRANS, ktorej sme vytvorili krásny a originálny web s dizajnom a ilustráciami na mieru. </p>
                    </div>

                    <div class="block--boxes">
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={eye} alt="UI UX dizajn" />
                                <h3>DIZAJN A ILUSTRÁCIE</h3>
                            </div>
                            <p>Na základe zadania sme pripravili originálny webový dizajn na mieru spoločnosti.</p>
                            <p>Ako môžete vidieť nižšie na dizajnoch nižšie, na webe sa nachádzajú animované ilustrácie, ktoré boli digitálne nakreslené na mieru.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={wireframe} alt="Wireframy" />
                                <h3>PROGRAMOVANIE A CMS</h3>
                            </div>
                            <p>Webovú stránku sme zastrešovali komplet na mieru a mali sme v rukách aj implementáciu dizajnu. Znamená to, že sme web naprogramovali a nasadili na CMS Wordpress pre správu obsahu.</p>
                            <p>Web sme naprogramovali tak, aby bol rýchly, optimalizovaný na všetky zariadenia a využili sme aj mikro animácie.</p>
                        </div>
                    </div>

                    <div className="link-row link-row--topNegative">
                        <a href="https://www.exsotrans.de" target="blank">navštíviť WWW.exsotrans.de <i className="arrow-next"></i></a>
                    </div>
                </div> 

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                        
                        <img src={coverImage}  srcSet={`${coverImage}, ${coverImage} 1x, ${coverImage2x} 2x`}  alt="exsotrans" />
                        <div class="overlayer"></div> 

                    {/* <video autoPlay loop muted id="video">
                        <source src={exsotransVideo} type="video/mp4"></source>
                    </video> */}
                </div>

                <div className="block--columns">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockColumns}
                            bottomOffset="10%"
                        />
                        <div className="container-block">
                            <div className="services">
                                <div className="services__item columns">
                                    <div className="services__image column is-half">
                                        <img src={section01} alt="exsotrans" />
                                    </div> 
                                    <div className="services__image column is-half">
                                        <img src={section02} alt="exsotrans" />
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>

                <div class="block--cover-video">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                        
                        {/* <img src={coverImage}  srcSet={`${coverImage}, ${coverImage} 1x, ${coverImage2x} 2x`}  alt="medirex" />
                        <div class="overlayer"></div>  */}

                    <video autoPlay loop muted playsinline id="video">
                        <source src={exsotransVideo} type="video/mp4"></source>
                    </video>
                </div>

                <div class="block--previews">
                    <div class="container-block"> 
                        <div class="previews-screens">

                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPreviews}
                            />
                            <div class="columns">
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage01} srcSet={`${refImage01}, ${refImage01} 1x, ${refImage01_2x} 2x`}  alt="exsotrans" />
                                </div>
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage02} srcSet={`${refImage02}, ${refImage02} 1x, ${refImage02_2x} 2x`}  alt="exsotrans" />
                                    <img class="block-image" src={refImage03} srcSet={`${refImage03}, ${refImage03} 1x, ${refImage03_2x} 2x`}  alt="exsotrans" />
                                    <img class="block-image" src={refImage04} srcSet={`${refImage04}, ${refImage04} 1x, ${refImage04_2x} 2x`}  alt="exsotrans" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
