import React from 'react'
import { Helmet } from "react-helmet";

// glboal components
import Footer from '../../components/home/Footer'

// hero specifiy components 
import HeroItems from '../../components/reference/HeroItems'
import ReferenceMnam from '../../components/reference/ContentMnam'
import Header from '../../components/reference/Header'
import Contact from '../../components/reference/Contact'
import Portfolio from '../../components/home/Portfolio'

import FreeConsultation from '../../components/home/FreeConsultation'

// import Notifications, { notify } from 'react-notify-toast';

class Mnam extends  React.Component {
  constructor() {
    super();

    this.state = {
        hasPortfolio: true, // portfolio on/off
        subtitle: "Mńam delivery",
        title: "Ochutnajte slinotvorne",
        hashTags: [
            "#2021",
            "#UI/UX dizajn"
        ],   
     } 
  }

  render() {
      return (
          <div className="referenceView mnam">
              <Helmet>
                <title>Mńam delivery | Referencie | MATATE</title>
                <meta name="description" content="Mnam delivery referencia matate" />
              </Helmet>
              <FreeConsultation />
              <Header />
              <HeroItems subtitle={this.state.subtitle} title={this.state.title} hashTags={this.state.hashTags} />
              <ReferenceMnam />
              <Portfolio status={this.state.hasPortfolio} />
              <Contact />
              <Footer />
          </div>
        )
    }
}

export default Mnam;
