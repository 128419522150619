import React, { useState, useRef, useEffect } from "react";
import { Waypoint } from "react-waypoint";
import $ from "jquery";
import _ from "lodash-es";
import { gsap, Power3 } from "gsap";
import { useTranslation } from "react-i18next";

import ModalConsultation from "../home/ModalConsultation";

import mnamImage01 from "../../images/services/web/ref-mnam/screen.png";
import mnamImage02 from "../../images/services/web/ref-mnam/pattern.png";
import mnamImage03 from "../../images/services/web/ref-mnam/stick.png";

import lecoqImage01 from "../../images/services/web/ref-lecoq/screen.png";
import lecoqImage02 from "../../images/services/web/ref-lecoq/watermelon-01.png";
import lecoqImage03 from "../../images/services/web/ref-lecoq/watermelon-02.png";
import lecoqImage04 from "../../images/services/web/ref-lecoq/watermelon-03.png";
import lecoqImage05 from "../../images/services/web/ref-lecoq/watermelon-04.png";
import lecoqImage06 from "../../images/services/web/ref-lecoq/lemon-01.png";
import lecoqImage07 from "../../images/services/web/ref-lecoq/lemon-02.png";
import lecoqImage08 from "../../images/services/web/ref-lecoq/lemon-03.png";
import lecoqImage09 from "../../images/services/web/ref-lecoq/pineapple-01.png";

import dofferoImage01 from "../../images/services/web/ref-doffero/screen.png";

import ehmImage01 from "../../images/services/web/ref-ehm/screen.png";
import ehmImage02 from "../../images/services/web/ref-ehm/check-graphics.png";

import bonitaxImage01 from "../../images/services/web/ref-bonitax/bonitax-screen.png";
import bonitaxImage02 from "../../images/services/web/ref-bonitax/line-chart.png";

/* function based components no need 'this.' */
const Hero = (props) => {
  const serviceWebHero = useRef();

  const [state, setState] = useState({
    isActive1: true,
    isActive2: false,
    isActive3: false,
    isActive4: false,
    isActive5: false,
    itWasRun: false
  });

  const [modal, setModal] = useState(false);

  // timeline for animations
  const t1 = useRef();
  const t2 = useRef();
  const t3 = useRef();
  const t4 = useRef();
  const t5 = useRef();

  const { t } = useTranslation();

  /* stepping items */
  const slideBy = (index) => {
    let prevIndex = $(".carousel-service.active").data("index");
    setState({
      isActive1: false,
      isActive2: false,
      isActive3: false,
      isActive4: false,
      isActive5: false
    });

    if (index == 0) {
      setState({ isActive1: true });
    } else if (index == 1) {
      setState({ isActive2: true });
    } else if (index == 2) {
      setState({ isActive3: true });
    } else if (index == 3) {
      setState({ isActive4: true });
    } else if (index == 4) {
      setState({ isActive5: true });
    }

    // animated item by index
    fadeOut(prevIndex - 1, 1);
    slideNext(index, 1);

    if (index == 0) {
      if (!t1.current) {
        t1.current = gsap
          .timeline()
          .to($(".le-coq .screen"), 0.5, {
            alpha: 1,
            y: 0,
            delay: 0.5
          })
          .to($(".active .watermelon-01"), 0.2, {
            scale: 1,
          }, "-=0.15")
          .to($(".active .watermelon-02"), 0.2, {
            scale: 1,
          }, "-=0.15")
          .to($(".active .watermelon-03"), 0.2, {
            scale: 1,
          }, "-=0.15")
          .to($(".active .watermelon-04"),0.2, {
            scale: 1,
          }, "-=0.15")
          .to($(".active .lemon-01"),0.2, {
            scale: 1,
          }, "-=0.15")
          .to($(".active .lemon-02"),0.2, {
            scale: 1,
          }, "-=0.05")
          .to($(".active .lemon-03"),0.2, {
            scale: 1,
          }, "-=0.05")
          .to($(".active .pineapple-01"),0.2, {
            scale: 1,
          }, "-=0.05");
      } else {
        t1.current.restart();
      }
    } else if (index == 1) {
      if (!t2.current) {
        t2.current = gsap.timeline()
          .to($(".mnam .screen"), 0.5, {
            alpha: 1,
            y: 0,
            delay: 0.5
          })
          .to($(".pattern"), 0.5, {
            alpha: 1,
            scale: 1.5,
   
          })
          .to($(".stick"), 0.5, {
            rotate: 0
          }, "-=0.5");
      } else {
        t2.current.restart();
      }
    } else if (index == 2) {
      if (!t3.current) {
        t3.current = gsap.timeline().to($(".doffero .screen"), 0.5, {
            y: 0,
          opacity: 1,
          delay: 0.5
        })
        .to($(".line-01"), 0.3, {
            x: 0,
            alpha:1
          }, '-=0.1')
          .to($(".line-02"), 0.3, {
            x: 0,
            alpha:1
          }, '-=0.1')
          .to($(".line-03"), 0.3, {
            x: 0,
            alpha:1
          }, '-=0.1')
          .to($(".line-04"), 0.3, {
            x: 0,
            alpha:1
          }, '-=0.1');
      } else {
        t3.current.restart();
      }
    } else if (index == 3) {
      if (!t4.current) {
        t4.current = gsap
          .timeline()
          .to($(".ehm .screen"), 0.5, {
            y: 0,
            alpha: 1,
            delay: 0.5
          })
          .to(".check-graphics", {
            x: 0,
            opacity: 1,
          });
      } else {
        t4.current.restart();
      }
    } else if (index == 4) {
      if (!t5.current) {
        t5.current = gsap.timeline().to($(".bonitax .screen"), 0.5, {
          y: 0,
          opacity: 1,
          delay: 0.5
        }) 
        .to(".line-chart", {
            y: 0,
            scale: 1.1,
            opacity: 1,
          });
      } else {
        t5.current.restart();
      }
    };
  }

  useEffect(() => {
    let ctx = gsap.context(() => {
    }, serviceWebHero);

    // runnin slides
    let calledIndex = 1;
    let carouselInterval = setInterval(function() {

        slideBy(calledIndex);
        calledIndex += 1;

        if (calledIndex > 4) {
            calledIndex = 0;
        } 
    }, 5000);

    $(".carousel-pagination").on('click', 'li', function() {
        clearInterval(carouselInterval);
    });

    return () => ctx.revert();
  }, []);

  const slideNext = (index, duration) => {
    let $item = $(".carousel-service").eq(index).find(".carousel-service__content");
    gsap.to($item, 0.5, { opacity: 1, ease: Power3.easeOut, delay: 0.2 });
  };

  const slidePrev = (index, duration) => {
    let $item = $(".carousel-service").eq(index).find(".carousel-service__content");
    gsap.to($item, 1, { opacity: 1, ease: Power3.easeOut, delay: 0.2 });
  };

  const fadeIn = (index, duration) => {
    let $item = $(".carousel-service").eq(index).find(".carousel-service__content");
    gsap.to($item, 1, { opacity: 1 });
  };

  const fadeOut = (index, duration) => {
    let $item = $(".carousel-service").eq(index).find(".carousel-service__content");
    gsap.to($item, 0.5, { opacity: 0 });
  };

  /* loading start animations ???? run first animation */
  function loadingAnim() {
    // first load of the portfolio carousel
    gsap.to(".carousel--services", 1, {
      y: 0,
      opacity: 1,
      delay: 0.8,
    });

    t1.current = gsap
      .timeline()
      .to($(".carousel--services .portfolio__right .kastiel-graphics"), {
        scale: 1,
        opacity: 1,
        duration: 0.5,
      })
      .to($(".active .portfolio__right .kastiel-chandelier"), {
        y: 0,
        delay: 0.2,
        duration: 1.5,
      })
      .to($(".active .portfolio__right .kastiel-chandelier"), {
        rotation: -5,
        ease: "sine.inOut",
        duration: 2,
        repeat: -1,
        yoyo: true,
      });
  }

  function hero_load() {
    _.delay(function () {
        if(state.itWasRun == false) {
            slideBy(0);
           // setState({ itWasRun: true });
        }
      $(".hero__left").css("transform", "none").css("opacity", "1");
    }, 100);
  }

  function modal_show() {
    setModal( true );
    _.delay(function () {
      $("#myModal .modal_content").css("transform", "scale(1)");
    }, 500);
  }

  function modal_close() {
    setModal( false );
  }

  return (
    <div className="hero--sub" ref={serviceWebHero}>
      <Waypoint scrollableAncestor={window} onEnter={hero_load} />
      <ModalConsultation close={modal_close} show={modal} />

      {props.type == "servicesWeb" ? (
        <div class="hero--services">
          <div class="container">
            <div class="row">
              <div class="hero__left">
                <h1>{props.title}</h1>
                <p>
                  Tvoríme web stránky na mieru, ktoré posunú váš
                  biznis na vyšší level. Prehľadné a pútavé. Tak, aby sa
                  zákazníkom páčili a radi vás kontaktovali alebo spravili
                  objednávku.
                </p>
                <div class="service-status service-status--available">
                  Prijímame nové projekty
                </div>
                <div>
                  <a onClick={modal_show} className="btn--cta">
                    Chcem konzultáciu zadarmo <span>👋</span>
                  </a>
                </div>
              </div>
              <div class="hero__right">
                <div class="carousel--services">
                  <div class={state.isActive1 ? "carousel-service active le-coq" : "carousel-service le-coq"} data-index="0">
                    <div class="carousel-service__content">
                        <img class="screen" src={lecoqImage01} />
                        <img class="graphics watermelon-01" src={lecoqImage02} />
                        <img class="graphics watermelon-02" src={lecoqImage03} />
                        <img class="graphics watermelon-03" src={lecoqImage04} />
                        <img class="graphics watermelon-04" src={lecoqImage05} />
                        <img class="graphics lemon-01" src={lecoqImage06} />
                        <img class="graphics lemon-02" src={lecoqImage07} />
                        <img class="graphics lemon-03" src={lecoqImage08} />
                        <img class="graphics pineapple-01" src={lecoqImage09} />
                    </div>
                  </div>
                  <div class={state.isActive2 ? "carousel-service active mnam" : "carousel-service mnam"} data-index="1">
                    <div class="carousel-service__content">
                        <img class="screen" src={mnamImage01} />
                        <img class="graphics pattern" src={mnamImage02} />
                        <img class="graphics stick" src={mnamImage03} />
                    </div>
                  </div>
                  <div class={state.isActive3 ? "carousel-service active doffero" : "carousel-service doffero"} data-index="2">
                    <div class="carousel-service__content">
                        <img className="screen" src={dofferoImage01} />
                        <div class="graphics line-01"></div>
                        <div class="graphics line-02"></div>
                        <div class="graphics line-03"></div>
                        <div class="graphics line-04"></div>
                    </div>
                  </div>
                  <div class={state.isActive4 ? "carousel-service active ehm" : "carousel-service ehm"} data-index="3">
                    <div class="carousel-service__content">
                        <img class="screen" src={ehmImage01} />
                        <img class="graphics check-graphics" src={ehmImage02} />
                    </div>
                  </div>
                  <div class={state.isActive5 ? "carousel-service active bonitax" : "carousel-service bonitax"} data-index="4">
                    <div class="carousel-service__content">
                        <img class="screen" src={bonitaxImage01} />
                        <img class="graphics line-chart" src={bonitaxImage02} />
                    </div>
                  </div>
                </div>

                <div className="carousel-pagination">
                  <ul>
                    <li className={state.isActive1 ? "active" : ""}>
                      <button onClick={() => slideBy(0)}></button>
                    </li>
                    <li className={state.isActive2 ? "active" : ""}>
                      <button onClick={() => slideBy(1)}></button>
                    </li>
                    <li className={state.isActive3 ? "active" : ""}>
                      <button onClick={() => slideBy(2)}></button>
                    </li>
                    <li className={state.isActive4 ? "active" : ""}>
                      <button onClick={() => slideBy(3)}></button>
                    </li>
                    <li className={state.isActive5 ? "active" : ""}>
                      <button onClick={() => slideBy(4)}></button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div class="reveal_wrap">
          <div class="hero--sub__text">
            <h1>{props.title}</h1>
            <p>{props.text}</p>
            <a className="btn--primary" onClick={modal_show}>
              {props.type == "marketing" ? (
                <span>Mám záujem o marketing</span>
              ) : (
                <span>Mám záujem o web</span>
              )}
            </a>
          </div>
        </div>
      )}

      {/* <a className="Mybtn Mybtn-6">
          <svg width="226" height="65" viewBox="0 0 226 65" fill="none">
            <rect
              x="0.5"
              y="0.5"
              width="225"
              height="64"
              rx="35"
              stroke="white"
            />
          </svg>
          <span className="hover"></span>
          <div className="text">
            Mám záujem o web
          </div>
        </a> */}
    </div>
  );
};

export default Hero;
