import React from 'react'
import { Helmet } from "react-helmet";

import FreeConsultation from '../components/home/FreeConsultation'
import Header from '../components/reference/Header'
import ReferenceList from '../components/reference/ReferenceList'
import Contact from '../components/reference/Contact'
import Footer from '../components/home/Footer'

//import Notifications, { notify } from 'react-notify-toast';
import { withTranslation } from "react-i18next"; // Import withTranslation

class Reference extends React.Component {

  render() {
      return (
          <div className="reference">
              <Helmet>
                <title>{this.props.t('pages.reference.pageTitle')} | MATATE</title>
                <meta name="description" content={this.props.t('pages.reference.metaText')} />
              </Helmet>
              <FreeConsultation />
              <Header />
              <ReferenceList />
              <Contact />
              <Footer />
          </div>
        )
  }
}

export default withTranslation()(Reference);

