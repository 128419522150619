import React, { Component } from "react";
import { Waypoint } from "react-waypoint";
import $ from "jquery";
import _ from "lodash-es";

import ModalConsultation from "../home/ModalConsultation";
import { Button } from '../../components/home/Button';   /* ???? */

import bgImage from '../../images/transparex/transparex-bg.png';

export default class Brands extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Modal: false,
      hashTags: props.hashTags
    };
  }

  /* ???? */
  componentDidMount() {
    new Button("Mybtn-6");
  }

  hero_load = () => {
    _.delay(function() {
        $('.hero--sub__text').css('transform','none').css('opacity', '1');
    }, 100);
  };

  modal_show = () => {
    this.setState({ Modal: true });
    _.delay(function () {
      $("#myModal .modal_content").css("transform", "scale(1)");
    }, 500);
  };

  modal_close = () => {
    this.setState({ Modal: false });
  };

  render() {
    return (
      <div className="hero--sub" style={{backgroundImage: "url("+bgImage+")"}}>

        <Waypoint scrollableAncestor={window} onEnter={this.hero_load} />
        <ModalConsultation close={this.modal_close} show={this.state.Modal} />

        <div className="container">
        
            <div className="reveal_wrap">

                <a className="back-link" href="/referencie">
                    <svg width="13" height="10" viewBox="0 0 13 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.625 5H11.375M5.41667 1.20833L1.625 5L5.41667 1.20833ZM1.625 5L5.41667 8.79166L1.625 5Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    Späť na referencie
                </a>
            
                <div className="hero--sub__text">
                    <div className="subtitle">{this.props.subtitle}</div>
                    <h1>{this.props.title}</h1>
                    <ul class="hashtags">
                        {
                            this.state.hashTags.map((obj) => ( 
                                <li>  
                                    {obj}
                                </li>  
                            )) 
                        }
                    </ul>
                </div>

            </div>

        </div>
      </div>
    );
  }
}
