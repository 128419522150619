import React from 'react'
import { Helmet } from "react-helmet";

import FreeConsultation from './components/home/FreeConsultation'
import Header from './components/aboutus/Header'
import Brands from './components/aboutus/Brands'
import Aboutus from './components/aboutus/Aboutus'
import Team from './components/aboutus/Team'
import WhyWe from './components/aboutus/WhyWe'
import Contact from './components/aboutus/Contact'
import Footer from './components/home/Footer'

//import Notifications, { notify } from 'react-notify-toast';

import { withTranslation } from "react-i18next"; // Import withTranslation

class AboutUs extends React.Component {
  render() {
      return (
          <div className="AboutUs">
              <Helmet>
                <title>{this.props.t('pages.aboutUs.pageTitle')} | MATATE</title>
                <meta name="description" content={this.props.t('pages.aboutUs.metaText')} />
              </Helmet>
              <FreeConsultation />
              <Header />
              <Brands />
              <Aboutus />
              <Team />
              <WhyWe />
              <Contact />
              <Footer />
          </div>
        )
  }
}

export default withTranslation()(AboutUs);