import React from 'react'
import { Helmet } from "react-helmet";

// glboal components
import Footer from '../../components/home/Footer'

// hero specifiy components 
import HeroItems from '../../components/reference/HeroItems'
import ReferenceTransParex from '../../components/reference/ContentTransparex'
import Header from '../../components/reference/Header'
import Contact from '../../components/reference/Contact'
import Portfolio from '../../components/home/Portfolio'

import FreeConsultation from '../../components/home/FreeConsultation'
 
// import Notifications, { notify } from 'react-notify-toast';

class Transparex extends  React.Component {
  constructor(){
    super();

    this.state = {
        hasPortfolio: true, // portfolio on/off
        subtitle: "Transparex",
        title: "Najväčšia databáza firem na Slovensku",
        hashTags: [
            "#2020 - 2021",
            "#UI/UX dizajn",
            "#logo",
            "#dizajn manuál",
            "#Konzultácie"
        ]
    }
  }

  render() {
      return (
          <div className="referenceView transparex">
              <Helmet>
                <title>Transparex | Referencie | MATATE</title>
                <meta name="description" content="Transparex referencia matate" />
              </Helmet>
              <FreeConsultation />
              <Header />
              <HeroItems subtitle={this.state.subtitle} title={this.state.title} hashTags={this.state.hashTags} />
              <ReferenceTransParex />
              <Portfolio status={this.state.hasPortfolio} />
              <Contact />
              <Footer />
          </div>
        )
    }
}

export default Transparex;
