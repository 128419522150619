import React from 'react'
import { Helmet } from "react-helmet";

import Header from './components/services/Header'
import Contact from './components/services/Contact'
import Footer from './components/home/Footer'
import Portfolio from './components/home/Portfolio'
import Hero from './components/services/Hero'
import List from './components/services/List'

import FreeConsultation from './components/home/FreeConsultation'
//import Notifications, { notify } from 'react-notify-toast';

import { withTranslation } from "react-i18next"; // Import withTranslation

class Services extends React.Component {
  constructor(){
    super();

    this.state = {
        hasPortfolio: true // portfolio on/off
    }
  }

  render() {
      return (
          <div className="Services">
              <Helmet>
                <title>{this.props.t('pages.services.pageTitle')} | MATATE</title>
                <meta name="description" content={this.props.t('pages.services.metaText')} />
              </Helmet>
              <FreeConsultation />
              <Header />
              <Hero />
              <List />
              <Portfolio status={this.state.hasPortfolio} />
              <Contact />
              <Footer />
          </div>
        )
   }
}

export default withTranslation()(Services);
