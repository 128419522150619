import _ from 'lodash-es'
import $ from "jquery"
import { Waypoint } from 'react-waypoint';
import React, { Component } from "react";

import logo from '../../images/matate-footer-logo.svg'
//import fb from '../../images/fb.svg'
//import yt from '../../images/yt.svg'
import dr from '../../images/dr.svg'
import ig from '../../images/ig.svg'
import { withTranslation } from "react-i18next"; // Import withTranslation

//import i18n from 'i18next';

const Footer_LoadPage = () =>{
    _.delay(function() {
        $('#footer .black_wall').css('transform', 'translate(180%)');
      }, 100);
    _.delay(function() {
        $('.animation_13').css('transform', 'matrix(1, 0, 0, 1, 0, 0)');
    }, 400);
    _.delay(function() {
        $('#footer .end').css('opacity', '1');
    }, 800);
}

class Footer extends Component {

    render() {
        return (
            <footer id="footer" className="">
                <Waypoint
                    scrollableAncestor={window}
                    onEnter={function(props) {
                        Footer_LoadPage()
                    }}
                />
                {/* <div className="info">
                    <div className="columns is-desktop">
                        <div className="column is-2"></div>
                        <div className="column is-8">
                                <div className="all animation_13"  >
                                    <div className="reveal_wrap ">
                                        <div className="animation_13" style={{transform: "matrix(1, 0, 0, 1, 0, 180)"}}>
                                            <a href="mailto: info@matate.sk" className="email">
                                                info@matate.sk
                                            </a>
                                            <a href="tel:+421 902 359 330" className="tel">
                                                +421 902 359 330
                                            </a>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="column"></div>
                    </div>
                    <div className="black_wall"></div>
                </div> */}
                <div className="end">
                    <div className="columns">
                        <div className="column is-2"></div>
                        <div className="column is-2">
                            <div className="logo">
                                <a href="#">
                                    <img src={logo} alt="" />
                                </a>
                            </div>
                            <div className="social">
                                <a href="https://dribbble.com/MATATE" target="_blank">
                                    <img src={dr} alt="" />
                                </a>
                                <a href="http://instagram.com/matate_sk/" target="_blank">
                                    <img src={ig} alt="" />
                                </a>
                            </div>
                        </div>
                        <div className="column is-4">
                            <p className="center_up">
                                {this.props.t('footer.text1')} {this.props.t('footer.text2')} 
                            </p>
                            <p className="center_down">
                        
                            </p>
                        </div>
                        <div className="column is-2">
                            <div className="links">
                                <a href="mailto: info@matate.sk" className="email">
                                    info@matate.sk
                                </a>
                                <a href="tel:+421 902 359 330" className="tel">
                                    +421 902 359 330
                                </a>
                            </div>
                        </div>
                        <div className="column is-2"></div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default withTranslation()(Footer);
