import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
//import _ from 'lodash-es'

import management01 from '../../images/services/management/management-01.png'
import management02 from '../../images/services/management/management-02.png'

import management01retina from '../../images/services/management/management-01@2x.png'
import management02retina from '../../images/services/management/management-02@2x.png'


export default class ContentManagement extends Component {
    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    <div className="block">
                        <div className="block__text">
                            <div className="text-toptitle">UI/UX</div>
                            <h2>Projektový manažment</h2>
                            <p>Postaráme sa o váš projekt, ako by bol náš vlastný. Zabezpečíme skutočne všetko.</p>
                            <p>Od komunikácie s tímom, cez zabezpečenie kvality výstupov od programátorov, grafikov či marketérov až po riadenie času a testovanie.</p>
                            <p>Cieľom je, aby všetko fungovalo ako švajčiarske hodinky. Vy šetríte peniaze a čas. My predchádzame problémom v oblasti, ktorej rozumieme.</p>
                        </div>
                        <div className="block__image">
                            <img src={management01} srcSet={`${management01}, ${management01} 1x, ${management01retina} 2x`} alt="Projektový manažment" />
                        </div>
                    </div>
                    <div className="block">
                        <div className="block__image">
                            <img src={management02}  srcSet={`${management02}, ${management02} 1x, ${management02retina} 2x`} alt="Konzultácie" />
                        </div>
                        <div className="block__text">
                            <div className="text-toptitle">s možnosťou administrácie.</div>
                            <h2>Konzultácie</h2>
                            <p>Chcete sa vyhnúť chybám? Potrebujete poradiť, aby mohol váš projekt rásť? Zodpovieme všetky vaše otázky a ukážeme vám správny smer.</p>
                        </div>
                    </div>
                </div> 
            </section>
        )
    }
}
