import React from 'react'
import { Helmet } from "react-helmet";

// glboal components
import Footer from '../../components/home/Footer'

// hero specifiy components 
import HeroItems from '../../components/reference/HeroItems'
import ReferenceMirano from '../../components/reference/ContentMirano'
import Header from '../../components/reference/Header'
import Contact from '../../components/reference/Contact'
import Portfolio from '../../components/home/Portfolio'

import FreeConsultation from '../../components/home/FreeConsultation'

// import Notifications, { notify } from 'react-notify-toast';

class Mirano extends  React.Component {
  constructor(){
    super();

    this.state = {
        hasPortfolio: true, // portfolio on/off
        subtitle: "Mirano",
        title: "Nízkoenergetické montované domy",
        hashTags: [
            "#2020 - 2021",
            "#UI/UX dizajn",
            "#web", 
            "#marketing",
            "#prints"
        ]
    }
  }

  render() {
      return (
          <div className="referenceView mirano">
              <Helmet>
                <title> MIRANO - montované domy | Referencie | MATATE</title>
                <meta name="description" content="Mirano referencia matate" />
              </Helmet>
              <FreeConsultation />
              <Header />
              <HeroItems subtitle={this.state.subtitle} title={this.state.title} hashTags={this.state.hashTags} />
              <ReferenceMirano />
              <Portfolio status={this.state.hasPortfolio} />
              <Contact />
              <Footer />
          </div>
        )
    }
}

export default Mirano;
