import React, { Component } from 'react'
import $ from "jquery"
import Notifications, {notify} from 'react-notify-toast';

import { withTranslation } from "react-i18next"; // Import withTranslation
// import i18n from 'i18next';

import modal_web from '../../images/modal_web.svg'
import modal_marketing from '../../images/modal_marketing.svg'
import modal_ine from '../../images/modal_ine.svg'

import { Button } from '../../components/home/Button'

class ModalConsultation extends Component {
    constructor(props){
        super(props)
        this.state = {
            type: 1,
            email: '', 
            tel: '', 
            text: '',
            sent: false
        }
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }
    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    email = () => {
        var api_key = 'a89a0ff9aea2ee281d70816f0a3127ac-1d8af1f4-0e6fca5b';
        var domain = 'mg.matate.sk';
        var mailgun = require('mailgun-js')({apiKey: api_key, domain: domain, host: "api.eu.mailgun.net"});
         
        var text = "<h3><b>Email:</b> " + this.state.email + " </h3> "
            text += "<h3><b>Telefón:</b> " + this.state.tel + " </h3> "

        if(this.state.type == 1)
            text += "<h3><b>Typ:</b> Web </h3> "
        else if(this.state.type == 2)
            text += "<h3><b>Typ:</b> Marketing </h3> "
        else if(this.state.type == 3)
            text += "<h3><b>Typ:</b> Iné </h3> "
            
            text += "<h3><b>Text:</b> " + this.state.text + " </h3> "

        var data = {
          from: 'Matate server <server@mg.matate.sk>',
          to: 'info@matate.sk'/**/,
          subject: 'Konzultácia pre '+this.state.email+' - matate.sk',
          html: text
        };
        data['h:Reply-To'] = this.state.email;
        if(this.state.email != "" && this.state.text != "" && this.validateEmail(this.state.email)){
            mailgun.messages().send(data);
           // notify.show("Správa bola úspešne odoslaná.", 'success');
            this.setState({sent: true});

            var service = null;
            if(this.state.type == 1) {
                service =  "Web";
            } else if(this.state.type == 2) {
                service =  "Marketing";
            }
            else {
                service = "Iné";
            }
            
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'contactFormSent',
                form_type: "floatingForm",
                user: {
                    email: this.state.email,
                    phone: this.state.tel,
                    services: service,
                }
              });

            $('.toast-notification').attr('id', "float-form-sent-success");
            this.setState({email: "", tel: "", text: "", });

            setInterval(() => {
                this.props.close();
                this.setState({sent: false});
            }, 2000);

        }else{
            notify.show("Zle vyplnené udaje.", 'error');
            this.setState({sent:false});
        }
    }
    componentDidMount(){
        new Button('Mybtn-6')
    }
    render() {

        const { t } = this.props; // Access t from props
        return (
            <div id="myModal" className={`modal ${this.props.show ? "active" : ""}`}>
                <div className="modal_content row">
                    <div className="col-lg-12 TopClose pt-5 pr-5">
                        <svg onClick={this.props.close} className="close" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.4851 9.51472L9.51458 26.4853M9.51458 9.51472L26.4851 26.4853L9.51458 9.51472Z" stroke="#FEA9B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div className="row cont p-0">
                        <div className="col-lg-12 p-0">
                            <h3 className="firstTitle">{t('kontaktModal.title')}</h3>
                        </div>
                        <div className="col-lg-12 p-0 mt-4">
                            <h1 className="secondTitle">
                            {t('kontaktModal.topText')}
                            </h1>
                        </div>
                        <div className="col-lg-12 p-0 mt-4">
                            <div className="form-floating mb-3">
                                <input type="email" name="email" className="form-control" id="floatingInput" placeholder={t('kontaktModal.email')}
                                         value={this.state.email}
                                         onChange={this.handleInputChange}/>
                                <label for="floatingInput">{t('kontaktModal.email')}</label>
                            </div>
                        </div>
                        <div className="col-lg-12 p-0">
                            <div className="form-floating mb-3">
                                <input type="tel" name="tel" className="form-control" id="floatingInput" placeholder={t('kontaktModal.phone')}
                                         value={this.state.tel}
                                         onChange={this.handleInputChange}/>
                                <label for="floatingInput">{t('kontaktModal.phone')}</label>
                            </div>
                        </div>
                        <div className="col-lg-12 row p-0 m-0 modal_what">
                            <div className="col-lg-3">
                                <div onClick={() => this.setState({ type: 1 })} className={`box ${this.state.type == 1 ? "active" : ""}`}>
                                    <img src={modal_web} alt="web" />
                                    <span>
                                        Web
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div onClick={() => this.setState({ type: 2 })} className={`box ${this.state.type == 2 ? "active" : ""}`}>
                                    <img src={modal_marketing} alt="web" />
                                    <span>
                                        Marketing
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div onClick={() => this.setState({ type: 3 })} className={`box ${this.state.type == 3 ? "active" : ""}`}>
                                    <img src={modal_ine} alt="web" />
                                    <span>
                                        {t('kontaktModal.type3')}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 p-0 mt-2">
                            <div class="form-floating">
                                <textarea name="text" class="form-control" placeholder="Správa" id="floatingTextarea"
                                    value={this.state.text}
                                    onChange={this.handleInputChange}>
                                </textarea>
                                <label for="floatingTextarea">{t('kontaktModal.msg')}</label>
                            </div>
                        </div>
                        {/* <div className="col-lg-12 p-0 mt-2">
                            <div class="form-chec suhlasim">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" />
                                <label class="form-check-label" for="flexCheckChecked">
                                    Súhlasím so <a href="#">spracovaním osobných údajov</a> 
                                </label>
                            </div>
                        </div>*/}
                        <div className="col-lg-12 p-0 mt-4 relative">
                            <button type="submit" className="Mybtn Mybtn-6 p-0">
                                <svg width="456" height="50" viewBox="0 0 456 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="455" height="49" rx="24.5" stroke="white"/>
                                </svg>
                                <span className="hover"></span>
                                <div className="text" onClick={this.email}>{t('kontaktModal.submitBtn')}</div> 
                            </button>

                             {this.state.sent ? <div className="success-msg">{t('messages.success')}</div> : null }

                            <Notifications  options={{zIndex: 99999, top: '750px'}} />
                        </div>
                    </div>
                </div>
          </div>
        )
    }
}

export default withTranslation()(ModalConsultation);