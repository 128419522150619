import React from "react";
import { Helmet } from "react-helmet";

import FreeConsultation from "./components/home/FreeConsultation";
import Header from "./components/blog/Header";
import List from "./components/blog/list";
import Contact from "./components/services/Contact";
import Footer from "./components/home/Footer";

class Blog extends React.Component {
  render() {
    return (
      <div className="blog">
        <Helmet>
          <title>Blog | MATATE</title>
          <meta name="description" content="Matate Blog" />
        </Helmet>
        <FreeConsultation />
        <Header />
        <List />
        <Contact />
        <Footer />
      </div>
    );
  }
}

export default Blog;
