import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'
import { TweenMax } from 'gsap/all'

import icon01 from '../../images/services/cookie.svg'
import icon02 from '../../images/services/question.svg'

export default class List extends Component {

    constructor() {
        super();
        this.state = {animationRunned: false};
    }

    list_load = () => {
        let that = this;
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            if (!that.state.animationRunned) {
                $('.services-list').css('opacity', '1');
                TweenMax.staggerFrom(".service-box", 1.5, { y: 50, opacity: 0, clearProps: 'all'}, 0.35);
                that.setState({animationRunned: true});
            }
        }, 500);
    }

    render() {
        return (
            <section class="block-services-list">
                   <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.list_load}
                        />

                <div class="container-block">
                    <div className="services-list">
                 
                        <div class="service-box upper">
                            <div class="box-top">
                                <img src={icon01} alt="Tvorba web stránok" />
                                <h2>Koláčiky (ne)štastia? </h2>
                            </div>
                            <div class="box-data">
                                <div class="text"> 
                                    <p>Cookies lišty sú od 1.2.2022 veľkou témou slovenského webového priestoru. Je to tak preto, že sa do platnosti dostal zákon č. 351/2011 Z.z. o elektronických komunikáciách. Ten okrem iného hovorí aj o tom, ako weby môžu, resp. nemôžu narábať s cookies na webe a že k práci s cookies potrebujú vyslovený súhlas od návštevníkov webu.</p>
                                </div>
                            </div>
                        </div>
                        
                        <div class="service-box">
                            <div class="box-top">
                                <img src={icon02} alt="Webová analytika" />
                                <h2>Poznáme know how a problematiku</h2>
                            </div>
                            <div class="box-data">
                                <div class="text">
                                    <p>Tejto problematike sme sa intenzívne venovali a vieme pre vás implementovať cookie lištu na váš web a tiež vyriešiť implementáciu CMP (systém pre správu súhlasov) podľa zákonných náležitosti.<br></br>
                                        Všetko nastavíme, vyladíme a odovzdáme hotové na kontrolu vašim, alebo našim spolupracujúcim právnikom. </p>
                                </div>

                            </div>
                        </div>
                    
                    </div>
                </div> 
            </section>
        )
    }
}
