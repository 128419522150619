import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'
//import Parallax from 'react-rellax'

import eye from '../../images/references/medirex/eye.svg'
import wireframe from '../../images/references/medirex/wireframe.svg'

//import coverImage from '../../images/references/medirex/cover.png'
//import coverImage2x from '../../images/references/medirex/cover@2x.png'

import refImage01 from '../../images/references/medirex/screen-01.png'
import refImage01_2x from '../../images/references/medirex/screen-01@2x.png'

import refImage02 from '../../images/references/medirex/screen-02.png'
import refImage02_2x from '../../images/references/medirex/screen-02@2x.png'

import refImage03 from '../../images/references/medirex/screen-03.png'
import refImage03_2x from '../../images/references/medirex/screen-03@2x.png'

import card01 from '../../images/references/medirex/card-01.png'
import card01_text from '../../images/references/medirex/card-01-text.png'
import card02 from '../../images/references/medirex/card-02.png'
import points from '../../images/references/medirex/points.png'
import cursor from '../../images/references/medirex/cursor-hand.png'
import leftArrow from '../../images/references/medirex/left-arrow.png'
import rightArrow from '../../images/references/medirex/right-arrow.png'

import mobileScreen from '../../images/references/medirex/mobile-screen.png'

import medirexVideo from '../../images/references/medirex/mdrx-animate.mp4'

export default class ContentMedirex extends Component {

    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $(".back-link").css("opacity", "1");
        }, 1500);
    }

    blockIntro = () => {
        _.delay(function() {
            $(".block--intro").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(0).css("opacity", "1").css("transform", "none");
        }, 400);
        
        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(1).css("opacity", "1").css("transform", "none");
        }, 750);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(2).css("opacity", "1").css("transform", "none");
        }, 950);
    }

    blockCoverImage = () => {
        $(".block--cover-image").find('.overlayer').css("width", "0");
    }

    blockScreen = () => {
        $(".block--screens").find(".block-image").css("opacity", "1").css("transform", "none");
    }

    blockPreviews = () => {

        _.delay(function() {

            let screens = $(".block--previews").find(".block-image");

            $.each(screens, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);
    }

    blockColumns = () => {

        let columnItems = $(".block--columns").find(".services__item");

        _.delay(function() {
            $.each(columnItems, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);

    }

    blockImages = () => {

        _.delay(function() {
            $(".block-images").find(".overlayer").css("width", "0");
        }, 200);

        _.delay(function() {
            $(".block-images").find("img").css("opacity", "1").css("transform", "none");
        }, 1000);
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    
                    <div class="block--intro">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockIntro}
                            bottomOffset="20%"
                        />
                        <h2>Web s dizajnom na mieru </h2>
                        <p>Medirex group academy je nezisková organizácia, ktorá sa zameriava na oblasť vedy a výskumu v biomedicíne. Pripravili sme web, ktorý na prvý pohľad návštevníkom dá pocit výskumu a budúcnosti.</p>
                        <p> Ako hlavný prvok sme zvolili DNA, ktoré je rozanimované a pri prechode myšou sa roztrúsi.</p>
                    </div>

                    <div class="block--boxes">
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={eye} alt="UI UX dizajn" />
                                <h3>UI/UX dizajn</h3>
                            </div>
                            <p>Pri tomto webe sme mali úlohu navrhnúť web s vyjadrením výskumu a budúcnosti.</p>

                            <p>Preto sme vytvorili dizajn na mieru s tzv. “particles” efektom, pričom hlavný prvok DNA je rozanimovaný a pri prechode myšou sa rozplynie. </p>

                            <p>Zároveň bolo potrebné navrhnúť spôsob zobrazovania veľkého počtu dát pri jednotlivých projektoch.</p> 
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={wireframe} alt="Wireframy" />
                                <h3>PROGRAMOVANIE A CMS</h3>
                            </div>
                            <p>Webovú stránku sme zastrešovali komplet na mieru a mali sme v rukách aj implementáciu dizajnu. Znamená to, že sme web naprogramovali a nasadili na CMS Wordpress pre správu obsahu. Web sme naprogramovali tak, aby bol rýchly, optimalizovaný na všetky zariadenia a využili sme aj mikro animácie.</p>
                            <p>Webová stránka dosahuje perfektné hodnotenia rýchlosti.</p>
                        </div>
                    </div>

                    <div className="link-row link-row--topNegative">
                            <a href="https://www.medirexgroupacademy.sk" target="blank">navštíviť www.medirexgroupacademy.sk <i className="arrow-next"></i></a>
                    </div>
                </div> 

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    {/* <img src={coverImage}  srcSet={`${coverImage}, ${coverImage} 1x, ${coverImage2x} 2x`}  alt="medirex" />
                    <div class="overlayer"></div> */}

                    <video autoPlay loop muted id="video">
                        <source src={medirexVideo} type="video/mp4"></source>
                    </video>
                </div>


                <div className="block--columns">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockColumns}
                            bottomOffset="10%"
                        />
                    <div className="container-block">
                        <div className="services">
                            <div className="services__item columns">
                                <div className="services__image column is-half">
                                   <img class="cg02_main" src={mobileScreen} alt="medirex" />
                                </div>
                                <div className="services__image column is-half">
                                    <img class="cg01" src={card01} alt="medirex" />
                                    <img class="cg02" src={card01_text} alt="medirex" />
                                    <img class="cg03" src={card02} alt="medirex" />
                                    <img class="cg04" src={points} alt="medirex" /> 
                                    <img class="cg05" src={cursor} alt="medirex" /> 
                                    <img class="cg06" src={leftArrow} alt="medirex" /> 
                                    <img class="cg07" src={rightArrow} alt="medirex" /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block--previews">
                    <div class="container-block"> 
                        <div class="previews-screens">

                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPreviews}
                            />
                            <div class="columns">
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage01} srcSet={`${refImage01}, ${refImage01} 1x, ${refImage01_2x} 2x`}  alt="medirex" />
                                </div>
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage02} srcSet={`${refImage02}, ${refImage02} 1x, ${refImage02_2x} 2x`}  alt="medirex" />
                                    <img class="block-image" src={refImage03} srcSet={`${refImage03}, ${refImage03} 1x, ${refImage03_2x} 2x`}  alt="medirex" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
