import React, { Component } from 'react'
import $ from "jquery"
import _ from 'lodash-es'
import { Waypoint } from 'react-waypoint';
import { withTranslation } from "react-i18next"; // Import withTranslation
//import i18n from 'i18next';

import whywe1 from '../../images/aboutus/whywe1.svg'
import whywe2 from '../../images/aboutus/whywe2.svg'
import whywe3 from '../../images/aboutus/whywe3.svg'


class WhyWe extends Component {
        
    team_load = () => {
        _.delay(function() {
            $('.team .team_line .line').css('transition','stroke-dashoffset 3s ease').css('stroke-dashoffset', '5600px');
        }, 100);
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('width','70px');
    }
    whywe_load = () => {
        _.delay(function() {
            $('.whywe .AU_animation8').css('opacity','1');
        }, 500);
        _.delay(function() {
            $('.whywe .AU_animation9').css('transform', 'translateX(0px)')
        }, 800);
        _.delay(function() {
            $('.whywe .AU_animation10').css('transform', 'translateX(0px)')
        }, 1000);
        _.delay(function() {
            $('.whywe .AU_animation11').css('transform', 'translateX(0px)')
        }, 1200);
    }
    render() {
        const { t } = this.props; // Access t from props
        return (
            <div className="columns is-desktop">
                <Waypoint
                    scrollableAncestor={window}
                    onEnter={this.team_load}
                />
                <div className="whywe">
                    <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.whywe_load}
                    />
                    <div className="left AU_animation8">
                        <div>
                            <h2>
                                {t('pageOnas.whyChooseUs.title1')}
                            </h2>
                            <h1>
                                {t('pageOnas.whyChooseUs.title2')}
                            </h1>
                        </div>
                    </div>
                    <div className="right">
                        <div className="reveal_wrap">
                            <div className="block AU_animation9">
                                <img src={whywe1} alt=""/>
                                <div>
                                    <h2>{t('pageOnas.whyChooseUs.reasons.reason1.title')}</h2>
                                    <p>
                                        {t('pageOnas.whyChooseUs.reasons.reason1.description')}
                                    </p>
                                </div>
                            </div>
                            <div className="block AU_animation10">
                                <img src={whywe2} alt=""/>
                                <div>
                                    <h2>{t('pageOnas.whyChooseUs.reasons.reason2.title')}</h2>
                                    <p>
                                        {t('pageOnas.whyChooseUs.reasons.reason2.description')}
                                    </p>
                                </div>
                            </div>
                            <div className="block AU_animation11">
                                <img src={whywe3} alt=""/>
                                <div>
                                    <h2>{t('pageOnas.whyChooseUs.reasons.reason3.title')}</h2>
                                    <p>
                                    {t('pageOnas.whyChooseUs.reasons.reason3.description')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(WhyWe);