import React, { useState } from "react";
import _ from "lodash-es";
import $ from "jquery";
//import { CustomCursor } from "../../components/home/CustomCursor";
import { Waypoint } from "react-waypoint";
import ModalConsultation from "./ModalConsultation";
import { useTranslation } from "react-i18next";

import { Player } from '@lottiefiles/react-lottie-player';

// earlier

import webdesignHover from "./assets/web-design.json";
import wireframeHover from "./assets/wireframe.json";
import timeManagementHover from "./assets/time-management.json";
import analyticsHover from "./assets/analytics.json";
import analyticalHover from "./assets/analytical-skills.json";
import onlineHover from "./assets/online.json";

import web from "../../images/nase_sluzny/web.svg";
import ux from "../../images/nase_sluzny/ux.svg";
import analytics from "../../images/nase_sluzny/analytics.svg";
import seo from "../../images/nase_sluzny/seo.svg";
import marketing from "../../images/nase_sluzny/online-marketing.svg";
import projectManagement from "../../images/nase_sluzny/projekt-management.svg";

const OurServices_LoadPage = () => {

  _.delay(function () {
    $("#section4 svg .line")
      .css("transition", "stroke-dashoffset 3s ease")
      .css("stroke-dashoffset", "5600px");
    $(".animation_7").css("transform", "matrix(1, 0, 0, 1, 0, 0)");
  }, 200);

  _.delay(function () {
    $("#section4 .carousel").css("opacity", "1");
    $("#section4 .gallery").addClass("animation_carousel");
  }, 500);
};

const OurServices = () => {
  const { t } = useTranslation();
  const [Modal, setModal] = useState(false);

  const modal_close = () => {
    setModal(false);
  };

  const modal_show = (index) => {
    let scrollY = $(window).scrollTop();

    setModal(true);
    _.delay(function () {

      $("#myModal .modal_content").css("transform", "scale(1)");

    }, 500);
  };

  return (
    <div>
      <ModalConsultation close={() => modal_close()} show={Modal} />
      <section id="section4" className="services-wrapper">
        <Waypoint
          scrollableAncestor={window}
          onEnter={function (props) {
            OurServices_LoadPage();
          }}
        />

        {/* <div className="container-text">
          <div className="columns">
            <div className="column is-3"></div>
            <div className="column">
              <div className="reveal_wrap">
                <div
                  className="header animation_7"
                  style={{ transform: "matrix(1, 0, 0, 1, 0, 180)" }}
                ></div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="center-headline">
          <div className="subtitle is-spaced">
            {t("pageHome.section4.headline")}
          </div>
          <h2 className="title">{t("pageHome.section4.title")}</h2>
        </div>

        <div className="services">
          <a href={t("service.web", { ns: "routes" })} className="service-item">
            <div className="service-item__content">
                <div className="image-holder">
                    <img className="service-item__image" src={web} alt="." />
                    <Player
                        autoplay
                        loop
                        src={webdesignHover}
                        style={{ height: '86px', width: '86px' }}
                        >
                    </Player>
                </div>
              <h2 className="service-item__title">Tvorba web stránok</h2>
              <p className="service-item__desc">
                Tvoríme web stránky na mieru, ktoré posunú váš biznis na vyšší
                level. Prehľadné a pútavé. Aby sa zákazníkom páčili a radi
                spravili objednávku.
              </p>
              <div className="service-item__tags">
                <span>eshop</span>
                <span>webstránka</span>
                <span>wordpress</span>
                <span>interný systém</span>
                <span>webová aplikácia</span>
              </div>
            </div>
            <div className="service-item__link-holder">
              <div className="link">Zobraziť viac</div>
            </div>
          </a>

          <a
            href={t("service.onlineMarketing", { ns: "routes" })}
            className="service-item"
          >
            <div className="service-item__content">
                <div className="image-holder">
                <img className="service-item__image" src={marketing} alt="." />
                <Player
                        autoplay
                        loop
                        src={onlineHover}
                        style={{ height: '86px', width: '86px' }}
                        >
                    </Player>
                </div>
              <h2 className="service-item__title">Online marketing</h2>
              <p className="service-item__desc">
                Odhaľujeme skutočné správanie zákazníkov. Čo sa im páči a čo
                nie. Testujeme web, aby sme zistili, kde presne treba zabrať.
              </p>
              <div className="service-item__tags">
                <span>PPC kampane</span>
                <span>Sociálne siete</span>
                <span>Google reklamy</span>
                <span>Youtube reklamy</span>
              </div>
            </div>
            <div className="service-item__link-holder">
              <div className="link">Zobraziť viac</div>
            </div>
          </a>
          
          <a href={t("service.seo", { ns: "routes" })} className="service-item">
            <div className="service-item__content">
                <div className="image-holder">
                <img className="service-item__image" src={seo} alt="." />
                <Player
                        autoplay
                        loop
                        src={analyticalHover}
                        style={{ height: '86px', width: '86px' }}
                        >
                    </Player>
                </div>
              <h2 className="service-item__title">SEO</h2>
              <p className="service-item__desc">
                Vyladíme váš web, aby si rozumel s ľuďmi aj s robotmi. SEO audit
                nám povie na čo sa máme zamerať, aby vám web prinášal
                zákazníkov.
              </p>
            </div>
            <div className="service-item__link-holder">
              <div className="link">Zobraziť viac</div>
            </div>
          </a>

          <a
            href={t("service.uxOnTheWeb", { ns: "routes" })}
            className="service-item"
          >
            <div className="service-item__content">
            <div className="image-holder">
              <img className="service-item__image" src={ux} alt="."  />
              <Player
                    autoplay
                    loop
                    src={wireframeHover}
                    style={{ height: '86px', width: '86px' }}
                    >
                </Player>
                </div>
              <h2 className="service-item__title">UX vo webe</h2>
              <p className="service-item__desc">
                Odhaľujeme skutočné správanie zákazníkov. Čo sa im páči a čo
                nie. Testujeme web, aby sme zistili, kde presne treba zabrať.
              </p>
            </div>
            <div className="service-item__link-holder">
              <div className="link">Zobraziť viac</div>
            </div>
          </a>

          <a
            href={t("service.webAnalytics", { ns: "routes" })}
            className="service-item"
          >
            <div className="service-item__content">
                <div className="image-holder">
                    <img className="service-item__image" src={analytics} alt="." />
                    <Player
                        autoplay
                        loop
                        src={analyticsHover}
                        style={{ height: '86px', width: '86px' }}
                        >
                    </Player>
                </div>
              <h2 className="service-item__title">Webová analytika</h2>
              <p className="service-item__desc">
                Nastavíme vám analytické nástroje a prepojíme ich s webom.
                Následne sa dzvieme viac o správaní zákazníkov a plánujeme
                marketingové aktivity.
              </p>
              <div className="service-item__tags">
                <span>Google Analytics 4</span>
                <span>GTM</span>
                <span>Facebook pixel</span>
              </div>
            </div>
            <div className="service-item__link-holder">
              <div className="link">Zobraziť viac</div>
            </div>
          </a>



          <a
            href={t("service.management", { ns: "routes" })}
            className="service-item"
          >
            <div className="service-item__content">
                <div className="image-holder">
                <img className="service-item__image" src={projectManagement} alt="." />
                <Player
                        autoplay
                        loop
                        src={timeManagementHover}
                        style={{ height: '86px', width: '86px' }}
                        >
                    </Player>
                </div>
              <h2 className="service-item__title">Projektový manažment</h2>
              <p className="service-item__desc">
                Zabezpečíme efektívne riadenie vášho projektu. Môžete sa na nás
                spoľahnúť od A po Z. Potrebujete poradiť od expertov? Sme tu pre
                vás.
              </p>
            </div>
            <div className="service-item__link-holder">
              <div className="link">Zobraziť viac</div>
            </div>
          </a>
        </div>

        <div className="btn-holder">
          <button type="button" className="btn--outline js-contact-cta" onClick={()=>modal_show()}>
            <span className="text">
              Chcem konzultáciu zadarmo <span className="hand">👋</span>
            </span>
          </button>
        </div>
      </section>
    </div>
  );
};

export default OurServices;
