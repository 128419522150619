import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'
//import Parallax from 'react-rellax'

import eye from '../../images/references/nadmerka/eye.svg'
import analytics from '../../images/references/nadmerka/analytics.svg'

import coverImage from '../../images/references/nadmerka/cover-image.png'
import coverImage2x from '../../images/references/nadmerka/cover-image@2x.png'

import mockupImage from '../../images/references/nadmerka/mockup-screen.png'
import mockupImage_2x from '../../images/references/nadmerka/mockup-screen@2x.png'

import cardgroup01_01 from '../../images/references/nadmerka/screen-01.png'
import cardgroup01_01_2x from '../../images/references/nadmerka/screen-01@2x.png'

import cardgroup02_01 from '../../images/references/nadmerka/screen-02.png'
import cardgroup02_01_2x from '../../images/references/nadmerka/screen-02@2x.png'

import cardProductRow from '../../images/references/nadmerka/product-rows.png'
import cardProductRow_2x from '../../images/references/nadmerka/product-rows@2x.png'

import refImage01 from '../../images/references/nadmerka/preview-screen-01.png'
import refImage01_2x from '../../images/references/nadmerka/preview-screen-01@2x.png'

import refImage02 from '../../images/references/nadmerka/preview-screen-02.png'
import refImage02_2x from '../../images/references/nadmerka/preview-screen-02@2x.png'

import refImage03 from '../../images/references/nadmerka/preview-screen-03.png'
import refImage03_2x from '../../images/references/nadmerka/preview-screen-03@2x.png'

import refImage04 from '../../images/references/nadmerka/preview-screen-04.png'
import refImage04_2x from '../../images/references/nadmerka/preview-screen-04@2x.png'


export default class ContentNadmerka extends Component {

    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $(".back-link").css("opacity", "1");
        }, 1500);

    }

    blockIntro = () => { 
        _.delay(function() {
            $(".block--intro").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(0).css("opacity", "1").css("transform", "none");
        }, 400);
        
        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(1).css("opacity", "1").css("transform", "none");
        }, 750);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(2).css("opacity", "1").css("transform", "none");
        }, 950);
    }

    blockCoverImage = () => {
        $(".block--cover-image").find('.overlayer').css("width", "0");
    }

    blockScreen = () => {
        $(".block--screens").find(".block-image").css("opacity", "1").css("transform", "none");
    }

    blockColumns = () => {

        let columnItems = $(".block--columns").find(".services__item");

        _.delay(function() {
            $.each(columnItems, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);
    }

    blockPack = () => {
        _.delay(function() {
            $(".block--image-pack").find(".overlayer").css("width", "0");
        }, 1000);
    }

    blockPreviews = () => {

        _.delay(function() {

            let screens = $(".block--previews").find(".block-image");

            $.each(screens, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);
    }


    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    
                    <div class="block--intro">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockIntro}
                            bottomOffset="20%"
                        />
                        <h2>Nový dizajn pre e-shop s topánkami</h2>
                        <p> Zadanie bolo jasné: potrebujeme nový, modernejší a vyladený dizajn pre náš e-shop s obuvou. Tak sme sa do toho pustili a pripravili to čo najlepšie.</p>
                        <p>Zamerali sme sa na to, aby sa ľuďom e-shop používal čo najlepšie. Spravili sme si analýzu, identifikovali sme prvky, ktoré sa najviac používajú a tie vytiahli von. </p>
                    </div>

                    <div class="block--boxes">
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={analytics} alt="ANALÝZA, USER EXPERIENCE" />
                                <h3>ANALÝZA, USER EXPERIENCE</h3>
                            </div>
                            <p>Ešte pred samotným kreslením dizajnov sme si spravili hĺbkovú analýzu. Pozreli sme sa na heatmapy a nahrávky, kde sme zistili, ako zákazníci používajú starý dizajn e-shopu. Podľa toho sme navrhli zlepšenia a nový dizajn.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={eye} alt="UI UX DIZAJN" />
                                <h3>UI / UX DIZAJN</h3>
                            </div>
                            <p>Na základe zistení z úvodnej analýzy sme navrhli nový moderný dizajn. Využili sme oblé tvary a všetky plochy sme navrhli tak, aby
                                sme zobrazili čo najviac fotiek samotných produktov - topánok.
                                Nákup sme sa snažili čo najviac zjednodušiť a spraviť ho čo najpríjemnejší pre používateľa.</p>
                        </div>
                    </div>
                    
                    <div className="link-row link-row--topNegative">
                            <a href="https://WWW.NADMERKA.SK" target="blank">navštíviť WWW.NADMERKA.SK <i className="arrow-next"></i></a>
                    </div>
                </div> 

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    <img src={coverImage}  srcSet={`${coverImage}, ${coverImage} 1x, ${coverImage2x} 2x`}  alt="Nadmerka" />
                    <div class="overlayer"></div>
                </div>

                <div className="block--columns">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockColumns}
                            bottomOffset="10%"
                        />
                    <div className="container-block">
                        <div className="services">
                            <div class="columns">
                                <div className="services__image column is-half">
                                    <img src={cardgroup02_01} srcSet={`${cardgroup02_01}, ${cardgroup02_01} 1x, ${cardgroup02_01_2x} 2x`} alt="Nadmerka" />
                                    <img class="shoes-list" src={cardProductRow} srcSet={`${cardProductRow}, ${cardProductRow} 1x, ${cardProductRow_2x} 2x`} alt="Nadmerka" />
                                </div>
                                <div className="services__image column is-half">
                                        <img src={cardgroup01_01} srcSet={`${cardgroup01_01}, ${cardgroup01_01} 1x, ${cardgroup01_01_2x} 2x`} alt="Nadmerka" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block--screens">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockScreen}
                            bottomOffset="5%"
                        />
                    <img class="block-image" src={mockupImage} srcSet={`${mockupImage}, ${mockupImage} 1x, ${mockupImage_2x} 2x`}  alt="Nadmerka" />
                </div>

                <div class="block--previews">
                    <div class="container-block"> 
                        <div class="previews-screens">

                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPreviews}
                            />
                            <div class="columns">
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage01} srcSet={`${refImage01}, ${refImage01} 1x, ${refImage01_2x} 2x`}  alt="Nadmerka" />
                                    <img class="block-image" src={refImage02} srcSet={`${refImage02}, ${refImage02} 1x, ${refImage02_2x} 2x`}  alt="Nadmerka" />
                                </div>
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage03} srcSet={`${refImage03}, ${refImage03} 1x, ${refImage03_2x} 2x`}  alt="Nadmerka" />
                                    <img class="block-image" src={refImage04} srcSet={`${refImage04}, ${refImage04} 1x, ${refImage04_2x} 2x`}  alt="Nadmerka" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
