import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'
//import Parallax from 'react-rellax'

import eye from '../../images/references/mnam/eye.svg'
//import wireframe from '../../images/references/mnam/wireframe.svg'

import coverImage from '../../images/references/mnam/cover_image.png'
import coverImage2x from '../../images/references/mnam/cover_image_2x.png'

import blockImage01 from '../../images/references/mnam/screen-01.png'
import blockImage01_2x from '../../images/references/mnam/screen-01@2x.png'

import blockImage02 from '../../images/references/mnam/screen-02.png'
import blockImage02_2x from '../../images/references/mnam/screen-02@2x.png'

import refImage01 from '../../images/references/mnam/preview-screen-01.png'
import refImage01_2x from '../../images/references/mnam/preview-screen-01@2x.png'

import refImage02 from '../../images/references/mnam/preview-screen-02.png'
import refImage02_2x from '../../images/references/mnam/preview-screen-02@2x.png'

import refImage03 from '../../images/references/mnam/preview-screen-03.png'
import refImage03_2x from '../../images/references/mnam/preview-screen-03@2x.png'

import refImage04 from '../../images/references/mnam/preview-screen-04.png'
import refImage04_2x from '../../images/references/mnam/preview-screen-04@2x.png'

import refImage05 from '../../images/references/mnam/preview-screen-05.png'
import refImage05_2x from '../../images/references/mnam/preview-screen-05@2x.png'

import mockupImage from '../../images/references/mnam/mockup-screen.png'
import mockupImage_2x from '../../images/references/mnam/mockup-screen@2x.png'

import cardgroup01_01 from '../../images/references/mnam/card-group-01-01.png'
import cardgroup01_02 from '../../images/references/mnam/card-group-01-02.png'
import cardgroup01_03 from '../../images/references/mnam/card-group-01-03.png'
import cardgroup01_04 from '../../images/references/mnam/card-group-01-04.png'

import cardgroup02_01 from '../../images/references/mnam/card-group-02-01.png'

//import cardgroup03_01 from '../../images/references/mnam/card-group-03-01.png'
//import cardgroup03_02 from '../../images/references/mnam/card-group-03-02.png'

export default class ContentMnam extends Component {

    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $(".back-link").css("opacity", "1");
        }, 1500);

    }

    blockIntro = () => {
        _.delay(function() {
            $(".block--intro").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(0).css("opacity", "1").css("transform", "none");
        }, 400);
        
        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(1).css("opacity", "1").css("transform", "none");
        }, 750);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(2).css("opacity", "1").css("transform", "none");
        }, 950);
    }

    blockCoverImage = () => {
        $(".block--cover-image").find('.overlayer').css("width", "0");
    }

    blockScreen = () => {
        $(".block--screens").find(".block-image").css("opacity", "1").css("transform", "none");
    }

    blockPreviews = () => {

        _.delay(function() {

            let screens = $(".block--previews").find(".block-image");

            $.each(screens, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);
    }

    blockColumns = () => {

        let columnItems = $(".block--columns").find(".services__item");

        _.delay(function() {
            $.each(columnItems, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);

    }

    blockImages = () => {

        _.delay(function() {
            $(".block-images").find(".overlayer").css("width", "0");
        }, 200);

        _.delay(function() {
            $(".block-images").find("img").css("opacity", "1").css("transform", "none");
        }, 1000);
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    
                    <div class="block--intro">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockIntro}
                            bottomOffset="20%"
                        />
                        <h2>Dizajn pre delivery web</h2>
                        <p>MŇAM DELIVERY je delivery based web, ktorý má zastrešovať objednávku a doručenie jedál z viacerých prevádzok jednej spoločnosti. </p>

                        <p>Navrhli sme dizajn s prihliadaním na brand manuál a taktiež na dobré user-experience a jednoduché možnosti objednávania či zvolenia doplnkov a prísad.</p>
                    </div>

                    <div class="block--boxes">
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={eye} alt="UI UX dizajn" />
                                <h3>UI/UX dizajn</h3>
                            </div>
                            <p>Pri tomto projekte sme mali úlohu navrhnúť web, na ktorom je jednoduché objednať si jedlá z viacerých prevádzok a zároveň správne odprezentovať návštevníkom, že sa jedná o jednu skupinu značiek. Tiež bolo dôležité myslieť na správne UX, nakoľko sa jedná o objednávkové procesy, ktoré musia byť čo najjednoduchšie pre zákazníka.</p>
                        </div>
                    </div>
                </div> 

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    <img src={coverImage}  srcSet={`${coverImage}, ${coverImage} 1x, ${coverImage2x} 2x`}  alt="Mńam delivery" />
                    <div class="overlayer"></div>
                </div>

                
                <div class="container-block"> 
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockImages}
                        />  
                    <div class="block-images">
                        <img src={blockImage01} srcSet={`${blockImage01}, ${blockImage01} 1x, ${blockImage01_2x} 2x`} alt="Mńam delivery" />
                        <img src={blockImage02} srcSet={`${blockImage02}, ${blockImage02} 1x, ${blockImage02_2x} 2x`} alt="Mńam delivery" />
                        <div class="overlayer"></div>
                    </div>
                </div>

                <div className="block--columns">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockColumns}
                            bottomOffset="10%"
                        />
                    <div className="container-block">
                        <div className="services">
                            <div className="services__item columns">
                                <div className="services__image column is-half">
                                   <img class="cg02_main" src={cardgroup02_01} alt="Mńam delivery Service" />
                                </div>
                                <div className="services__image column is-half">
                                    <img class="cg01" src={cardgroup01_01} alt="Mńam delivery Service" />
                                    <img class="cg02" src={cardgroup01_02} alt="Mńam delivery Service" />
                                    <img class="cg03" src={cardgroup01_03} alt="Mńam delivery Service" />
                                    <img class="cg04" src={cardgroup01_04} alt="Mńam delivery Service" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block--screens">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockScreen}
                            bottomOffset="5%"
                        />
                    <img class="block-image" src={mockupImage} srcSet={`${mockupImage}, ${mockupImage} 1x, ${mockupImage_2x} 2x`}  alt="Mńam delivery Service" />
                </div>

                <div class="block--previews">
                    <div class="container-block"> 
                        <div class="previews-screens">

                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPreviews}        
                            />
                            <div class="columns">
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage01} srcSet={`${refImage01}, ${refImage01} 1x, ${refImage01_2x} 2x`}  alt="Mńam delivery Reference" />
                                    <img class="block-image" src={refImage02} srcSet={`${refImage02}, ${refImage02} 1x, ${refImage02_2x} 2x`}  alt="Mńam delivery Reference" />
                                </div>
                                <div class="screen-image is-half">
                                <img class="block-image" src={refImage03} srcSet={`${refImage03}, ${refImage03} 1x, ${refImage03_2x} 2x`}  alt="Mńam delivery Reference" />
                                    <img class="block-image" src={refImage04} srcSet={`${refImage04}, ${refImage04} 1x, ${refImage04_2x} 2x`}  alt="Mńam delivery Reference" />
                                    <img class="block-image" src={refImage05} srcSet={`${refImage05}, ${refImage05} 1x, ${refImage05_2x} 2x`}  alt="Mńam delivery Reference" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
