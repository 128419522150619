import React from 'react'
import { Helmet } from "react-helmet";

import FreeConsultation from './components/home/FreeConsultation'
import Header from './components/contact/Header'
import Reviews from './components/home/Reviews'
import Footer from './components/home/Footer'
import Content from './components/contact/content'

import { withTranslation } from "react-i18next"; // Import withTranslation

class pageContact extends React.Component {

  render() {
      return (
          <div className="page-contact">
              <Helmet>
                <title>{this.props.t('pages.contact.pageTitle')} | MATATE</title>
                <meta name="description" content={this.props.t('pages.contact.metaText')} />
              </Helmet>
              <FreeConsultation />
              <Header />
              <Content />
              {/* <Reviews /> */}
              <Footer />
          </div>
        )
  }
}

export default withTranslation()(pageContact);