import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'

import eye from '../../images/references/orava/eye.svg'
import wireframe from '../../images/references/orava/wireframe.svg'
import texty from '../../images/references/orava/texty.svg'

import refImage01 from '../../images/references/orava/screen-01.png'
import refImage01_2x from '../../images/references/orava/screen-01@2x.png'

import refImage02 from '../../images/references/orava/screen-02.png'
import refImage02_2x from '../../images/references/orava/screen-02@2x.png'

import mockupImage from '../../images/references/orava/bg.png'

import card01 from '../../images/references/orava/card-01.png'
import card02 from '../../images/references/orava/card-02.png'

//import points from '../../images/references/medirex/points.png'
//import cursor from '../../images/references/medirex/cursor-hand.png'
//import leftArrow from '../../images/references/medirex/left-arrow.png'
//import rightArrow from '../../images/references/medirex/right-arrow.png'

import mobileScreen from '../../images/references/orava/mobile-screen.png'

import oravavideo from '../../images/references/orava/animation.mp4'

export default class ContentOrava extends Component {

    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $(".back-link").css("opacity", "1");
        }, 1500);
    }

    blockIntro = () => {
        _.delay(function() {
            $(".block--intro").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(0).css("opacity", "1").css("transform", "none");
        }, 400);
        
        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(1).css("opacity", "1").css("transform", "none");
        }, 750);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(2).css("opacity", "1").css("transform", "none");
        }, 950);
    }

    blockCoverImage = () => {
        $(".block--cover-image").find('.overlayer').css("width", "0");
    }

    blockScreen = () => {
        $(".block--screens").find(".block-image").css("opacity", "1").css("transform", "none");
    }

    blockPreviews = () => {

        _.delay(function() {

            let screens = $(".block--previews").find(".block-image");

            $.each(screens, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);
    }

    blockColumns = () => {

        let columnItems = $(".block--columns").find(".services__item");

        _.delay(function() {
            $.each(columnItems, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);

    }

    blockImages = () => {

        _.delay(function() {
            $(".block-images").find(".overlayer").css("width", "0");
        }, 200);

        _.delay(function() {
            $(".block-images").find("img").css("opacity", "1").css("transform", "none");
        }, 1000);
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    
                    <div class="block--intro">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockIntro}
                            bottomOffset="20%"
                        />
                        <h2>Penzión Kuzmínovo má nový web</h2>
                        <p>Tento penzión je naozaj srdcom Oravy, nakoľko sa nachádza v Dolnom Kubíne a vedú odtiaľ cesty ku mnohým turistickým trasám.  Web penziónu už bol zastaralý a vyžadoval nový dych. </p>
                        <p>Pripravili sme dizajn na mieru a impementovali ho na CMS Wordpress pre správu obsahu.</p>
                    </div>

                    <div class="block--boxes">
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={eye} alt="UI UX dizajn" />
                                <h3>UI/UX dizajn</h3>
                            </div>
                            <p>Web dizajn penziónu Kuzmínovo sme navrhli tak, aby prostredníctvom veľkých fotiek a videí komunikoval tie najväčšie benefity, ako je napr. okolitá príroda. </p>
                            <p>Samozrejmosťou je prispôsobenie na mobil, nakoľko z mobilu prichádza väčšia časť návštevníkov webu. </p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={wireframe} alt="Wireframy" />
                                <h3>PROGRAMOVANIE A CMS</h3>
                            </div>
                            <p>Dizajn, ktorý sme navhrli, sme implementovali na CMS Wordpress pre jednoduchú správu obsahu. </p>
                            <p>Vytvorili sme šablónu na mieru, ktorá je optimalizovaná na všetky moderné prehliadače a vyznačuje sa rýchlosťou načítania.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={texty} alt="texty" />
                                <h3>Texty</h3>
                            </div>
                            <p>Pri tomto webe sme sa postarali aj o texty. Copywriterka navrhla príjemné a elegantné textácie, ktoré sa jednoducho čítajú a odovzdávajú presne tie informácie, ktoré potrebujeme.</p>
                        </div>
                    </div>

                    <div className="link-row link-row--topNegative">
                            <a href="https://WWW.KUZMINOVO.SK" target="blank">navštíviť WWW.KUZMINOVO.SK <i className="arrow-next"></i></a>
                    </div>
                </div> 

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    {/* <img src={coverImage}  srcSet={`${coverImage}, ${coverImage} 1x, ${coverImage2x} 2x`}  alt="medirex" />
                    <div class="overlayer"></div> */}

                    <video autoPlay loop muted id="video">
                        <source src={oravavideo} type="video/mp4"></source>
                    </video>
                </div>


                <div className="block--columns">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockColumns}
                            bottomOffset="10%"
                        />
                    <div className="container-block">
                        <div className="services">
                            <div className="services__item columns">
                                <div className="services__image column is-half">
                                   <img class="cg02_main" src={mobileScreen} alt="orava" />
                                </div>
                                <div className="services__image column is-half last">
                                    <img class="cg01" src={card01} alt="orava" />
                                    <img class="cg03" src={card02} alt="orava" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block--screens">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockScreen}
                            bottomOffset="5%"
                        />
                    <img class="block-image" src={mockupImage} alt="Orava delivery Service" />
                </div>

                <div class="block--previews">
                    <div class="container-block"> 
                        <div class="previews-screens">

                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPreviews}
                            />
                            <div class="columns">
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage01} srcSet={`${refImage01}, ${refImage01} 1x, ${refImage01_2x} 2x`}  alt="orava" />
                                </div>
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage02} srcSet={`${refImage02}, ${refImage02} 1x, ${refImage02_2x} 2x`}  alt="orava" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
