import _ from "lodash-es";
/*import React, { useRef } from "react";*/
import Flickity from 'flickity';
import { Waypoint } from "react-waypoint";
import $ from "jquery";

import quotes_mark from "../../images/reviews/quote-inactive.png";
import quotes_mark_active from "../../images/reviews/quote-active.png";

import React from 'react';
import { useTranslation } from 'react-i18next'; 

const OneReview = (props) => {
  
  return (
    <div className={`recenzia recenzia1 ${props.style}`}>
      <div className="columns is-desktop">
        <div className="column">
          <img className="uvodzovky" src={quotes_mark} alt="quote" />
          <img className="uvodzovky is-active" src={quotes_mark_active} alt="quote" />
          <p className="text">
            V rámci medzinárodného projektu TRANSPAREX nám spoločnosť MATATE
            pomohla navrhnúť komplexný dizajn. Ceníme si vlastné inovácie a
            nápady, a zároveň zodpovednú dodávku služieb na kľúč.
          </p>
          {/*<a href="#" className="z_cele">
                        Zobraziť celé
                    </a>*/}
          <div>
            <div>
              <div className="meno">PhDr. Matúš Džuppa, LL.M.</div>
              <div className="veduci">vedúci projektu TRANSPAREX</div>
            </div>
          </div>
        </div>
        {/* <div className="column">
                    <div className="zisky">
                        <div className="zisk0"></div>
                        <div className="zisk1"></div>
                        <div className="zisk2"></div>
                        <div className="zisk3"></div>
                    </div>
                </div> */}
      </div>
    </div>
  );
};

const TwoReview = (props) => {
  return (
    <div className={`recenzia recenzia2 ${props.style}`}>
      <div className="columns is-desktop">
        <div className="column">
          <img className="uvodzovky" src={quotes_mark} alt="quote" />
          <img className="uvodzovky is-active" src={quotes_mark_active} alt="quote" />
          <p className="text">
            Dovolim si len pozitivne hodnotenie napisat. Za kratky cas
            spoluprace sa stihlo dokoncit vela prace. Cize kladne hodnotim -
            promptnost, odbornost, kreativitu, detailnost, ochotu vysvetlit a
            poradit vo vsetkom.
          </p>
          <div>
            <div>
              <div className="meno">Katarína Barošová</div>
              <div className="veduci">DR THEISS Slovensko</div>
            </div>
          </div>
        </div>
        {/* <div className="column">
                    <div className="foto">
                        <img  src={drtheiss} className="animeImg" /> 
                    </div>
                </div> */}
      </div>
    </div>
  );
};

const ThirdReview = (props) => {
  return (
    <div className={`recenzia recenzia2 ${props.style}`}>
      <div className="columns is-desktop">
        <div className="column">
          <img className="uvodzovky" src={quotes_mark} alt="quote" />
          <img className="uvodzovky is-active" src={quotes_mark_active} alt="quote" />
          <p className="text">
            Využil som služby MATATE, keď som potreboval vytvoriť web, upraviť
            iný web a tiež pri PPC reklame. Dávid je spoľahlivý odborník s veľmi
            dobrou komunikáciou. Vždy presne vie ako nájsť správne riešenie.
            Nemusím u neho čakať na odpoveď týždeň a doprosovať sa pomoci.
            Dávida môžem len odporučiť a rád využijem jeho služby aj pri ďalších
            projektoch.
          </p>
          <div>
            <div>
              <div className="meno">Daniel Výcha, </div>
              <div className="veduci">CEO CoolWriters.sk</div>
            </div>
          </div>
        </div>
        {/* <div className="column">
                      <div className="foto">
                          <img  src={drtheiss} className="animeImg" /> 
                      </div>
                  </div> */}
      </div>
    </div>
  );
};


const ReviewsList = (props) => {
  const { t } = useTranslation();
    _.delay(function() {
        new Flickity( '.reviews', {
            freeScroll: true,
            contain: true,
            pageDots: false,
            wrapAround: true,
            freeScrollFriction: 0.03,
            draggable: false
        });
    }, 500);

    const initLoad = () => {
        console.log("lefut");
        $(".line_one ").find('.line_path').addClass("line-anim");
    };

  return (
    <div className="recenzie">
        <Waypoint scrollableAncestor={window} onEnter={initLoad} />
      <svg
                  className="line_one"
                  width="612"
                  height="468"
                  viewBox="0 0 612 468"
                  fill="none"
                >
                  <g className="underly" filter="url(#filter0_f)">
                    <path
                      d="M70.2888 -84.272C121.556 -61.3306 207.844 -50.6576 85.0507 113.82C-68.441 319.417 171.081 553.334 388.43 358.364C605.778 163.395 715.475 272.036 698.3 421.524"
                      stroke="black"
                      strokeWidth="24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M70.2888 -84.272C121.556 -61.3306 207.844 -50.6576 85.0507 113.82C-68.441 319.417 171.081 553.334 388.43 358.364C605.778 163.395 715.475 272.036 698.3 421.524"
                      stroke="url(#paint0_linear)"
                      strokeOpacity="0.3"
                      strokeWidth="24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <path
                    className="line_path"
                    d="M69.4133 -113.594C119.668 -88.5119 205.429 -74.2114 75.8125 84.9441C-86.2088 283.889 143.241 527.694 368.614 342.059C593.987 156.424 699.008 269.592 675.547 418.224"
                    stroke="black"
                    strokeWidth="21"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    className="line_path"
                    d="M69.4133 -113.594C119.668 -88.5119 205.429 -74.2114 75.8125 84.9441C-86.2088 283.889 143.241 527.694 368.614 342.059C593.987 156.424 699.008 269.592 675.547 418.224"
                    stroke="url(#paint1_linear)"
                    strokeWidth="21"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <filter
                      id="filter0_f"
                      x="0.00415039"
                      y="-120.997"
                      width="736.083"
                      height="588.739"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="12"
                        result="effect1_foregroundBlur"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear"
                      x1="621.026"
                      y1="275.428"
                      x2="76.0865"
                      y2="176.55"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#0FA6E1" />
                      <stop offset="0.538272" stopColor="#F3385A" />
                      <stop offset="1" stopColor="#45187A" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear"
                      x1="604.5"
                      y1="269"
                      x2="64.2122"
                      y2="147.241"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#0FA6E1" />
                      <stop offset="0.538272" stopColor="#F3385A" />
                      <stop offset="1" stopColor="#45187A" />
                    </linearGradient>
                  </defs>
                </svg>
      <div className="reveal_wrap ">
        <div
          className="header animation_10"
          style={{ transform: "matrix(1, 0, 0, 1, 0, 180)" }}
        >
            <div>
                <div className="headline">
                    <h2>{t('pageHome.section7.headline')}</h2>
                </div>
                <div className="title">
                    <h2>{t('pageHome.section7.title')}</h2>
                </div>
          </div>
          <div class="buttons">

          </div>
        </div>

        
        {/* <div className="reviews-buttons">
          <div className="swiper-button reviews-prev" onClick={goPrev}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="75.714"
              height="64.927"
              viewBox="0 0 75.714 64.927"
            >
              <path
                id="arrow-left"
                d="M1.61,92.569a5.417,5.417,0,0,0,0,7.656l27.042,27.042a5.414,5.414,0,1,0,7.656-7.656L18.477,101.8H70.33a5.408,5.408,0,1,0,0-10.817H18.494l17.8-17.814a5.414,5.414,0,1,0-7.656-7.656L1.593,92.552Z"
                transform="translate(-0.025 -63.925)"
                fill="#e1e1e1"
              />
            </svg>
          </div>
          <div className="swiper-button reviews-next" onClick={goNext}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="75.714"
              height="64.927"
              viewBox="0 0 75.714 64.927"
            >
              <path
                id="arrow-right"
                d="M74.154,92.569a5.417,5.417,0,0,1,0,7.656L47.112,127.267a5.414,5.414,0,0,1-7.656-7.656L57.287,101.8H5.433a5.408,5.408,0,1,1,0-10.817H57.27l-17.8-17.814a5.414,5.414,0,0,1,7.656-7.656L74.171,92.552Z"
                transform="translate(-0.025 -63.925)"
                fill="#e1e1e1"
              />
            </svg>
          </div>
        </div> */}
      </div>

      {/* <Swiper
        ref={ref}
        modules={[Navigation]}
        spaceBetween={15}
        slidesPerView={1.2}
        watchSlidesProgress={true}
        slidesOffsetBefore={10}
        slidesOffsetAfter={50}
        loop={true}
        navigation={{
          nextEl: ".reviews-next",
          prevEl: ".reviews-prev",
        }}
        breakpoints={{
          640: {
            slidesPerView: 1.2,
          },
          1280: {
            slidesPerView: 1.9,
            slidesOffsetBefore:300,
            slidesOffsetAfter:300,
            spaceBetween:30,
            cssMode: false
          },
        }}
      >
        <SwiperSlide>
          <OneReview />
        </SwiperSlide>
        <SwiperSlide>
          <TwoReview />
        </SwiperSlide>
        <SwiperSlide>
          <ThirdReview />
        </SwiperSlide>
      </Swiper> */}

      <div className="cursor_hover1 carousel reviews" datahover="Ťahať">
            <div className="carousel-cell is-selected" style={{position: "absolute; left: 0%"}}>
                <OneReview />
            </div>
            <div className="carousel-cell" style={{position: "absolute; left: 0%"}}>
                <TwoReview />
            </div>
            <div className="carousel-cell" style={{position: "absolute; left: 0%"}}>
                <ThirdReview />
            </div>
            <div className="carousel-cell" style={{position: "absolute; left: 0%"}}>
                <OneReview />
            </div>
            <div className="carousel-cell" style={{position: "absolute; left: 0%"}}>
                <TwoReview />
            </div>
            <div className="carousel-cell" style={{position: "absolute; left: 0%"}}>
                <ThirdReview />
            </div>
        </div>

      {/* <OneReview style={`${props.actual == 1 ? "active" : " "}`} />
      <TwoReview style={`${props.actual == 2 ? "active" : " "}`} /> */}
    </div>
  );
};

export default ReviewsList;
