import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./home";
import AboutUs from "./aboutus";
import Contact from "./pageContact";
import References from "./pages/reference";
import Blog from "./blog";
import Article from "./components/blog/article";
// admin
//import AdminLogin from "./components/admin/Login";
//import AdminCreatePost from "./components/admin/CreatePost";

// Services URLs
import Services from "./services";
import ServiceWeb from "./serviceWeb";
import ServiceUx from "./serviceUx";
import ServiceAnalytika from "./serviceAnalytika";
import ServiceSeo from "./serviceSeo";
import ServiceMarketing from "./serviceMarketing";
import ServiceManagement from "./serviceManagement";
import ServiceCookies from "./serviceCookies";

// References URLs
import Transparex from "./pages/references/transparex";
import Aldona from "./pages/references/aldona";
import Kastiel from "./pages/references/kastiel";
import Mnam from "./pages/references/mnam";
import Giganet from "./pages/references/giganet";
import Nadmerka from "./pages/references/nadmerka";
import Medirex from "./pages/references/medirex";
import Drtheiss from "./pages/references/drtheiss";
import Orava from "./pages/references/orava";
import Mirano from "./pages/references/mirano";
import Exsotrans from "./pages/references/exsotrans";
import Casopis from "./pages/references/casopis";
import Jankadera from "./pages/references/jankadera";

import reportWebVitals from "./reportWebVitals";

import "bulma/css/bulma.css";
import "./scss/base.scss";

//Services
import { ApolloProvider } from "@apollo/client";
import client from "./services/apolo";

import { I18nextProvider } from "react-i18next";
import i18n from './i18n';
//import i18next from "i18next";

import ReactGA from "react-ga4";
//import { BlogProvider } from './BlogContext';
ReactGA.initialize("G-CFBKMXPCWG");

// setup gtm
// const tagManagerArgs = {
//     gtmId: 'G-CFBKMXPCWG'
// }
 
// TagManager.initialize(tagManagerArgs)

ReactDOM.render(
  <React.StrictMode>
   <I18nextProvider i18n={i18n}>
    <ApolloProvider client={client}>
      <Router forceRefresh>
        <Switch>
          {/* <Route path="/admin">
                <AdminLogin />
            </Route>
            <Route path="/admin/createpost">
                <AdminCreatePost />
            </Route> */}

          <Route
            path="/blog/:id"
            render={({ match }) => <Article id={match.params.id} />}
          />
          <Route path={i18n.t('blog', {ns:'routes'})}>
            <Blog />
          </Route>
          <Route path={i18n.t('reference.janKadera', {ns:'routes'})}>
            <Jankadera />
          </Route>
          <Route path="/referencie/casopis">
            <Casopis />
          </Route>
          <Route path={i18n.t('reference.mirano', {ns:'routes'})}>
            <Mirano />
          </Route>
          <Route path={i18n.t('reference.exsotrans', {ns:'routes'})}>
            <Exsotrans />
          </Route>
          <Route path={i18n.t('reference.penzion', {ns:'routes'})}>
            <Orava />
          </Route>
          <Route path={i18n.t('reference.drtheiss', {ns:'routes'})}>
            <Drtheiss />
          </Route>
          <Route path={i18n.t('reference.transparex', {ns:'routes'})}>
            <Transparex />
          </Route>
          <Route path={i18n.t('reference.aldona', {ns:'routes'})}>
            <Aldona />
          </Route>
          <Route path={i18n.t('reference.kastiel', {ns:'routes'})}>
            <Kastiel />
          </Route>
          <Route path={i18n.t('reference.mnam', {ns:'routes'})}>
            <Mnam />
          </Route>
          <Route path={i18n.t('reference.giganet', {ns:'routes'})}>
            <Giganet />
          </Route>
          <Route path={i18n.t('reference.nadmerka', {ns:'routes'})}>
            <Nadmerka />
          </Route>
          <Route path={i18n.t('reference.medirex', {ns:'routes'})}>
            <Medirex />
          </Route>
          <Route path={i18n.t('reference.index', {ns:'routes'})}>
            <References />
          </Route>
          <Route path={i18n.t('service.cookies', {ns:'routes'})}>
            <ServiceCookies />
          </Route>
          <Route path={i18n.t('service.web', {ns:'routes'})}>
            <ServiceWeb />
          </Route>
          <Route path={i18n.t('service.uxOnTheWeb', {ns:'routes'})}>
            <ServiceUx />
          </Route>
          <Route path={i18n.t('service.webAnalytics', {ns:'routes'})}>
            <ServiceAnalytika />
          </Route>
          <Route path={i18n.t('service.seo', {ns:'routes'})}>
            <ServiceSeo />
          </Route>
          <Route path={i18n.t('service.onlineMarketing', {ns:'routes'})}>
            <ServiceMarketing />
          </Route>
          <Route path={i18n.t('service.management', {ns:'routes'})}>
            <ServiceManagement />
          </Route>
          <Route path={i18n.t('service.index', {ns:'routes'})}>
            <Services />
          </Route>
          <Route path={i18n.t('aboutUs', {ns:'routes'})}>
            <AboutUs />
          </Route>
            <Route path={i18n.t('contact', {ns:'routes'})}>
                <Contact />
          </Route>
          <Route path={i18n.t('home', {ns:'routes'})}>
            <Home />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
