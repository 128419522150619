import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'
import { withTranslation } from "react-i18next"; // Import withTranslation
// import i18n from 'i18next';

import globe from '../../images/aboutus/globe.svg'
import stars from '../../images/aboutus/stars.svg'
import wallet from '../../images/aboutus/wallet.svg'

class Aboutus extends Component {

    aboutus_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $('#section3 .aboutus_line .line').css('transition','stroke-dashoffset 5s ease').css('stroke-dashoffset', '5600px');
        }, 200);
        _.delay(function() {
            $('.section3 .AU_animation3').css('transform', 'translateX(0px)')
        }, 500);
        _.delay(function() {
            $('.section3 .AU_animation4').css('transform', 'translateY(0px)')
        }, 800);
        _.delay(function() {
            $('.section3 .AU_animation5').css('transform', 'translateY(0px)')
        }, 1000);
        _.delay(function() {
            $('.section3 .AU_animation6').css('transform', 'translateY(0px)')
        }, 1200);

    }

    render() {
        const { t } = this.props; // Access t from props

        return (
            <div id="section3" className="section3">
                <Waypoint
                    scrollableAncestor={window}
                    onEnter={this.aboutus_load}
                />
                <div className="AU_animation3">
                    <div className="aboutus-container">
                        <div className="">
                            <div className="service">{t('pageOnas.aboutUs.title1')}</div>
                            <div className="text">
                                <h1>{t('pageOnas.aboutUs.title2')}</h1>
                            </div>
                        </div>
                    </div>
                    <div className="first-p">
                        <p>{t('pageOnas.aboutUs.sections.section1.content')}</p>
                    </div>
                    <div className="second-p">
                        <p>{t('pageOnas.aboutUs.sections.section2.content')}</p>
                    </div>
                    <div className="heading-what">
                        <h3>{t('pageOnas.aboutUs.sections.section3.title')}</h3>
                    </div>
                </div>
                <div className="box-container reveal_wrap">
                    <div className="boxes AU_animation4">
                        <div className="icon">
                            <img src={globe} alt="." />
                        </div>
                        <div className="text">
                            {t('pageOnas.aboutUs.boxes.box1.text')}
                        </div>
                    </div>
                    <div className="boxes AU_animation5">
                        <div className="icon">
                            <img src={stars} alt="." />
                        </div>
                        <div className="text">
                            {t('pageOnas.aboutUs.boxes.box2.text')}
                        </div>
                    </div>
                    <div className="boxes AU_animation6">
                        <div className="icon">
                            <img src={wallet} alt="." />
                        </div>
                        <div className="text">
                            {t('pageOnas.aboutUs.boxes.box3.text')}
                        </div>
                    </div>
                </div>
                <svg className="aboutus_line" width="732" height="2690" viewBox="0 0 732 2690" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip01)">
                        <g filter="url(#filter0_f1)">
                            <path d="M964.488 2417.18L948.192 2455.59C526.408 2553.29 299.348 1880.12 803.888 1518.81C1308.43 1157.49 1201.09 656.192 1707.25 374.523C1881.24 277.7 2177.11 179.732 2577.16 455.772" stroke="url(#paint0_linear1)" stroke-opacity="0.3" stroke-width="24" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <path className="line" d="M945.443 2394.06L929.147 2432.46C507.363 2530.17 280.303 1857 784.844 1495.68C1289.38 1134.37 1182.05 633.069 1688.21 351.4C1862.2 254.577 2158.07 156.608 2558.11 432.649" stroke="url(#paint1_linear1)" stroke-width="21" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <filter id="filter0_f1" x="478.869" y="239.608" width="2134.63" height="2261.21" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                    <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur"/>
                    </filter>
                    <linearGradient id="paint0_linear1" x1="817.147" y1="2041.72" x2="908.973" y2="1109.41" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#03B6F2"/>
                    <stop offset="0.473958" stop-color="#F3385A"/>
                    <stop offset="0.989583" stop-color="#772390"/>
                    </linearGradient>
                    <linearGradient id="paint1_linear1" x1="798.102" y1="2018.6" x2="889.928" y2="1086.29" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#03B6F2"/>
                    <stop offset="0.473958" stop-color="#F3385A"/>
                    <stop offset="0.989583" stop-color="#772390"/>
                    </linearGradient>
                    <clipPath id="clip01">
                    <rect width="2895.22" height="1066.27" fill="white" transform="matrix(-0.707107 0.707107 0.707107 0.707107 2047.23 -111.673)"/>
                    </clipPath>
                    </defs>
                </svg>
            </div>
        )
    }
}

export default withTranslation()(Aboutus);
