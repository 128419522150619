import React from 'react'
import { Helmet } from "react-helmet";

// glboal components
import Footer from '../../components/home/Footer'

// hero specifiy components 
import HeroItems from '../../components/reference/HeroItems'
import ReferenceCasopis from '../../components/reference/ContentCasopis'
import Header from '../../components/reference/Header'
import Contact from '../../components/reference/Contact'
import Portfolio from '../../components/home/Portfolio'

import FreeConsultation from '../../components/home/FreeConsultation'

//import Notifications, { notify } from 'react-notify-toast';

class Casopis extends  React.Component {
  constructor(){
    super();

    this.state = {
        hasPortfolio: true, // portfolio on/off
        subtitle: "ČASOPIS PROJEKT",
        title: "Minimalistický web pre architektonický časopis",
        hashTags: [
            "#2022",
            "#UI/UX dizajn",
            "#WEB" 
        ]
    }
  }

  render() {
      return (
          <div className="referenceView casopis">
              <Helmet>
                <title>Časopis projekt | Referencie | MATATE</title>
                <meta name="description" content="Pre architektonický časopis Projekt, ktorý vychádza už od roku 1959, sme vytvorili úplne nový web s možnosťou prehliadania ukážok časopisu online." />
              </Helmet>
              <FreeConsultation />
              <Header />
              <HeroItems subtitle={this.state.subtitle} title={this.state.title} hashTags={this.state.hashTags} />
              <ReferenceCasopis />
              <Portfolio status={this.state.hasPortfolio} />
              <Contact />
              <Footer />
          </div>
        )
    }
}

export default Casopis;
