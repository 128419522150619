import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'
import Parallax from 'react-rellax'

import eye from '../../images/references/aldona/eye.svg'
import union from '../../images/references/aldona/union.svg'
import wireframe from '../../images/references/aldona/wireframe.svg'

import coverImage from '../../images/references/aldona/aldona-screen.png'
import coverImage2x from '../../images/references/aldona/aldona-screen@2x.png'

import logoGraphic from '../../images/references/aldona/aldona-logo.png'
import logoGraphic2x from '../../images/references/aldona/aldona-logo@2x.png'

import manual01 from '../../images/references/aldona/logo-manual-01.png'
import manual01x2 from '../../images/references/aldona/logo-manual-01@2x.png'

import manual02 from '../../images/references/aldona/logo-manual-02.png'
import manual02x2 from '../../images/references/aldona/logo-manual-02@2x.png'

import refImage01 from '../../images/references/aldona/screen-01.png'
import refImage01_2x from '../../images/references/aldona/screen-01@2x.png'

import refImage02 from '../../images/references/aldona/screen-02.png'
import refImage02_2x from '../../images/references/aldona/screen-02@2x.png'

import refImage03 from '../../images/references/aldona/screen-03.png'
import refImage03_2x from '../../images/references/aldona/screen-03@2x.png'

import columnImage01 from '../../images/references/aldona/forklift.svg'

import respoDizajn01 from '../../images/references/aldona/respo-dizajn-item-01.png'
import respoDizajn02 from '../../images/references/aldona/respo-dizajn-item-02.png'
import respoDizajn03 from '../../images/references/aldona/respo-dizajn-item-03.png'

import respoDizajnDecor01 from '../../images/references/aldona/respo-dizajn-decor-01.png'
import respoDizajnDecor02 from '../../images/references/aldona/respo-dizajn-decor-02.png'


export default class ContentAldona extends Component {

    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $(".back-link").css("opacity", "1");
        }, 1500);

    }

    blockIntro = () => {
        _.delay(function() {
            $(".block--intro").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(0).css("opacity", "1").css("transform", "none");
        }, 400);
        
        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(1).css("opacity", "1").css("transform", "none");
        }, 750);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(2).css("opacity", "1").css("transform", "none");
        }, 950);
    }

    blockCoverImage = () => {
        $(".block--cover-image").find('.overlayer').css("width", "0");
    }

    blockLogo = () => {

        _.delay(function() {
            $(".block-top").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--logo").find(".block-image").css("opacity", "1").css("transform", "none");
        }, 500);
    }

    blockPack = () => {
        _.delay(function() {
            $(".block--image-pack").find(".overlayer").css("width", "0");
        }, 1000);
    }

    blockScreen = () => {
        $(".block--screens").find(".block-image").css("opacity", "1").css("transform", "none");
    }

    blockPreviews = () => {

        _.delay(function() {

            let screens = $(".block--previews").find(".block-image");

            $.each(screens, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);
    }

    blockColumns = () => {

        let columnItems = $(".block--columns").find(".services__item");

        _.delay(function() {
            $.each(columnItems, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);

    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    
                    <div class="block--intro">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockIntro}
                            bottomOffset="20%"
                        />
                        <h2>Originálny web s ilustráciami</h2>
                        <p>Pre špedičnú spoločnosť ALDONA sme pripravili krásnu animovanú webovú stránku s dizajnom a ilustráciami na mieru. Okrem toho sme riešili logo a marketingovú HR kampaň.</p>

                        <p>Táto spoločnosť mala záujem o web stránku, ktorá bude niečím originálna a zaujímavá. Preto sme po spoločnej dohode zvolili cestu ilustrovaného webu.</p> 

                        <p>Celá hlavička webstránky je digitálne nakreslená na mieru pre klienta. K ilustráciám sme pridali animácie, čo ešte viac obzvláštnilo web.</p>

                    </div>


                    {/* <div>
                        <img class="brand-logo-mobile" src={logoMobile} alt="Aldona" />
                    </div> */}

                    <div class="block--boxes">
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={eye} alt="DIZAJN A ILUSTRÁCIE" />
                                <h3>DIZAJN A ILUSTRÁCIE</h3>
                            </div>
                            <p>Na základe zadania a dohody s klientom sme pripravili originálny webový dizajn na mieru spoločnosti. </p><br/>
                            <p>Ako môžete vidieť nižšie na dizajnoch nižšie,  na webe sa nachádzajú animované ilustrácie, ktoré boli digitálne nakreslené na mieru.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={union} alt="Logo a dizajn manuál" />
                                <h3>LOGO A DIZAJN MANUÁL</h3>
                            </div>
                            <p>Spoločnosť ALDONA potrebovala aj refresh loga a preto sme pripravili nové logo a dizajn manuál, ktorý hovorí o správnom použití značky v rôznych situáciách. Zadefinovali sme farby, fonty a merkantil. Pripravili sme šablónu mailový podpis či vizitky.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={wireframe} alt="PROGRAMOVANIE A CMS" />
                                <h3>PROGRAMOVANIE A CMS</h3>
                            </div>
                            <p>Webovú stránku sme zastrešovali komplet na mieru a mali sme v rukách aj implementáciu dizajnu. Znamená to, že sme web naprogramovali a nasadili na CMS pre správu obsahu. Web sme naprogramovali tak, aby bol rýchly, optimalizovaný na všetky zariadenia a využili sme aj mikro animácie.</p>
                        </div>
                    </div>

                    <div className="link-row link-row--topNegative">
                            <a href="https://www.aldona.sk/" target="blank">navštíviť WWW.ALDONA.SK <i className="arrow-next"></i></a>
                        </div>
                </div> 

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                    <img src={coverImage}  srcSet={`${coverImage}, ${coverImage} 1x, ${coverImage2x} 2x`}  alt="Aldona" />
                    <div class="overlayer"></div>
                </div>

                <div class="container-block"> 
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockLogo}
                        />
                    <div class="block--logo">
                        <div class="block-top">
                            <h2 class="block-title">Logo</h2>
                            <p class="block-text">Logo je základným prvkom vizuálnej prezentácie
značky. Preto treba dbať na správne používanie pre
profesionálny vzhľad komunikačných a firemných
materiálov.</p>
                        </div>
                        <img class="block-image" src={logoGraphic} srcSet={`${logoGraphic}, ${logoGraphic} 1x, ${logoGraphic2x} 2x`}  alt="Aldona" />
                    </div>
                </div>

                <div class="container-block"> 
                    <div class="block--image-pack">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockPack}
                            bottomOffset="10%"
                        />
                        <img class="block-image" src={manual01} srcSet={`${manual01}, ${manual01} 1x, ${manual01x2} 2x`}  alt="Aldona" />
                        <div class="overlayer"></div>
                    </div>
                </div>

                <div class="container-block"> 
                    <div class="block--image-pack">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockPack}
                            bottomOffset="10%"
                        />
                        <img class="block-image" src={manual02} srcSet={`${manual02}, ${manual02} 1x, ${manual02x2} 2x`}  alt="Aldona" />
                        <div class="overlayer"></div>
                    </div>
                </div>

                <div className="block--columns">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockColumns}
                            bottomOffset="10%"
                        />
                    <div className="container-block">
                        <div className="services">
                            <div className="services__item columns">
                                <div className="services__col column is-half">
                                    <h2>Vyšperkované animácie pripravené na mieru</h2>
                                    <p>Pre klienta sme pripravili animácie na mieru. Tieto sa vyskytujú na celom webe a na rôznych miestach a dopĺňajú tak statický dizajn o dynamické prvky.</p>
                                </div>
                                <div className="services__image column is-half">
                                    <img src={columnImage01} alt="Aldona Service" />
                                </div>
                            </div>
                            <div className="services__item columns"> 
                                <div className="services__image column is-half">
                                    <img className="imgRef01" src={respoDizajn01}  alt="Aldona Service 1" />
                                    <img className="imgRef02" src={respoDizajn02}  alt="Aldona Service 2" />
                                    <Parallax className="imgRef03" speed={-0.5}>
                                        <img src={respoDizajn03}  alt="Aldona Service 3" />
                                    </Parallax>

                                    <img className="imgDecor01" src={respoDizajnDecor01}  alt="Aldona Service 1" />
                                    <img className="imgDecor02" src={respoDizajnDecor02}  alt="Aldona Service 2" />
                                </div>
                                <div className="services__col column is-half">
                                    <h2>Responzívny dizajn máme v rukách</h2>
                                    <p>V dnešnej dobe čoraz viac návštevníci prehliadajú webové stránky cez mobil (v priemere viac ako 70% oproti desktopu).</p>
                                    <p>Preto všetky webové stránky pripravujeme tak, aby boli responzívne, tj. prispôsobené na prehliadanie na všetkých zariadeniach.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block--previews">
                    <div class="container-block"> 
                        <div class="previews-screens">

                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPreviews}
                            />
                            <div class="columns">
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage01} srcSet={`${refImage01}, ${refImage01} 1x, ${refImage01_2x} 2x`}  alt="Aldona Reference" />
                                </div>
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage02} srcSet={`${refImage02}, ${refImage02} 1x, ${refImage02_2x} 2x`}  alt="Aldona Reference" />
                                    <img class="block-image" src={refImage03} srcSet={`${refImage03}, ${refImage03} 1x, ${refImage03_2x} 2x`}  alt="Aldona Reference" />
                                </div>
                            </div>

                        </div>

                        <div className="link-row link-row--topMore mb-0">
                            <a href="https://www.aldona.sk/" target="blank">navštíviť WWW.ALDONA.SK <i className="arrow-next"></i></a>
                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
