import React, { Component, useState } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'

import { withTranslation,Trans} from "react-i18next"; // Import withTranslation

//import i18n from 'i18next';

import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

import reference01 from '../../images/references/main-references/kastiel-palffy.png'
import reference01_2x from '../../images/references/main-references/kastiel-palffy@2x.png'

import reference02 from '../../images/references/main-references/aldona.png'
import reference02_2x from '../../images/references/main-references/aldona@2x.png'

import reference04 from '../../images/references/main-references/giganet.png'
import reference04_2x from '../../images/references/main-references/giganet@2x.png'

import reference05 from '../../images/references/main-references/nadmerka.png'
import reference05_2x from '../../images/references/main-references/nadmerka@2x.png'

import reference06 from '../../images/references/main-references/transparex.png'
import reference06_2x from '../../images/references/main-references/transparex@2x.png'

import reference07 from '../../images/references/main-references/medirex.png'
import reference07_2x from '../../images/references/main-references/medirex@2x.png'

import reference08 from '../../images/references/main-references/mnam-delivery.png'
import reference08_2x from '../../images/references/main-references/mnam-delivery@2x.png'

import reference09 from "../../images/slider/theiss.png";
import reference09_2x from "../../images/slider/theiss@2x.png";
import reference10 from "../../images/slider/penzion.png";
import reference10_2x from "../../images/slider/penzion@2x.png";

// mirano
import reference11 from "../../images/references/main-references/mirano.png";
import reference11_2x from "../../images/references/main-references/mirano@2x.png";

// exsotrans
import reference12 from "../../images/references/main-references/exotrans.png";
import reference12_2x from "../../images/references/main-references/exotrans@2x.png";

import reference13 from '../../images/references/main-references/casopis.png';
import reference13_2x from '../../images/references/main-references/casopis@2x.png';

import reference14 from '../../images/references/main-references/jan-kadera.png';
import reference14_2x from '../../images/references/main-references/jan-kadera@2x.png';

/* filtering item */
const filtersDefault = [
    { label: "Všetko", class: "all" },
    { label: "Web", class: "web" },
    { label: "Marketing", class: "marketing" },
    { label: "Aplikácie", class: "application" }
];

class ReferenceList extends Component {

    constructor(props) {
        super(props);
        //const [filters, updateFilters] = useState(filtersDefault);
        this.state = {
            filters: filtersDefault
        };
    }

    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');
    }

    heroLoad = () => {
        _.delay(function() {
             $(".hero__toptitle").css("transform", "none").css("opacity", '1')
        }, 100);

        _.delay(function() {
               $(".hero__title").css("transform", "none").css("opacity", '1')
               $(".reference-filter").find(".line").css("width", "100%");
        }, 500);

        _.delay(function() {
               $(".reference-filter").find("li").css("opacity", '1')
        }, 700);

        _.delay(function() {
               $(".references-list").css("transform", "none").css("opacity", '1')
        }, 1000);
    }

    lineLoad = () => {
         $('.glow-line .glow-path').css('transition','stroke-dashoffset 5s ease').css('stroke-dashoffset', '5600px');
    }

    filtering = (event) => {
        $("html, body").animate({ scrollTop: 0}, 500);

        $(".references-list").fadeOut();

        const $target = $(event.target);
        $(".reference-filter").find('li').removeClass("is-active");
        $target.addClass("is-active");

        let getClass = $target.data("target");

        setTimeout(function() {
                
            if (getClass == "all") {
                $(".references-list__item").show();
            } else {
                $(".references-list__item").hide();
                $(".references-list__item."+getClass).show();
            }

            $(".references-list").fadeIn();

        }, 1000);
    }

    componentDidMount= () => {
        window.addEventListener('scroll', this.handleScroll);
    }
    
    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        console.log(event);
        let scrollTopY = window.scrollY;

        if (scrollTopY > 1000) {
            $(".reference-filter").addClass("is-fixed");
        } else {
            $(".reference-filter").removeClass("is-fixed");
        }
    }

    render() {
        return (
            <section className="content">
                    <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.heroLoad}
                    />
                <div className="hero--reference">
                    <div className="container-block"> 
                        <div className="hero__toptitle">{this.props.t('references.hero.topTitle')}</div>
                        <h1 className="hero__title">{this.props.t('references.hero.title')}</h1>
                    </div>
                </div>

                <div class="reference-filter">
                    <span class="line"></span>
                     <SimpleBar forceVisible="x" autoHide={true}>
                        <ul>
                            <li class="is-active" data-target="all" onClick={this.filtering}>{this.props.t('references.filters.all')}</li>
                            <li data-target="web" onClick={this.filtering}>{this.props.t('references.filters.web')}</li>
                            <li data-target="marketing" onClick={this.filtering}>{this.props.t('references.filters.marketing')}</li>
                        </ul>
                    </SimpleBar>
                </div>

                <div className="container-block"> 
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.lineLoad}
                            bottomOffset="60%"
                        />
                    <div class="glow-line-wrapper">
                        <svg class="glow-line" width="263" height="870" viewBox="0 0 263 870" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path class="glow-path" d="M-161.804 828.67C-178.444 775.026 -227.428 703.191 -22.8303 686.739C232.917 666.173 250.325 331.83 -39.6242 297.433C-329.573 263.036 -320.466 108.915 -196.887 23.0662" stroke="black" stroke-width="21" stroke-linecap="round" stroke-linejoin="round"/>
                            <path class="glow-path" d="M-161.804 828.67C-178.444 775.026 -227.428 703.191 -22.8303 686.739C232.917 666.173 250.325 331.83 -39.6242 297.433C-329.573 263.036 -320.466 108.915 -196.887 23.0662" stroke="url(#paint0_linear)" stroke-width="21" stroke-linecap="round" stroke-linejoin="round"/>
                            <defs>
                            <linearGradient id="paint0_linear" x1="-255.532" y1="177.586" x2="30.1878" y2="652.033" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#0FA6E1"/>
                            <stop offset="0.538272" stop-color="#F3385A"/>
                            <stop offset="1" stop-color="#45187A"/>
                            </linearGradient>
                            </defs>
                        </svg>
                    </div>

                    <div class="references-list">
                    
                            <div class="references-list__item columns web marketing">
                                <div class="column is-half">
                                   <a hreflang={this.props.i18n.language} href={this.props.t("reference.drtheiss", {ns:'routes'})} >
                                        <div class="img-wrapper">
                                            <img src={reference09} srcSet={`${reference09}, ${reference09} 1x, ${reference09_2x} 2x`}  alt="DR THEISS" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>
                                <div class="column is-half column--text">
                                    <div class="item-hashtags">
                                        <span>{this.props.t('references.sections.section1.hashtags.tag1')}</span>
                                        <span>{this.props.t('references.sections.section1.hashtags.tag2')}</span>
                                        <span>{this.props.t('references.sections.section1.hashtags.tag3')}</span> 
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.drtheiss", {ns:'routes'})}>{this.props.t('references.sections.section1.title')}</a></h2>
                                    <p class="item-text">{this.props.t('references.sections.section1.description')}</p>
                                    <a hreflang={this.props.i18n.language} class="item-link" href={this.props.t("reference.drtheiss", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div>
                            </div>

                            <div class="references-list__item columns web marketing">
                                <div class="column is-half column--text">
                                    <div class="item-hashtags">
                                        <span>{this.props.t('references.sections.section2.hashtags.tag1')}</span>
                                        <span>{this.props.t('references.sections.section2.hashtags.tag2')}</span>
                                        <span>{this.props.t('references.sections.section2.hashtags.tag3')}</span> 
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.mirano", {ns:'routes'})}>{this.props.t('references.sections.section2.title')}</a></h2>
                                    <p class="item-text">{this.props.t('references.sections.section2.description')}</p>
                                    <a hreflang={this.props.i18n.language} class="item-link" href={this.props.t("reference.mirano", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div>
                                <div class="column is-half">
                                   <a hreflang={this.props.i18n.language} href={this.props.t("reference.mirano", {ns:'routes'})}>
                                        <div class="img-wrapper">
                                            <img src={reference11} srcSet={`${reference11}, ${reference11} 1x, ${reference11_2x} 2x`}  alt="mirano" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>

                            </div>

                            <div class="references-list__item columns web marketing">
                                <div class="column is-half">
                                    <a hreflang={this.props.i18n.language} href={this.props.t("reference.giganet", {ns:'routes'})}>
                                        <div class="img-wrapper">
                                            <img src={reference04} srcSet={`${reference04}, ${reference04} 1x, ${reference04_2x} 2x`}  alt="Giganet" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>
                                <div class="column is-half column--text">
                                    <div class="item-hashtags">
                                        <span>{this.props.t('references.sections.section3.hashtags.tag1')}</span>
                                        <span>{this.props.t('references.sections.section3.hashtags.tag2')}</span>
                                        <span>{this.props.t('references.sections.section3.hashtags.tag3')}</span>
                                        <span>{this.props.t('references.sections.section3.hashtags.tag4')}</span> 
                                        <span>{this.props.t('references.sections.section3.hashtags.tag5')}</span> 
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.giganet", {ns:'routes'})}>{this.props.t('references.sections.section3.title')}</a></h2>
                                    <p class="item-text">{this.props.t('references.sections.section3.description')}</p>
                                    <a hreflang={this.props.i18n.language} class="item-link" href={this.props.t("reference.giganet", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div>
                            </div>

                            
                            <div class="references-list__item columns web">
                                <div class="column is-half column--text ux">
                                    <div class="item-hashtags">
                                        <span>{this.props.t('references.sections.section4.hashtags.tag1')}</span>
                                        <span>{this.props.t('references.sections.section4.hashtags.tag2')}</span>
                                        <span>{this.props.t('references.sections.section4.hashtags.tag3')}</span>
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.exsotrans", {ns:'routes'})}>{this.props.t('references.sections.section4.title')}</a></h2>
                                    <p class="item-text">{this.props.t('references.sections.section4.description1')}</p>
                                    <p class="item-text">{this.props.t('references.sections.section4.description2')}</p>

                                    <a hreflang={this.props.i18n.language} class="item-link" href={this.props.t("reference.exsotrans", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div> 
                                <div class="column is-half">
                                    <a hreflang={this.props.i18n.language} href={this.props.t("reference.exsotrans", {ns:'routes'})}>
                                        <div class="img-wrapper">
                                            <img src={reference12}  srcSet={`${reference12}, ${reference12} 1x, ${reference12_2x} 2x`}  alt="exsotrans" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div class="references-list__item columns web">

                                <div class="column is-half">
                                    <a hreflang={this.props.i18n.language} href={this.props.t("reference.transparex", {ns:'routes'})}>
                                        <div class="img-wrapper">
                                            <img src={reference06}  srcSet={`${reference06}, ${reference06} 1x, ${reference06_2x} 2x`}  alt="Transparex" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>

                                <div class="column is-half column--text ux">
                                    <div class="item-hashtags">
                                        <span>#web</span>
                                        <span>#logotyp</span>
                                        <span>#ux</span>
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.transparex", {ns:'routes'})}>Transparex</a></h2>
                                    <p class="item-text">Transparex je projekt zaoberajúci sa firemnými dátami, verejným obstarávaním a vyhodnocovaním transparentnosti spoločností. Pre tento projekt sme zabezpečili kompletný dizajn webu na mieru, riešili sme UX a tiež sme vypracovali logo a dizajn manuál.</p>
                                    <a hreflang={this.props.i18n.language} class="item-link" href={this.props.t("reference.transparex", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div>
                            </div>

                            <div class="references-list__item columns web">
                                <div class="column is-half column--text">
                                    <div class="item-hashtags">
                                        <span>#web</span>
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.nadmerka", {ns:'routes'})}>Nadmerka</a></h2>
                                    <p class="item-text">Pre obchod s nadmernou obuvou sme pripravili nový dizajn e-shopu a dostali sme tak web stránku do modernejšieho šatu.</p>
                                    <a hreflang={this.props.i18n.language} class="item-link" href={this.props.t("reference.nadmerka", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div>
                                <div class="column is-half">
                                   <a hreflang={this.props.i18n.language} href={this.props.t("reference.nadmerka", {ns:'routes'})}>
                                        <div class="img-wrapper">
                                            <img src={reference05} srcSet={`${reference05}, ${reference05} 1x, ${reference05_2x} 2x`}  alt="Nadmerka" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            
                            <div class="references-list__item columns web">
                                <div class="column is-half">
                                    <a hreflang={this.props.i18n.language} href={this.props.t("reference.kastiel", {ns:'routes'})}>
                                        <div class="img-wrapper">
                                            <img src={reference01}  srcSet={`${reference01}, ${reference01} 1x, ${reference01_2x} 2x`}  alt="Kaštiel Pálffy" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>
                                <div class="column is-half column--text">
                                    <div class="item-hashtags">
                                        <span>#web</span>
                                        <span>#3D</span>
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.kastiel", {ns:'routes'})}>Kaštiel Pálffy</a></h2>
                                    <p class="item-text">Impozantný Kaštieľ Pálffy vo Svätom Jure od nás dostal elegantnú webovú stránku. Zastrešili sme dizajn aj programovanie. Okrem toho sme pripravili 3D kresby kaštieľa a mapy priestorov.</p>
                                    <a hreflang={this.props.i18n.language} class="item-link" href={this.props.t("reference.kastiel", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div>
                            </div>

                            <div class="references-list__item columns web marketing">
                                <div class="column is-half column--text">
                                    <div class="item-hashtags">
                                        <span>#web</span>
                                        <span>#logotyp</span>
                                        <span>#animacie</span>
                                        <span>#ilustracie</span>
                                        <span>#marketing</span>
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.aldona", {ns:'routes'})}>Aldona</a></h2>
                                    <p class="item-text">Pre špedičnú spoločnosť ALDONA sme pripravili krásnu animovanú webovú stránku s dizajnom a ilustráciami na mieru. Okrem toho sme riešili logo a marketingovú HR kampaň.</p>
                                    <a hreflang={this.props.i18n.language} class="item-link" href={this.props.t("reference.aldona", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div>
                                <div class="column is-half">
                                    <a hreflang={this.props.i18n.language} href={this.props.t("reference.aldona", {ns:'routes'})}>
                                        <div class="img-wrapper">
                                            <img src={reference02}  srcSet={`${reference02}, ${reference02} 1x, ${reference02_2x} 2x`}  alt="Aldona" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>
                            </div>


                            {/* <div class="references-list__item columns web marketing">
                                <div class="column is-half">
                                    <a href="/referencie/mnam">
                                        <div class="img-wrapper">
                                            <img src={reference03}  srcSet={`${reference03}, ${reference03} 1x, ${reference03_2x} 2x`}  alt="Mnam delivery" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>
                                <div class="column is-half column--text">
                                    <div class="item-hashtags">
                                        <span>#web</span>
                                    </div>
                                    <h2 class="item-title"><a href="/referencie/mnam">Mnam delivery</a></h2>
                                    <p class="item-text">You've confirmed abc@xyz.com. You're all ready to start a new group for chatting</p>
                                    <a class="item-link" href="/referencie/mnam">Zobraziť detail</a>
                                </div>
                            </div> */}



                            <div class="references-list__item columns web">
                                <div class="column is-half">
                                   <a hreflang={this.props.i18n.language} href={this.props.t("reference.medirex", {ns:'routes'})}>
                                        <div class="img-wrapper">
                                            <img src={reference07} srcSet={`${reference07}, ${reference07} 1x, ${reference07_2x} 2x`}  alt="medirex" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>
                                <div class="column is-half column--text">
                                    <div class="item-hashtags">
                                        <span>#web</span>
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.medirex", {ns:'routes'})}>Medirex group academy</a></h2>
                                    <p class="item-text">Pri tomto webe sme mali úlohu navrhnúť web s vyjadrením výskumu a budúcnosti. Navrhli sme futuristický dizajn a zastrešili sme aj programovanie.</p>
                                    <a class="item-link" href={this.props.t("reference.medirex", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div>
                            </div>

                            <div class="references-list__item columns web">
                                <div class="column is-half column--text ux">
                                    <div class="item-hashtags">
                                        <span>#web</span>
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.mnam", {ns:'routes'})}>MŇAM delivery</a></h2>
                                    <p class="item-text">Navrhli sme dizajn s prihliadaním na brand manuál a taktiež na dobré user-experience a jednoduché možnosti objednávania či zvolenia doplnkov a prísad.</p>
                                    <a hreflang={this.props.i18n.language} class="item-link" href={this.props.t("reference.mnam", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div> 
                                <div class="column is-half">
                                    <a hreflang={this.props.i18n.language} href={this.props.t("reference.mnam", {ns:'routes'})}>
                                        <div class="img-wrapper">
                                            <img src={reference08}  srcSet={`${reference08}, ${reference08} 1x, ${reference08_2x} 2x`}  alt="MŇAM delivery" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div class="references-list__item columns web">
                                <div class="column is-half">
                                    <a hreflang={this.props.i18n.language} href={this.props.t("reference.penzion", {ns:'routes'})}>
                                        <div class="img-wrapper">
                                            <img src={reference10}  srcSet={`${reference10}, ${reference10} 1x, ${reference10_2x} 2x`}  alt="penzion kuzminovo" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>
                                <div class="column is-half column--text">
                                    <div class="item-hashtags">
                                        <span>#web</span>
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.penzion", {ns:'routes'})}>Penzión Kuzmínovo</a></h2>
                                    <p class="item-text">Web penziónu Kuzmínovo už bol zastaralý a vyžadoval nový dych. <br /><br />Pripravili sme dizajn na mieru a impementovali ho na CMS Wordpress pre správu obsahu.</p>

                                    <a hreflang={this.props.i18n.language} class="item-link" href={this.props.t("reference.penzion", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div> 
                            </div>

                            
                            <div class="references-list__item columns web">
                                <div class="column is-half column--text">
                                    <div class="item-hashtags">
                                        <span>#web</span>
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.casopis", {ns:'routes'})}>Časopis projekt</a></h2>
                                    <p class="item-text">Pre architektonický časopis Projekt, ktorý vychádza už od roku 1959, sme vytvorili úplne nový web s možnosťou prehliadania ukážok časopisu online.</p>
                                    <a hreflang={this.props.i18n.language} class="item-link" href={this.props.t("reference.casopis", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div>
                                <div class="column is-half">
                                   <a hreflang={this.props.i18n.language} href={this.props.t("reference.casopis", {ns:'routes'})}>
                                        <div class="img-wrapper">
                                            <img src={reference13} srcSet={`${reference13}, ${reference13} 1x, ${reference13_2x} 2x`}  alt="Casopis projekt" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div class="references-list__item columns web marketing">
                                <div class="column is-half">
                                    <a hreflang={this.props.i18n.language} href={this.props.t("reference.janKadera", {ns:'routes'})}>
                                        <div class="img-wrapper">
                                            <img src={reference14}  srcSet={`${reference14}, ${reference14} 1x, ${reference14_2x} 2x`}  alt="Kampaň Ján Kadera" />
                                            <div class="overlayer"></div>
                                        </div>
                                    </a>
                                </div>
                                <div class="column is-half column--text">
                                    <div class="item-hashtags">
                                        <span>#web</span>
                                        <span>#marketing</span>
                                        <span>#prints</span>
                                        <span>#identity</span>
                                    </div>
                                    <h2 class="item-title"><a href={this.props.t("reference.janKadera", {ns:'routes'})}>Úspešná primátorska kampaň</a></h2>
                                    <p class="item-text">Pre primátora mesta Námestovo Jána Kaderu<br /> sme pripravovali celú kandidátsku kampaň vo<br /> voľbách 2022. Starali sme sa o celú vizuálnu identitu, sociálne siete, web a online marketing.</p>

                                    <a hreflang={this.props.i18n.language} class="item-link" href={this.props.t("reference.janKadera", {ns:'routes'})}>{this.props.t('references.link')}</a>
                                </div> 
                            </div>
                            
                        </div> 

                        {/* <div class="load-more">
                            <a class="load-more-btn">Načítať viac</a>
                        </div> */}
                    </div>
            </section>
        )
    }
}

export default withTranslation()(ReferenceList);