import React, { Component } from "react";
import { Waypoint } from "react-waypoint";
import _ from "lodash-es";
import $ from "jquery";
import Notifications, { notify } from "react-notify-toast";
import { withTranslation } from "react-i18next"; // Import withTranslation
import i18n from "i18next";


import Portfolio from "../home/Portfolio";

import ModalConsultation from "../home/ModalConsultation";

import service01 from "../../images/services/web/web-01.svg";
import service02 from "../../images/services/web/web-02.svg";
import service03 from "../../images/services/web/web-03.svg";
import service04 from "../../images/services/web/web-04.svg";

import david from "../../images/services/web/david-brontvaj.png";

import video from "../../videos/matate-logo-movie.mp4";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = { Modal: false, email: "", tel: "", text: "", sent: false };
    this.handleInputChange = this.handleInputChange.bind(this)

  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  blocks_load = () => {
    $(".freeCons .const_free_text").css("display", "none");
    $(".freeCons").css("opacity", "1");
    $(".freeCons").css("width", "70px");
  };

  email = () => {
    var api_key = "a89a0ff9aea2ee281d70816f0a3127ac-1d8af1f4-0e6fca5b";
    var domain = "mg.matate.sk";
    var mailgun = require("mailgun-js")({
      apiKey: api_key,
      domain: domain,
      host: "api.eu.mailgun.net",
    });

    var text = "<h3><b>Email:</b> " + this.state.email + " </h3> ";
    text += "<h3><b>Telefón:</b> " + this.state.tel + " </h3> ";
    text += "<h3><b>Text:</b> " + this.state.text + " </h3> ";

    var data = {
      from: "Matate server <server@mg.matate.sk>",
      to: "info@matate.sk" /*info@matate.sk*/,
      subject: "Konzultácia pre " + this.state.email + " - matate.sk",
      html: text,
    };
    data["h:Reply-To"] = this.state.email;
    if (
      this.state.email !== "" &&
      this.state.text !== "" &&
      this.validateEmail(this.state.email)
    ) {
      mailgun.messages().send(data);
      
      //notify.show("Správa bola úspešne odoslaná.", "success");

      this.state.sent = true;
      let $self = this;

      setTimeout(function() {
        //this.props.close();
        $self.state.sent = false;
    }, 2000);

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "contactFormSent",
        form_type: "contactPage",
        user: {
          message: $self.state.text,
          email: $self.state.email,
          phone: $self.state.tel,
        },
      });

      this.setState({ email: "", tel: "", text: ""});
    } else {
      notify.show("Zle vyplnené údaje.", "error");
    }
  };

  componentDidMount() {
    // set autoplay dynamically to not showed controls on iOS
    document.getElementById("heroVideo").setAttribute("autoplay", "autoplay");
    document.getElementById("heroVideo").play();

    $(".item-question").on("click", function () {
      $(this).toggleClass("is-toggled");
      $(this).next().slideToggle();
    });
  }

  modal_show = () => {
    this.setState({ Modal: true });
    _.delay(function () {
      $("#myModal .modal_content").css("transform", "scale(1)");
    }, 500);
  };

  modal_close = () => {
    this.setState({ Modal: false });
  };

  render() {
    const { t } = this.props; 
    return (
      <section className="content content--services">
        <Waypoint scrollableAncestor={window} onEnter={this.blocks_load} />
        <ModalConsultation close={this.modal_close} show={this.state.Modal} />

        <div class="block--services-items">
          <div class="container">
            <div class="block-head">
              <div class="block-head__subtitle">NAŠE PRÁCE.</div>
              <h2 class="block-head__title">Vieme pre vás vytvoriť</h2>
            </div>
            <div class="services-items">
              <div class="service-item">
                <div class="img-holder">
                  <img src={service01} alt="Web stránka s dizajnom na mieru" />
                </div>
                <h2 class="service-item__title">
                  Web stránka <br />s dizajnom na mieru
                </h2>
                <p class="service-item__description">
                  Vytvárame unikátne webové stránky prispôsobené vašim potrebám
                  a firemnej identite, s dôrazom na pekný vizuál, použiteľnosť a
                  responzivitu na všetkých zariadeniach.
                </p>
              </div>
              <div class="service-item">
                <div class="img-holder">
                  <img src={service02} alt="Interný systém na mieru" />
                </div>
                <h2 class="service-item__title">
                  Interný systém
                  <br /> na mieru
                </h2>
                <p class="service-item__description">
                  Navrhujeme interné systémy, ktoré optimalizujú firemné
                  procesy, zlepšujú efektivitu a prispôsobia sa vašim
                  špecifickým obchodným potrebám
                </p>
              </div>
              <div class="service-item">
                <div class="img-holder">
                  <img src={service03} alt="Webová aplikácia" />
                </div>
                <h2 class="service-item__title">E-shop a ecommerce riešenia</h2>
                <p class="service-item__description">
                  Ponúkame kompletné e-commerce riešenia pre rýchly a bezpečný
                  online predaj, vrátane platobných brán a správy skladových
                  zásob. E-shopy robíme s moderným a prehľadným dizajnom na
                  mieru.
                </p>
              </div>
              <div class="service-item">
                <div class="img-holder">
                  <img src={service04} alt="E-shop a ecommerce riešenia" />
                </div>
                <h2 class="service-item__title">
                  Webová
                  <br />
                  aplikácia
                </h2>
                <p class="service-item__description">
                  Navrhujeme a vyvíjame interaktívne a funkčné webové aplikácie,
                  ktoré sú rýchle, bezpečné ale najmä používateľsky prívetivé.
                </p>
              </div>
            </div>

            <div class="btn-holder">
              <a href="#" class="btn--cta" onClick={this.modal_show}>
                Chcem konzultáciu zadarmo <span>👋</span>
              </a>
            </div>
          </div>
        </div>

        <div class="block--steps">
          <div class="container">
            <div class="block-head block-head--alignLeft">
              <div class="head__left">
                <div class="block-head__subtitle">AKO PRACUJEME</div>
                <h2 class="block-head__title block-head__title--light">
                  Čo od nás môžete očakávať
                </h2>
              </div>
              <div class="head__right">
                <p class="block-head__text">
                  Očakávajte profesionálny prístup, kreatívne riešenia a vysokú
                  kvalitu služieb. Spoľahnite sa na nás pre komplexné riešenia
                  od návrhu až po realizáciu a trvalú podporu. Našim cieľom je
                  plnenie tých vašich, najmä podnikateľských cieľov.
                </p>
              </div>
            </div>
            <div class="steps">
              <div class="steps__item">
                <div class="item-number">01</div>
                <h3 class="item-title">
                  Stretneme sa alebo si zavoláme online
                </h3>
                <p class="item-text">
                  Dohodnime si krátke nezáväzné stretnutie alebo online hovor na
                  pochopenie vašich potrieb a cieľov.
                </p>
              </div>
              <div class="steps__item">
                <div class="item-number">02</div>
                <h3 class="item-title">
                  Navrhneme pekný, moderný ale najmä funkčný dizajn
                </h3>
                <p class="item-text">
                  Vytvoríme atraktívny a funkčný dizajn, ktorý zodpovedá vašim
                  požiadavkám.
                </p>
              </div>
              <div class="steps__item">
                <div class="item-number">03</div>
                <h3 class="item-title">
                  Naprogramujeme, implementujeme na CMS a otestujeme
                </h3>
                <p class="item-text">
                  Dizajn naprogramujeme a implementujeme na CMS pre jednoduchú
                  správu obsahu. Web dôkladne otestujeme.
                </p>
              </div>
              <div class="steps__item">
                <div class="item-number">04</div>
                <h3 class="item-title">
                  O web sa naďalej staráme a vieme naň priviesť zákazníkov
                </h3>
                <p class="item-text">
                  Poskytujeme údržbu webu a vieme vám poskytnúť aj komplexné
                  marketingové služby alebo SEO na prilákanie zákazníkov.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="block--references">
          <Portfolio />
        </div>

        <div class="block--faq">
          <div class="container">
            <div class="block-head block-head--alignLeft">
              <div class="block-head__subtitle">FAQ</div>
              <h2 class="block-head__title block-head__title--light">
                Máte otázky?
              </h2>
            </div>
            <div class="row">
              <div class="column">
                <div class="faq">
                  <div class="faq__item">
                    <h3 class="item-question">
                      <span class="item-counter">01</span>Ako dlho trvá
                      vytvorenie webovej stránky?
                    </h3>
                    <div class="item-answer">
                      <p>
                        Doba vytvorenia webovej stránky závisí od komplexnosti a
                        funkcií, ktoré požadujete. V priemere trvá vytvorenie
                        jednoduchej webovej stránky s dizajnom na mieru cca 4-6
                        týždňov, zatiaľ čo komplexnejšie projekty môžu trvať aj
                        niekoľko mesiacov.
                      </p>
                    </div>
                  </div>
                  <div class="faq__item">
                    <h3 class="item-question">
                      <span class="item-counter">02</span>Koľko stojí vytvorenie
                      webovej stránky?
                    </h3>
                    <div class="item-answer">
                      <p>
                        Cena sa líši v závislosti od typu webovej stránky,
                        požadovaných funkcií, rozsahu a dizajnu. Naša ponuka
                        začína od 900 EUR za základnú webovú stránku s využitím
                        šablóny, avšak webové stránky s dizajnom na mieru sú
                        drahšie. Pre presnú cenovú ponuku nás kontaktujte so
                        špecifikáciami vášho projektu.
                      </p>
                    </div>
                  </div>
                  <div class="faq__item">
                    <h3 class="item-question">
                      <span class="item-counter">03</span>Budem môcť upravovať
                      obsah svojej webovej stránky sám/sama?
                    </h3>
                    <div class="item-answer">
                      <p>
                        Áno, všetky naše webové stránky sú postavené na
                        používateľsky priateľských CMS platformách, ktoré vám
                        umožnia ľahko aktualizovať a spravovať obsah bez potreby
                        programovacích znalostí.
                      </p>
                    </div>
                  </div>
                  <div class="faq__item">
                    <h3 class="item-question">
                      <span class="item-counter">04</span> Aké typy webových
                      stránok vytvárate?
                    </h3>
                    <div class="item-answer">
                      <p>
                        Špecializujeme sa na rôzne typy webových stránok,
                        vrátane firemných prezentácií, e-shopov, portfólií,
                        informačných portálov a ďalších. Tiež sa radi popasujeme
                        so zložitejšími zadaniami typu CRM systém či firemná
                        databáza.
                      </p>
                    </div>
                  </div>
                  <div class="faq__item">
                    <h3 class="item-question">
                      <span class="item-counter">05</span>Poskytujete aj SEO
                      služby?
                    </h3>
                    <div class="item-answer">
                      <p>
                        Áno, naša služba zahŕňa základnú optimalizáciu pre
                        vyhľadávače (SEO), aby vaša webová stránka mala dobré
                        základy. Ponúkame aj pokročilé a dlhodobejšie SEO služby
                        na zlepšenie vašich pozícii vo vyhľadávačoch.
                      </p>
                    </div>
                  </div>
                  <div class="faq__item">
                    <h3 class="item-question">
                      <span class="item-counter">06</span>Budem mať prístup k
                      analytickým údajom o návštevnosti mojej stránky?
                    </h3>
                    <div class="item-answer">
                      <p>
                        Áno, integrujeme Google Analytics a ďalšie analytické
                        nástroje, aby ste mohli sledovať návštevnosť, správanie
                        používateľov a ďalšie dôležité metriky vašej webovej
                        stránky.
                      </p>
                    </div>
                  </div>
                  <div class="faq__item">
                    <h3 class="item-question">
                      <span class="item-counter">07</span>Poskytujete aj podporu
                      a údržbu po spustení webovej stránky?
                    </h3>
                    <div class="item-answer">
                      <p>
                        Áno, ponúkame rôzne balíky podpory a údržby, aby vaša
                        webová stránka bola vždy aktuálna a funkčná. Sme tu pre
                        vás, aby sme riešili akékoľvek technické problémy alebo
                        aktualizácie, ktoré budete potrebovať.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <video id="heroVideo" src={video} muted loop playsInline />
              </div>
            </div>
          </div>
        </div>

        <div class="block--contact">
          <div class="container">
            <div class="row">
              <div class="column column--left">
                <div class="contact-box">
                  <h4 class="contact-box__title">
                    Práve ste našli spoľahlivého dodávateľa pre váš nový web
                  </h4>
                  <p class="contact-box__text">Radi vám poradíme. Napíšte.</p>

                  <div class="contact-box__data">
                    <div class="data-ceo">
                      <div class="ceo-image">
                        <img src={david} alt="David Brontvaj Matate CEO" />
                      </div>
                      <div class="ceo-data">
                        <div class="ceo-name">Dávid Brontvaj, CEO</div>
                        <div class="ceo-contact">
                          <a href="tel:0902 359 330">
                            <span>0902 359 330</span>
                          </a>
                          <span class="separator"></span>
                          <a href="mailto:david@matate.sk">
                            <span>david@matate.sk</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="data-text">
                      <p>
                        Rezervujte si bezplatnú 30 min konzultáciu a prejdime si
                        všetko potrebné. Komunikovať s vami budem priamo ja ako
                        majiteľ firmy.
                      </p>
                      <a
                        href="https://calendly.com/davidbrontvaj/30min-meeting"
                        target="_blank"
                        class="btn--bordered"
                      >
                        MÁM ZÁUJEM
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column column--right">
                <div class="contact-form">
                  <h2>Alebo vyplňte formulár</h2>

                  <form class="form--contact" action="." method="POST">
                    <div class="form-group">
                      <input
                        class="form-input"
                        name="email"
                        type="text"
                        placeholder="E-mail (povinné)"
                        value={this.state.email}
                        required
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div class="form-group">
                      <input
                        class="form-input"
                        name="tel"
                        type="text"
                        placeholder="Telefón"
                        value={this.state.tel}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    <div class="form-group">
                      <textarea
                        class="form-input"
                        name="text"
                        required
                        placeholder="Správa"
                        value={this.state.text}
                        onChange={this.handleInputChange}
                      ></textarea>
                    </div>
                    <div class="form-group">
                      <label>
                        <input type="checkbox" />
                        Súhlasím so <a href="#">spracovaním osobných údajov</a>
                      </label>
                    </div>
                    <div class="form-submit">
                      <button
                        type="button"
                        class="btn--submit"
                        onClick={this.email}
                      >
                        <span>Odoslať</span>
                        <svg
                          width="16"
                          height="16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#a)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.446 4.554 6.166 8.22.643 6.38a.94.94 0 0 1 .009-1.788L14.772.045a.941.941 0 0 1 1.183 1.184l-4.547 14.12a.94.94 0 0 1-1.787.008l-1.85-5.55 3.675-5.253Z"
                              fill="#000"
                            />
                          </g>
                          <defs>
                            <clipPath id="a">
                              <path fill="#fff" d="M0 0h16v16H0z" />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>

                    {this.state.sent ? <div className="success-msg">{this.props.t('messages.success')}</div> : null }
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Notifications  options={{zIndex: 99999, top: '750px'}} />

        {/* old content <div class="container-block"> 
                    <div className="block">
                        <div className="block__text">
                            <div className="text-toptitle">UI/UX</div>
                            <h2>Dizajn</h2>
                            <p>Ľudia majú radi veci, ktoré sú im sympatické. Rovnaké je to aj s dizajnom na stránke. Farby, rozloženie, tvar a veľkosť písma - to všetko rozhoduje o úspechu vášho webu.</p>
                            <p><strong>Nakreslíme dizajn webu alebo aplikácie</strong> na mieru vášmu podnikaniu.</p>
                        </div>
                        <div className="block__image">
                            <img src={service01} srcSet={`${service01}, ${service01} 1x, ${service01retina} 2x`} alt="Dizajn" />
                        </div>
                    </div>
                    <div className="block">
                        <div className="block__image">
                            <img src={service02}  srcSet={`${service02}, ${service02} 1x, ${service02retina} 2x`} alt="WordPress" />
                        </div>
                        <div className="block__text">
                            <div className="text-toptitle">s možnosťou administrácie</div>
                            <h2>WordPress</h2>
                            <p>Ste fanúšikom najpoužívanejšieho redakčného systému? Vytvoríme vám web na šablóne alebo pripravíme komplet <strong>WordPress riešenie na mieru,</strong> či už sa jedná o prezentačný web alebo e-shop.</p>
                        </div>
                    </div>
                    <div className="block">
                        <div className="block__text">
                            <div className="text-toptitle">riešenie na mieru</div>
                            <h2>Rozsiahly web</h2>
                            <p><strong>Potrebujete zložitejší webový portál?</strong> Máme skúsenosti s dovolenkovými a inzertnými portálmi a dokážeme vytvoriť aj sociálnu sieť alebo CRM.</p>
                            <p>Navrhneme a naprogramujeme pre vás také riešenie, ktoré pomôže vašim procesom.</p>
                        </div>
                        <div className="block__image">
                            <img src={service03}  srcSet={`${service03}, ${service03} 1x, ${service03retina} 2x`} alt="Rozsiahly web" />
                        </div>
                    </div>
                </div>  */}
      </section>
    );
  }
}

export default withTranslation()(Content);