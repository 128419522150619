import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'
 
import eye from '../../images/references/drtheiss/eye.svg'
import wireframe from '../../images/references/drtheiss/wireframe.svg'
import marketing from '../../images/references/drtheiss/marketing.svg'

import coverImage from '../../images/references/drtheiss/cover.png'
import coverImage2x from '../../images/references/drtheiss/cover@2x.png'

import refImage01 from '../../images/references/drtheiss/screen-01.png'
import refImage01_2x from '../../images/references/drtheiss/screen-01@2x.png'

import refImage02 from '../../images/references/drtheiss/screen-02.png'
import refImage02_2x from '../../images/references/drtheiss/screen-02@2x.png'

import refImage03 from '../../images/references/drtheiss/screen-03.png'
import refImage03_2x from '../../images/references/drtheiss/screen-03@2x.png'

import refImage04 from '../../images/references/drtheiss/screen-04.png'
import refImage04_2x from '../../images/references/drtheiss/screen-04@2x.png'

import mockupImage from '../../images/references/drtheiss/screens.png'
import mockupImage_2x from '../../images/references/drtheiss/screens@2x.png'

import card01 from '../../images/references/drtheiss/product-small-thumbnail.png'
import card02 from '../../images/references/drtheiss/product-small-thumbnail-02.png'
import card03 from '../../images/references/drtheiss/product-small-thumbnail-03.png'
import card04 from '../../images/references/drtheiss/product-small-thumbnail-04.png'

import partner01 from '../../images/references/drtheiss/partner-01.svg'
import partner02 from '../../images/references/drtheiss/partner-02.svg'
import partner03 from '../../images/references/drtheiss/partner-03.svg'
import partner04 from '../../images/references/drtheiss/partner-04.svg'
import partner05 from '../../images/references/drtheiss/partner-05.svg'

import cursor from '../../images/references/drtheiss/product-small-thumbnail.png'
import leftArrow from '../../images/references/drtheiss/product-small-thumbnail.png'
import rightArrow from '../../images/references/drtheiss/product-small-thumbnail.png'

import mobileScreen from '../../images/references/drtheiss/mobile-screen.png'

export default class ContentDrTheiss extends Component {

    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $(".back-link").css("opacity", "1");
        }, 1500);
    }

    blockIntro = () => {
        _.delay(function() {
            $(".block--intro").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(0).css("opacity", "1").css("transform", "none");
        }, 400);
        
        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(1).css("opacity", "1").css("transform", "none");
        }, 750);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(2).css("opacity", "1").css("transform", "none");
        }, 950);
    }

    blockCoverImage = () => {
        $(".block--cover-image").find('.overlayer').css("width", "0");
    }

    blockScreen = () => {
        $(".block--screens").find(".block-image").css("opacity", "1").css("transform", "none");
    }

    blockPreviews = () => {

        _.delay(function() {

            let screens = $(".block--previews").find(".block-image");

            $.each(screens, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);
    }

    blockColumns = () => {

        let columnItems = $(".block--columns").find(".services__item");

        _.delay(function() {
            $.each(columnItems, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);

    }

    blockImages = () => {

        _.delay(function() {
            $(".block-images").find(".overlayer").css("width", "0");
        }, 200);

        _.delay(function() {
            $(".block-images").find("img").css("opacity", "1").css("transform", "none");
        }, 1000);
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />

                    <div class="container-block"> 
                        <div class="partners">
                            <img src={partner01} alt="Lacalut" />
                            <img src={partner02} alt="Dr Theiss" />
                            <img src={partner03} alt="Dolgit" />
                            <img src={partner04} alt="Olivenöl" />
                            <img src={partner05} alt="medipharma cosmetics" />
                        </div>
                    </div>
                    

                <div class="container-block"> 


                    <div class="block--intro">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockIntro}
                            bottomOffset="20%"
                        />
                        <h2>E-shop na mieru a online marketing </h2>
                        <p>DR THEISS je spoločnosť, ktorá vyrába a vyvíja výrobky ako lieiky, kozmetiku či zdravotnícke pomôcky. V portfóliu má silné značky ako je Lacalut, Dolgit, Detrical, Parusan a iné.</p>
                        <p>Pre DR THEISS sme vyvinuli e-shop na mieru a staráme sa o online marketing.</p>
                    </div>

                    <div class="block--boxes">
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={eye} alt="UI UX dizajn" />
                                <h3>UI/UX dizajn</h3>
                            </div>
                            <p>Našou úlohou bolo navrhnúť jednoduchý, prehľadný a hlavne používateľsky prívetivý internetový obchod / eshop.</p>
                            <p>Okrem pekného dizajnu sme sa teda zameriavali aj na to, aby sa s e-shopom ľahko pracovalo, aby bol jednoduchý nákupný proces a aby na e-shope človek našiel to, čo hľadá.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={wireframe} alt="PROGRAMOVANIE A CMS" />
                                <h3>PROGRAMOVANIE A CMS</h3>
                            </div>
                            <p>E-shopové riešenie sme vytvárali komplet na mieru, nakoľko to situácia na žačiatku spolupráce vyžadovala. Vyvinuli sme CMS a e-shop systém na PHP frameworku Laravel a následne sme naň implementovali naše navrhnuté dizajny.</p>
                            <p>Okrem toho sme sa samozrejme postarali o napojenie na 3. strany ako napríklad platobná brána Besteron či skladový a fakturačný systém Nautilus.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={marketing} alt="Online marketing" />
                                <h3>Online marketing</h3>
                            </div>
                            <p>Pre DR THEISS sa komplexne staráme o online marketing. Znamená to, že vytvárame obsah na sociálne siete (3 rôzne profily), zastrešujeme PPC kampane na sociálnych sieťach Facebook a Instagram a tiež kampane v Google search a Google display network.</p>
                            <p>Našou úlohou je na e-shop privádzať ľudí, ktorí nakúpia a zvyšovať tak tržby.</p>
                        </div>
                    </div>

                    <div className="link-row link-row--topNegative">
                        <a href="https://www.theiss.sk" target="blank">navštíviť WWW.theiss.SK <i className="arrow-next"></i></a>
                    </div>
                </div> 

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
                     <img src={coverImage}  srcSet={`${coverImage}, ${coverImage} 1x, ${coverImage2x} 2x`}  alt="drtheiss" />
                    <div class="overlayer"></div> 
                </div>

                <div className="block--columns">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockColumns}
                            bottomOffset="10%"
                        />
                    <div className="container-block">
                        <div className="services">
                            <div className="services__item columns">
                                <div className="services__image column is-half">
                                   <img class="cg02_main" src={mobileScreen} alt="drtheiss" />
                                </div>
                                <div className="services__image column is-half last">
                                    <img class="cg01" src={card01} alt="drtheiss" />
                                    <img class="cg02" src={card02} alt="drtheiss" />
                                    <img class="cg03" src={card03} alt="drtheiss" />
                                    <img class="cg04" src={card04} alt="drtheiss" /> 
                                    <img class="cg05" src={cursor} alt="drtheiss" /> 
                                    <img class="cg06" src={leftArrow} alt="drtheiss" /> 
                                    <img class="cg07" src={rightArrow} alt="drtheiss" /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block--screens">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockScreen}
                            bottomOffset="5%"
                        />
                    <img class="block-image" src={mockupImage} srcSet={`${mockupImage}, ${mockupImage} 1x, ${mockupImage_2x} 2x`}  alt="Mńam delivery Service" />
                </div>

                <div class="block--previews">
                    <div class="container-block"> 
                        <div class="previews-screens">

                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPreviews}
                            />
                            <div class="columns">
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage01} srcSet={`${refImage01}, ${refImage01} 1x, ${refImage01_2x} 2x`}  alt="drtheiss" />
                                    <img class="block-image" src={refImage03} srcSet={`${refImage03}, ${refImage03} 1x, ${refImage03_2x} 2x`}  alt="drtheiss" />
                                </div>
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage02} srcSet={`${refImage02}, ${refImage02} 1x, ${refImage02_2x} 2x`}  alt="drtheiss" />
                                    <img class="block-image" src={refImage04} srcSet={`${refImage04}, ${refImage04} 1x, ${refImage04_2x} 2x`}  alt="drtheiss" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        )
    }
}
