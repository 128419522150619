import React, { Component } from "react";
import { Waypoint } from "react-waypoint";
import $ from "jquery";
import _ from "lodash-es";
import { TweenMax } from "gsap/all";
import { withTranslation } from "react-i18next";

import { Player } from '@lottiefiles/react-lottie-player';

import icon01 from "../../images/services/category-graphic-01.svg";
import icon02 from "../../images/services/category-graphic-02.svg";
import icon03 from "../../images/services/category-graphic-03.svg";

import webdesignHover from "./assets/web-design.json";
import wireframeHover from "./assets/wireframe.json";
import timeManagementHover from "./assets/time-management.json";
import analyticsHover from "./assets/analytics.json";
import analyticalHover from "./assets/analytical-skills.json";
import onlineHover from "./assets/online.json";

import web from "../../images/nase_sluzny/web.svg";
import ux from "../../images/nase_sluzny/ux.svg";
import analytics from "../../images/nase_sluzny/analytics.svg";
import seo from "../../images/nase_sluzny/seo.svg";
import marketing from "../../images/nase_sluzny/online-marketing.svg";
import projectManagement from "../../images/nase_sluzny/projekt-management.svg";

class List extends Component {
  constructor(props) {
    super(props);
    this.state = { animationRunned: false };
  }

  list_load = () => {
    let that = this;
    $(".freeCons .const_free_text").css("display", "none");
    $(".freeCons").css("opacity", "1");
    $(".freeCons").css("width", "70px");

    _.delay(function () {
      if (!that.state.animationRunned) {
        $(".services-list").css("opacity", "1");
        TweenMax.staggerFrom(
          ".service-box",
          1.5,
          { y: 50, opacity: 0, clearProps: "all" },
          0.35
        );
        that.setState({ animationRunned: true });
      }
    }, 500);
  };

  render() {
    return (
      <section className="block-services-list">
        <Waypoint scrollableAncestor={window} onEnter={this.list_load} />

        <div className="container-block">
          <div className="services">
            <a
              href={this.props.t("service.web", { ns: "routes" })}
              className="service-item"
            >
              <div className="service-item__content">
                <div className="image-holder">
                  <img className="service-item__image" src={web} alt="." />
                  <Player
                    autoplay
                    loop
                    src={webdesignHover}
                    style={{ height: "86px", width: "86px" }}
                  ></Player>
                </div>
                <h2 className="service-item__title">Tvorba web stránok</h2>
                <p className="service-item__desc">
                  Tvoríme web stránky na mieru, ktoré posunú váš biznis na vyšší
                  level. Prehľadné a pútavé. Aby sa zákazníkom páčili a radi
                  spravili objednávku.
                </p>
                <div className="service-item__tags">
                  <span>eshop</span>
                  <span>webstránka</span>
                  <span>wordpress</span>
                  <span>interný systém</span>
                  <span>webová aplikácia</span>
                </div>
              </div>
              <div className="service-item__link-holder">
                <div className="link">Zobraziť viac</div>
              </div>
            </a>

            <a
              href={this.props.t("service.onlineMarketing", { ns: "routes" })}
              className="service-item"
            >
              <div className="service-item__content">
                <div className="image-holder">
                  <img
                    className="service-item__image"
                    src={marketing}
                    alt="."
                  />
                  <Player
                    autoplay
                    loop
                    src={onlineHover}
                    style={{ height: "86px", width: "86px" }}
                  ></Player>
                </div>
                <h2 className="service-item__title">Online marketing</h2>
                <p className="service-item__desc">
                  Odhaľujeme skutočné správanie zákazníkov. Čo sa im páči a čo
                  nie. Testujeme web, aby sme zistili, kde presne treba zabrať.
                </p>
                <div className="service-item__tags">
                  <span>PPC kampane</span>
                  <span>Sociálne siete</span>
                  <span>Google reklamy</span>
                  <span>Youtube reklamy</span>
                </div>
              </div>
              <div className="service-item__link-holder">
                <div className="link">Zobraziť viac</div>
              </div>
            </a>

            <a
              href={this.props.t("service.seo", { ns: "routes" })}
              className="service-item"
            >
              <div className="service-item__content">
                <div className="image-holder">
                  <img className="service-item__image" src={seo} alt="." />
                  <Player
                    autoplay
                    loop
                    src={analyticalHover}
                    style={{ height: "86px", width: "86px" }}
                  ></Player>
                </div>
                <h2 className="service-item__title">SEO</h2>
                <p className="service-item__desc">
                  Vyladíme váš web, aby si rozumel s ľuďmi aj s robotmi. SEO
                  audit nám povie na čo sa máme zamerať, aby vám web prinášal
                  zákazníkov.
                </p>
              </div>
              <div className="service-item__link-holder">
                <div className="link">Zobraziť viac</div>
              </div>
            </a>

            <a
              href={this.props.t("service.uxOnTheWeb", { ns: "routes" })}
              className="service-item"
            >
              <div className="service-item__content">
                <div className="image-holder">
                  <img className="service-item__image" src={ux} alt="." />
                  <Player
                    autoplay
                    loop
                    src={wireframeHover}
                    style={{ height: "86px", width: "86px" }}
                  ></Player>
                </div>
                <h2 className="service-item__title">UX vo webe</h2>
                <p className="service-item__desc">
                  Odhaľujeme skutočné správanie zákazníkov. Čo sa im páči a čo
                  nie. Testujeme web, aby sme zistili, kde presne treba zabrať.
                </p>
              </div>
              <div className="service-item__link-holder">
                <div className="link">Zobraziť viac</div>
              </div>
            </a>

            <a
              href={this.props.t("service.webAnalytics", { ns: "routes" })}
              className="service-item"
            >
              <div className="service-item__content">
                <div className="image-holder">
                  <img
                    className="service-item__image"
                    src={analytics}
                    alt="."
                  />
                  <Player
                    autoplay
                    loop
                    src={analyticsHover}
                    style={{ height: "86px", width: "86px" }}
                  ></Player>
                </div>
                <h2 className="service-item__title">Webová analytika</h2>
                <p className="service-item__desc">
                  Nastavíme vám analytické nástroje a prepojíme ich s webom.
                  Následne sa dzvieme viac o správaní zákazníkov a plánujeme
                  marketingové aktivity.
                </p>
                <div className="service-item__tags">
                  <span>Google Analytics 4</span>
                  <span>GTM</span>
                  <span>Facebook pixel</span>
                </div>
              </div>
              <div className="service-item__link-holder">
                <div className="link">Zobraziť viac</div>
              </div>
            </a>

            <a
              href={this.props.t("service.management", { ns: "routes" })}
              className="service-item"
            >
              <div className="service-item__content">
                <div className="image-holder">
                  <img
                    className="service-item__image"
                    src={projectManagement}
                    alt="."
                  />
                  <Player
                    autoplay
                    loop
                    src={timeManagementHover}
                    style={{ height: "86px", width: "86px" }}
                  ></Player>
                </div>
                <h2 className="service-item__title">Projektový manažment</h2>
                <p className="service-item__desc">
                  Zabezpečíme efektívne riadenie vášho projektu. Môžete sa na
                  nás spoľahnúť od A po Z. Potrebujete poradiť od expertov? Sme
                  tu pre vás.
                </p>
              </div>
              <div className="service-item__link-holder">
                <div className="link">Zobraziť viac</div>
              </div>
            </a>
          </div>

          {/* <div className="services-list">
            <a
              hreflang={this.props.i18n.language}
              href={this.props.t("service.web", { ns: "routes" })}
              className="service-box"
            >
              <div className="box-top">
                <img src={icon01} alt="Tvorba web stránok" />
                <h2>
                  {this.props.t(
                    "pageSluzby.servicesList.services.service1.title"
                  )}
                </h2>
              </div>
              <div className="box-data">
                <div className="text">
                  <p>
                    {this.props.t(
                      "pageSluzby.servicesList.services.service1.description"
                    )}
                  </p>
                </div>
                <div className="box-footer">
                  <span className="link-text">
                    {this.props.t("pageSluzby.servicesList.services.linkText")}
                  </span>
                  <div className="box-button">
                    <i></i>
                  </div>
                </div>
              </div>
            </a>

            <a
              hreflang={this.props.i18n.language}
              href={this.props.t("service.uxOnTheWeb", { ns: "routes" })}
              className="service-box"
            >
              <div className="box-top">
                <img
                  src={icon02}
                  alt={this.props.t(
                    "pageSluzby.servicesList.services.service2.title"
                  )}
                />
                <h2>
                  {this.props.t(
                    "pageSluzby.servicesList.services.service2.title"
                  )}
                </h2>
              </div>
              <div className="box-data">
                <div className="text">
                  <p>
                    {this.props.t(
                      "pageSluzby.servicesList.services.service2.description"
                    )}
                  </p>
                </div>
                <div className="box-footer">
                  <span className="link-text">
                    {this.props.t("pageSluzby.servicesList.services.linkText")}
                  </span>
                  <div className="box-button">
                    <i></i>
                  </div>
                </div>
              </div>
            </a>

            <a
              hreflang={this.props.i18n.language}
              href={this.props.t("service.webAnalytics", { ns: "routes" })}
              className="service-box"
            >
              <div className="box-top">
                <img
                  src={icon03}
                  alt={this.props.t(
                    "pageSluzby.servicesList.services.service3.title"
                  )}
                />
                <h2>
                  {this.props.t(
                    "pageSluzby.servicesList.services.service3.title"
                  )}
                </h2>
              </div>
              <div className="box-data">
                <div className="text">
                  <p>
                    {this.props.t(
                      "pageSluzby.servicesList.services.service3.description"
                    )}
                  </p>
                </div>

                <div className="box-footer">
                  <span className="link-text">
                    {this.props.t("pageSluzby.servicesList.services.linkText")}
                  </span>
                  <div className="box-button">
                    <i></i>
                  </div>
                </div>
              </div>
            </a>

            <a
              hreflang={this.props.i18n.language}
              href={this.props.t("service.seo", { ns: "routes" })}
              className="service-box"
            >
              <div className="box-top">
                <img
                  src={icon02}
                  alt={this.props.t(
                    "pageSluzby.servicesList.services.service4.title"
                  )}
                />
                <h2>
                  {this.props.t(
                    "pageSluzby.servicesList.services.service4.title"
                  )}
                </h2>
              </div>
              <div className="box-data">
                <div className="text">
                  <p>
                    {this.props.t(
                      "pageSluzby.servicesList.services.service4.description"
                    )}
                  </p>
                </div>

                <div className="box-footer">
                  <span className="link-text">
                    {this.props.t("pageSluzby.servicesList.services.linkText")}
                  </span>
                  <div className="box-button">
                    <i></i>
                  </div>
                </div>
              </div>
            </a>

            <a
              hreflang={this.props.i18n.language}
              href={this.props.t("service.onlineMarketing", { ns: "routes" })}
              className="service-box"
            >
              <div className="box-top">
                <img
                  src={icon02}
                  alt={this.props.t(
                    "pageSluzby.servicesList.services.service5.title"
                  )}
                />
                <h2>
                  {this.props.t(
                    "pageSluzby.servicesList.services.service5.title"
                  )}
                </h2>
              </div>
              <div className="box-data">
                <div className="text">
                  <p>
                    {this.props.t(
                      "pageSluzby.servicesList.services.service5.description"
                    )}
                  </p>
                </div>

                <div className="box-footer">
                  <span className="link-text">
                    {this.props.t("pageSluzby.servicesList.services.linkText")}
                  </span>
                  <div className="box-button">
                    <i></i>
                  </div>
                </div>
              </div>
            </a>

            <a
              hreflang={this.props.i18n.language}
              href={this.props.t("service.management", { ns: "routes" })}
              className="service-box"
            >
              <div className="box-top">
                <img
                  src={icon03}
                  alt={this.props.t(
                    "pageSluzby.servicesList.services.service6.title"
                  )}
                />
                <h2>
                  {this.props.t(
                    "pageSluzby.servicesList.services.service6.title"
                  )}
                </h2>
              </div>
              <div className="box-data">
                <div className="text">
                  <p>
                    {this.props.t(
                      "pageSluzby.servicesList.services.service6.description"
                    )}
                  </p>
                </div>

                <div className="box-footer">
                  <span className="link-text">
                    {this.props.t("pageSluzby.servicesList.services.linkText")}
                  </span>
                  <div className="box-button">
                    <i></i>
                  </div>
                </div>
              </div>
            </a>
          </div> */}
        </div>
      </section>
    );
  }
}

export default withTranslation()(List);
