import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
import _ from 'lodash-es'

import eye from '../../images/references/mirano/eye.svg'
import marketing from '../../images/references/mirano/marketing.svg'
import darts from '../../images/references/mirano/darts.svg'

import cards from '../../images/references/mirano/cards.png'
import mobileScreen from '../../images/references/mirano/mobile-screen.png'

import catalog from '../../images/references/mirano/catalog.png'
import catalog2x from '../../images/references/mirano/catalog@2x.png'

import refImage01 from '../../images/references/mirano/screen01.png'
import refImage01_2x from '../../images/references/mirano/screen01@2x.png'

import refImage02 from '../../images/references/mirano/screen02.png'
import refImage02_2x from '../../images/references/mirano/screen02@2x.png'

import decor01 from '../../images/references/mirano/truck.png'
import decor01_2x from '../../images/references/mirano/truck@2x.png'

import decor02 from '../../images/references/mirano/truck02.png'
import decor02_2x from '../../images/references/mirano/truck02@2x.png'

import miranoVideo from '../../images/references/mirano/mirano-video.mp4'

export default class ContentMirano extends Component {

    componentDidMount() {
        document.querySelector("#video").play();
    }

    blocks_load = () => {
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');

        _.delay(function() {
            $(".back-link").css("opacity", "1");
        }, 1500);
    }

    blockIntro = () => {
        _.delay(function() {
            $(".block--intro").css("opacity", "1").css("transform", "none");
        }, 200);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(0).css("opacity", "1").css("transform", "none");
        }, 400);
        
        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(1).css("opacity", "1").css("transform", "none");
        }, 750);

        _.delay(function() {
            $(".block--boxes").find(".service-box").eq(2).css("opacity", "1").css("transform", "none");
        }, 950);
    }

    blockCoverImage = () => {
        $(".block--cover-image").find('.overlayer').css("width", "0");
    }

    blockScreen = () => {
        $(".block--screens").find(".block-image").css("opacity", "1").css("transform", "none");
    }

    blockPreviews = () => {

        _.delay(function() {

            let screens = $(".block--previews").find(".block-image");

            $.each(screens, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);
    }

    blockColumns = () => {

        let columnItems = $(".block--columns").find(".services__item");

        _.delay(function() {
            $.each(columnItems, function()  {
                $(this).css("opacity", "1").css("transform", "none");
            });

        }, 1000);

    }

    blockImages = () => {

        _.delay(function() {
            $(".block-images").find(".overlayer").css("width", "0");
        }, 200);

        _.delay(function() {
            $(".block-images").find("img").css("opacity", "1").css("transform", "none");
        }, 1000);
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    
                    <div class="block--intro">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockIntro}
                            bottomOffset="20%"
                        />
                        <h2>Web, grafika, online marketing a SEO</h2>
                        <p>Pre stavebnú spoločnosť MIRANO, ktorá sa venuje výstavbe nízkoenergetických montovaných domov, zastrešujeme komplexné služby v oblasti webu, online marketingu a grafiky. Postarali sme sa o nový web, video, tlačené katalógy, grafiku kamiónu a zároveň sa staráme o online marketingové kampane a SEO.</p>
                    </div>

                    <div class="block--boxes">
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={eye} alt="UI UX dizajn" />
                                <h3>WEB A GRAFICKÉ MATERIÁLY</h3>
                            </div>
                            <p>Nakoľko web bol už nejakú dobu zastaralý, navrhli sme nový. Cieľom bolo informovať o aktivitách spoločnosti, ale zároveň prehľadnou formou zobraziť dostupné projekty domov a možnosť filtrovať v nich. </p>

                            <p>Pri tvorbe webu sme tiež dávali pozor, aby sme sa neprepadli vo výsledkoch SEO. </p>

                            <p>Okrem webu sa klientovi staráme o grafické návrhy tlačovín - navrhli sme napríklad katalóg a tiež vzhľad kamiónu.</p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={marketing} alt="online marketing" />
                                <h3>ONLINE MARKETING</h3>
                            </div>
                            <p>Pre MIRANO sme navrhli komplexnú marketingovú kampaň v online priestore. Už niekoľko rokov sa staráme o PPC kampane na sociálnych sieťach aj v Google vyhľadávaní</p>
                            <p>Video, ktoré sme vyrobili, sme použili v Youtube reklamách. Priebežne sa staráme o  optimalizáciu kampaní a jej nastavení, aby dosahovala čo najlepšie výsledky. </p>
                        </div>
                        <div class="service-box">
                            <div class="service-box__head">
                                <img src={darts} alt="Seo" />
                                <h3>SEO</h3>
                            </div>
                            <p>80% návštev z neplateného Google vyhľadávania a 20% z platených PPC kampaní. To sú aktuálne pomery návštev webu MIRANO. Už niekoľko rokov sa staráme o SEO a medziročne na výsledkoch rastieme v stovkách % už niekoľko rokov. Zabezpečujeme tak do budúcna pre klienta návštevy</p>
                        </div>
                    </div>

                    <div className="link-row link-row--topNegative">
                        <a href="https://www.mirano.sk" target="blank">navštíviť WWW.mirano.SK<i className="arrow-next"></i></a>
                    </div>
                </div> 

                <div class="block--cover-image">
                        <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockCoverImage}
                        />
{/* 
                        <img src={coverImage}  srcSet={`${coverImage}, ${coverImage} 1x, ${coverImage2x} 2x`}  alt="mirano" /> */}
                        <div class="overlayer"></div> 

                        <video autoPlay loop muted id="video">
                            <source src={miranoVideo} type="video/mp4"></source>
                        </video>
                </div>

                <div className="block--columns">
                    <Waypoint
                            scrollableAncestor={window}
                            onEnter={this.blockColumns}
                            bottomOffset="10%"
                        />
                    <div className="container-block">
                        <div className="services">
                            <div className="services__item columns">
                                <div className="services__image column is-half">
                                   <img class="cg02_main" src={mobileScreen} alt="mirano" />
                                </div>
                                <div className="services__image column is-half">
                                    <img class="cg01" src={cards} alt="mirano" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block--catalog">
                    <div class="container-block">
                        <img src={catalog} srcSet={`${catalog}, ${catalog} 1x, ${catalog2x} 2x`}  alt="mirano" />
                    </div>
                </div>

                <div class="block--previews">
                    <div class="container-block"> 
                        <div class="previews-screens">

                            <Waypoint
                                scrollableAncestor={window}
                                onEnter={this.blockPreviews}
                            />
                            <div class="columns">
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage01} srcSet={`${refImage01}, ${refImage01} 1x, ${refImage01_2x} 2x`}  alt="mirano" />
                                </div>
                                <div class="screen-image is-half">
                                    <img class="block-image" src={refImage02} srcSet={`${refImage02}, ${refImage02} 1x, ${refImage02_2x} 2x`}  alt="mirano" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="block--decors">
                    <img class="block-image" src={decor01} srcSet={`${decor01}, ${decor01} 1x, ${decor01_2x} 2x`}  alt="mirano" />
                    <img class="block-image" src={decor02} srcSet={`${decor02}, ${decor02} 1x, ${decor02_2x} 2x`}  alt="mirano" />
                </div>

            </section>
        )
    }
}
