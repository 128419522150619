import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import $ from "jquery"
//import _ from 'lodash-es'

import seo01 from '../../images/services/seo/seo-01.png'
import seo02 from '../../images/services/seo/seo-02.png'

import seo01retina from '../../images/services/seo/seo-01@2x.png'
import seo02retina from '../../images/services/seo/seo-02@2x.png'


export default class ContentSeo extends Component {
    blocks_load = () => {;
        $('.freeCons .const_free_text').css('display','none');
        $('.freeCons').css('opacity','1');
        $('.freeCons').css('width','70px');
    }

    render() {
        return (
            <section className="content">
                         <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.blocks_load}
                    />
                <div class="container-block"> 
                    <div className="block">
                        <div className="block__text">
                            <div className="text-toptitle">UI/UX</div>
                            <h2>Cieľ - prvá trojka</h2>
                            <p>Až 75 % celej návštevnosti po zadaní výrazu do vyhľadávača pripadá prvým trom výsledkom. Chceme vás dostať na stupeň víťazov.</p>
                        </div>
                        <div className="block__image">
                            <img src={seo01} srcSet={`${seo01}, ${seo01} 1x, ${seo01retina} 2x`} alt="Cieľ - prvá trojka" />
                        </div>
                    </div>
                    <div className="block">
                        <div className="block__image">
                            <img src={seo02}  srcSet={`${seo02}, ${seo02} 1x, ${seo02retina} 2x`} alt="Buďte SEO friendly" />
                        </div>
                        <div className="block__text">
                            <div className="text-toptitle">s možnosťou administrácie.</div>
                            <h2>Buďte SEO friendly</h2>
                            <p>Dôležité je tvoriť obsah pre ľudí a venovať pozornosť aj vyhľadávačom. Pomôžeme vám potešiť oboch. Zabezpečíme celú starostlivosť o SEO.</p>
                        </div>
                    </div>
                </div> 
            </section>
        )
    }
}
