import React, { Component } from 'react'
import { Waypoint } from 'react-waypoint';
import _ from 'lodash-es'
import $ from "jquery"
import {notify} from 'react-notify-toast';
import { withTranslation } from "react-i18next"; // Import withTranslation
//import i18n from 'i18next';

import { Button } from '../../components/home/Button'

class Contact extends Component {
    constructor(props){
        super(props)
        this.state = {email: '', tel: '', text: '', sent: false};
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }
    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    email = () => {
        var api_key = 'a89a0ff9aea2ee281d70816f0a3127ac-1d8af1f4-0e6fca5b';
        var domain = 'mg.matate.sk';
        var mailgun = require('mailgun-js')({apiKey: api_key, domain: domain, host: "api.eu.mailgun.net"});
         
        var text = "<h3><b>Email:</b> " + this.state.email + " </h3> "
            text += "<h3><b>Telefón:</b> " + this.state.tel + " </h3> "
            text += "<h3><b>Text:</b> " + this.state.text + " </h3> "

        var data = {
          from: 'Matate server <server@mg.matate.sk>',
          to: 'info@matate.sk'/*info@matate.sk*/,
          subject: 'Konzultácia pre '+this.state.email+' - matate.sk',
          html: text
        };
        data['h:Reply-To'] = this.state.email;
        if(this.state.email !== "" && this.state.text !== "" && this.validateEmail(this.state.email)){
            mailgun.messages().send(data);
            //notify.show("Správa bola úspešne odoslaná.", 'success')
            this.state.sent = true;

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'contactFormSent',
                form_type: "services",
                user: {
                    email: this.state.email,
                    text: this.state.text
                }
              });

            this.setState({email: "", tel: "", text: ""});
        }else{
            this.state.sent = false;
            notify.show("Zle vyplnené údaje.", 'error')
        }
    }
    componentDidMount(){
        new Button('Mybtn-6')
    }
    contact_load = () => {
        _.delay(function() {
            $('.kontakt .AU_animation12').css('transform', 'translateX(0px)')
        }, 1000);
        _.delay(function() {
            $('.kontakt .AU_animation13').css('transform', 'translateX(0px)')
        }, 1000);
        _.delay(function() {
            $('.kontakt .right .Mybtn').css('transition', 'all .5s ease-in');
            $('.kontakt .right .Mybtn rect').css('transition','stroke-dashoffset 1s ease').css('stroke-dashoffset', '0px');
          }, 2000);
          _.delay(function() {
            $('.kontakt .right .Mybtn .text').css('animation','blur 2s ease-in').css('animation-fill-mode','forwards');
          }, 2400);
    }
    render() {
        const { t } = this.props; // Access t from props
        return (
            <div className="kontakt-wrapper">
                <div className="kontakt columns reveal_wrap">
                    <Waypoint
                        scrollableAncestor={window}
                        onEnter={this.contact_load}
                    />
                    <div className="left column is-2">
                        <div className="AU_animation12">
                            <h2>
                                {t('kontakt.title')}
                            </h2>
                            <h3>
                                {t('kontakt.subtitle')}
                            </h3>
                            <p>{t('kontakt.description')}</p>
                        </div>
                    </div>
                    <div className="right column is-4 AU_animation13">
                        <form>
                            <input type="email" name="email" id="" placeholder={t('kontakt.placeholder1')}  required
                                value={this.state.email}
                                onChange={this.handleInputChange}
                            />
                            {/* <input type="tel" name="tel" id="" placeholder="Váš telefón (nepovinné)"
                                value={this.state.tel}
                                onChange={this.handleInputChange}
                            /> */}
                            <textarea name="text" id="" cols="30" rows="10" placeholder={t('kontakt.placeholder2')}
                                value={this.state.text}
                                onChange={this.handleInputChange}
                            ></textarea>
                            {/*<label className="checkbox">
                                <input type="checkbox" />
                                Súhlasím so <a href="#"> spracovaním osobných údajov</a>
                            </label>*/}
                            <a className="Mybtn Mybtn-6">
                                <svg width="129" height="50" viewBox="0 0 129 50" fill="none">
                                    <rect x="0.5" y="0.5" width="128" height="49" rx="24" stroke="white"/>
                                </svg>
                                <span className="hover"></span>
                                <div className="text" onClick={this.email}>{t('kontakt.buttonText')}</div> 
                            </a>

                            {this.state.sent ? <div className="success-msg">{t('messages.success')}</div> : null }
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Contact);