import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Header from "./Header";
import Footer from "../home/Footer";

import { gql, useQuery } from "@apollo/client";

import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const formatDate = (wrongDate) => {
  const date = new Date(wrongDate);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();

  const formattedDate = `${day}.${month}.${year}`;
  return formattedDate; // Output: 13/03/2023
};

const GET_POST_BY_SLUG = gql`
  query GetPostBySlug($id: String!) {
    postBy(slug: $id) {
      id
      title
      content
      date
      featuredImage {
        node {
          sourceUrl
          altText
        }
      }
    }
  }
`;

const Article = ({ id }) => {
  const { loading, error, data } = useQuery(GET_POST_BY_SLUG, {
    variables: { id },
  });

  const [post, setPost] = useState({});

  useEffect(() => {
    data &&
      setPost(() => {
        return {
          ...data.postBy,
          date: formatDate(data.postBy.date),
          thumbnail: { url: data.postBy.featuredImage.node.sourceUrl },
        };
      });
  }, [data]);

  return (
    <div className="blogPage">
      <Helmet htmlAttributes>
        <html lang="sk" />
        <title>{loading ? "Blog | Matate" : post.title}</title>
        <meta name="description" content={post.title} />
      </Helmet>

      <Header />
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div className="hero--sub">
          <div className="date">
            {loading ? <Skeleton height={20} width={150} /> : post.date}
          </div>
          <h1 className="headline">
            {loading ? <Skeleton height={40} width={300} /> : post.title}
          </h1>
        </div>
      </SkeletonTheme>

      <SkeletonTheme baseColor="#b0b0b0" highlightColor="#fff">
        <div class="container-block">
          <article>
            {loading ? (
              <Skeleton height={40} count={10} />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: post.content,
                }}
                onError={(e) => console.log("Error loading HTML content:", e)}
              />
            )}
          </article>
        </div>
      </SkeletonTheme>

      {/* <section className="blog-offers">
                    <div className="container-block"> 
                        <div class="header">
                            <div className="header__subtitle">Blog</div>
                            <h2 className="header__title">Posledné<br /> články z blogu</h2>
                        </div>

                        <div className="boxes">
                            <div className="columns">
                                <a href="#" className="column blog-offers__box">
                                    <img src={blogImage01} alt="title"/>
                                    <h2>Webová analytika</h2>
                                    <p>Vyhodnocovaním marketingových aktivít máte podrobný prehľad o tom, ako sa vášmu webu darí. Robte dôležité rozhodnutia na základe faktov.</p>
                                </a>
                                <a href="#" className="column blog-offers__box">
                                    <img src={blogImage02} alt="title"/>
                                    <h2>Webová analytika</h2>
                                    <p>Vyhodnocovaním marketingových aktivít máte podrobný prehľad o tom, ako sa vášmu webu darí. Robte dôležité rozhodnutia na základe faktov.</p>
                                </a>
                                <a href="#" className="column blog-offers__box">
                                    <img src={blogImage03} alt="title"/>
                                    <h2>Webová analytika</h2>
                                    <p>Vyhodnocovaním marketingových aktivít máte podrobný prehľad o tom, ako sa vášmu webu darí. Robte dôležité rozhodnutia na základe faktov.</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </section> */}

      <Footer />
    </div>
  );
};

export default Article;
