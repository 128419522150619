import React from 'react'
import { Helmet } from "react-helmet";

// glboal components
import Footer from './components/home/Footer'

// hero specifiy components 
import Hero from './components/service/Hero'
import ContentSeo from './components/service/ContentSeo'
import Header from './components/service/Header'
import Contact from './components/service/Contact'
import Portfolio from './components/home/Portfolio'

import FreeConsultation from './components/home/FreeConsultation'

//import Notifications, { notify } from 'react-notify-toast';

import { withTranslation } from "react-i18next"; // Import withTranslation
import i18n from './i18n';

class ServiceSeo extends React.Component {
  constructor() {
    super();

    this.state = {
        hasPortfolio: true, // portfolio on/off
        title: i18n.t('pages.serviceSeo.heroTitle'),
        text: i18n.t('pages.serviceSeo.heroText'),
        breadcrumb: [
            {
                title: "Služby",
                link: "/sluzby"
            }, {
                title: "SEO",
                link: ""
            }
        ]
    }
  }

  render() {
      return (
          <div className="ServiceWeb">
              <Helmet>
                <title>{this.props.t('pages.serviceSeo.pageTitle')} | MATATE</title>
                <meta name="description" content={this.props.t('pages.serviceSeo.metaText')} />
              </Helmet>
              <FreeConsultation />
              <Header breadcrumb={this.state.breadcrumb} />
              <Hero title={this.state.title} text={this.state.text} />
              <ContentSeo />
              <Portfolio status={this.state.hasPortfolio} />
              <Contact />
              <Footer />
          </div>
        )
    }
}

export default withTranslation()(ServiceSeo);
